export const deviceType = () => {
  const user = navigator.userAgent;
  let type;

  if (user.indexOf("iPhone") > -1
      || user.indexOf("Android") > -1
      || user.indexOf("iPad") > -1
  ) {
   return type = "MO"
  } else {
   return type = "PC"
  }

}

