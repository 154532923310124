import { deviceType } from "./DeviceType";
import LocalizationManager from "../LocalizationManager";
import {fadeIn, fadeOut} from "./TransitionHelper";
import Solution from "./Solution";


let instance = null;
export default class GuidePopup {
  constructor() {
    // Singleton
    if (instance) {
      return instance;
    }

    instance = this;

    this.guidePopup = document.querySelector(".guide-popup");
    this.guideLabel = document.querySelector(".guide-popup-label");
    this.guidIcon = document.querySelector(".guide-popup-inner div");
    this.guideType = '';
    this.isSolution = false;
    this.isAddEvent = false;

    this.localizationManager = new LocalizationManager();
    this.solution = new Solution();

  }

  show(icon, label) {
    this.guidePopup.className = `guide-popup ${this.guideType}`;
    this.guidIcon.className = icon;
    const type = deviceType();
    this.guideLabel.setAttribute("data-i18n", label + type);

    this.localizationManager.updateContent();
    fadeIn(this.guidePopup);

    if(this.isAddEvent) {
      this.isAddEvent = false;
      setTimeout(() => {
        this.addHideEvents();
      }, 300)
    }
    
  }

  hide() {
    fadeOut(this.guidePopup);
    this.removeHideEvents();
    if (this.guideType === 'webgl') {
      this.solution.solutionIn();
    }
  }

  eventListener = () => {
    this.hide();
  }

  addHideEvents() {
    window.addEventListener('click', this.eventListener);
    window.addEventListener('dragstart', this.eventListener);
    window.addEventListener('touchstart', this.eventListener);
  }

  removeHideEvents() {
    window.removeEventListener('click', this.eventListener);
    window.removeEventListener('dragstart', this.eventListener);
    window.removeEventListener('touchstart', this.eventListener);
  }

}
