import * as THREE from "three";
import Experience from "../../Experience.js";
import { Timeline } from "gsap/gsap-core";
import StateMachine from "../../StateMachine.js";

export default class GIRD_SYSTEM_0 {
    constructor() {
        return;

        this.CLASS_STATE = StateMachine.States.GIRD_SYSTEM_0;
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;
        this.pointer = this.experience.pointer;

        this.cityGroup = this.experience.world.city.group;
        this.natureGroup = this.experience.world.nature.group;
        this.undergroundGroup = this.experience.world.underground.group;

        this.renderer = this.experience.renderer;
        this.resources = this.experience.resources;
        this.stateMachine = this.experience.stateMachine;
        this.time = this.experience.time;
        this.debug = this.experience.debug;

        this.group = new THREE.Group();
        this.group.scale.set(
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale
        );

        this.setMaterial();
        this.setModel();
        this.hide();
        this.setStateMachine();
        this.pointer.on("mousemove", () => {});
    }

    setStateMachine() {
        this.stateMachine.on("stateChange", (value) => {
            let currentState = this.stateMachine.State;
            switch (currentState) {
                case this.CLASS_STATE:
                    this.show();
                    break;
                default:
                    this.hide();
                    break;
            }
        });
    }

    show() {
        this.group.visible = true;
    }

    hide() {
        this.group.visible = false;
    }

    setModel() {
        this.model = this.resources.items.cityUndergroundModel;
        this.group.add(this.model.scene);
        this.scene.add(this.group);

        this.model.scene.traverse((child) => {
            console.log(child.name);
            if (child.isMesh) {
                child.castShadow = false;
                child.receiveShadow = false;
                if (child.name == "3_2_mesh_01") {
                    child.material = this.materials[0];
                }

                if (child.name == "3_2_mesh_02") {
                    child.material = this.materials[1];
                }

                if (child.name == "3_2_mesh_03") {
                    child.material = this.materials[2];
                }
            }
        });
    }

    setMaterial() {


    }

    update() {}
}