import { deviceType } from "./DeviceType";


let instance = null;
export default class Solution {
  constructor() {
    // Singleton
    if (instance) {
      return instance;
    }

    instance = this;

    this.solutionWrap = document.querySelector(".header-solution-btn-wrap");
  }

  solutionIn() {
    if (deviceType() === "PC") {
      this.solutionWrap.style.pointerEvents = 'auto';
    } else {
      this.solutionWrap.style.pointerEvents = 'auto';
      this.solutionWrap.style.opacity = 1;
    }
  }

  solutionOut() {
    if (deviceType() === "PC") {
      this.solutionWrap.style.pointerEvents = 'none';
    } else {
      this.solutionWrap.style.pointerEvents = 'none';
      this.solutionWrap.style.opacity = 0;
    }
  }
}
