import i18next from "i18next";
import locI18next from "loc-i18next";

import englishFile from "./Languages/en.json";
import koreanFile from "./Languages/ko.json";

let instance = null;
export default class LocalizationManager {
    constructor() {
        // Singleton
        if (instance) {
            return instance;
        }

        instance = this;

        const language = 'en';
        const _this = this;
        i18next
            .init({
                lng: language,
                fallbackLng: language,
                resources: {
                    en: {
                        translation: englishFile
                    },
                    ko: {
                        translation: koreanFile
                    }
                }
            })
            .then(function () {
                _this.updateContent();
            });
    }

    updateContent() {
        console.log('LocalizationManager updateContent');
        const localize = locI18next.init(i18next, {
            useOptionsAttr: true
        });
        localize(".popup");
        localize(".guide-popup");
        localize(".text-type-close-btn");
        localize(".tutorial-popup");
        localize(".solution-guide");
        localize(".intro");
    }

    loadText(key) {
        return i18next.t(key);
    }

    changeLanguage(language) {
        const selectedLang = language ? language : "en";
        localStorage.setItem('language', selectedLang);
        i18next.changeLanguage(selectedLang).then((t) => {
            this.updateContent();
        });
    }
}
