import * as THREE from "three";
import Experience from "../Experience.js";
import gsap from "gsap";
import { Timeline } from "gsap/gsap-core";
import StateMachine from "../StateMachine.js";

export default class Underground {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.stateMachine = this.experience.stateMachine;
        this.time = this.experience.time;
        this.debug = this.experience.debug;
        this.group = new THREE.Group();
        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder("Underground");
            this.debugFolder.close();
        }
        this.colors = [
                { r: .5, g: .5, b: .5 },
                { r: 141 / 256, g: 42 / 256, b: 27 / 256 },
                { r: 140 / 256, g: 163 / 256, b: 181 / 256 },
                { r: 152 / 256, g: 208 / 256, b: 235 / 256 },
                { r: 31 / 256, g: 56 / 256, b: 97 / 256 },
            ]
            // Resource
        this.resource = this.resources.items.cityUndergroundModel;
        this.setMaterial();
        this.setModel();
        this.stateMachine.on("stateChange", (value) => {
            let currentState = this.stateMachine.State;
            var tl = new Timeline();
            switch (currentState) {
                case StateMachine.States.SEPERATE:
                    tl.to(this.group.position, {
                        duration: 1.5,
                        // delay: 0.3,
                        ease: "power1.inOut",
                        y: -83,
                    });
                    this.group.visible = true;

                    this.setColorToWhite(tl);
                    this.hideRingRoad();
                    break;
                case StateMachine.States.COEXIST_WITH_NATURE:
                case StateMachine.States.HUMAN_CENTERED:
                    this.group.visible = true;
                    tl.to(this.group.position, {
                        duration: 1.5,
                        // delay: 0.3,
                        ease: "power1.inOut",
                        y: 0,
                    });
                    this.setColorToWhite(tl);
                    this.hideRingRoad();
                    break;
                case StateMachine.States.EMBRACING_FUTURE:
                    this.group.visible = true;
                    this.showRingRoad();
                    tl.to(this.group.position, {
                        duration: 1.5,
                        delay: 0.3,
                        ease: "power2.inOut",
                        y: -50,
                    });
                    this.setColorToWhite(tl);
                    break;
                case StateMachine.States.GIRD_SYSTEM_0:
                case StateMachine.States.GIRD_SYSTEM_1:
                case StateMachine.States.GIRD_SYSTEM_2:
                case StateMachine.States.CONNECTED_CITY_0:
                    this.group.visible = true;
                    this.showRingRoad();
                    tl.to(this.group.position, {
                        duration: 1.5,
                        delay: 0.3,
                        ease: "power2.inOut",
                        y: -50,
                    });
                    this.setColorToVivid(tl);
                    break;
                default:
                    this.group.visible = true;
                    this.showRingRoad();
                    this.setColorToWhite(tl);
                    tl.to(this.group.position, {
                        duration: 1.5,
                        delay: 0.3,
                        ease: "power2.inOut",
                        y: 0,
                    });
                    break;
            }
        });
    }

    setColorToWhite(tl) {
        for (let index = 0; index < this.materials.length; index++) {
            tl.to(
                this.materials[index].color, {
                    r: .5,
                    g: .5,
                    b: .5,
                    duration: 1,
                    ease: "power2.inOut",
                },
                0
            );
        }
    }

    setColorToVivid(tl) {
        tl.to(
            this.materials[0].color, {
                r: this.colors[1].r,
                g: this.colors[1].g,
                b: this.colors[1].b,
                duration: 1,
                ease: "power2.inOut",
            },
            0
        );
        tl.to(
            this.materials[1].color, {
                r: this.colors[2].r,
                g: this.colors[2].g,
                b: this.colors[2].b,
                duration: 1,
                ease: "power2.inOut",
            },
            0
        );
        tl.to(
            this.materials[2].color, {
                r: this.colors[3].r,
                g: this.colors[3].g,
                b: this.colors[3].b,
                duration: 1,
                ease: "power2.inOut",
            },
            0
        );
        tl.to(
            this.materials[3].color, {
                r: this.colors[4].r,
                g: this.colors[4].g,
                b: this.colors[4].b,
                duration: 1,
                ease: "power2.inOut",
            },
            0
        );
    }
    showRingRoad() {
        this.uniforms[1].uSize.target = 1;
    }
    hideRingRoad() {
        this.uniforms[1].uSize.target = 0;
    }

    setModel() {
        this.model = this.resource.scene;
        this.model.scale.set(
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale
        );

        this.scene.add(this.group);
        this.group.add(this.model);

        this.model.traverse((child) => {
            if (child.isMesh) {
                child.castShadow = true;
                child.receiveShadow = true;

                if (child.name.includes("UNDER_CAP")) {
                    this.cap = child;
                    this.cap.visible = false;
                }
                if (child.name.includes("UNDER_CORE")) {
                    child.material = this.materials[0];
                    this.underCore = child;
                }
                if (child.name.includes("UNDER_RING_ROAD")) {
                    child.material = this.materials[1];
                    this.ringRoad = child;
                    child.castShadow = false;
                    child.receiveShadow = false;
                }
                if (child.name.includes("UNDER_ROAD")) {
                    child.material = this.materials[2];
                    this.underRoad = child;
                }
                if (child.name.includes("UNDER_SPACE")) {
                    child.material = this.materials[3];
                    this.underSpace = child;
                }
            }
        });

        if (this.debugFolder) {
            this.debugFolder
                .add(this.model.position, "y")
                .name("position")
                .min(-400)
                .max(0);
            this.debugFolder
                .add(this.model.scale, "y")
                .name("scale")
                .min(0.01)
                .max(0.2)
                .onChange((value) => {
                    this.model.scale.set(value, value, value);
                });
            // this.debugFolder.addColor(this.material, "color");
            for (let index = 0; index < this.materials.length; index++) {
                const material = this.materials[index];
                this.debugFolder
                    .addColor(this.materials[index], "color")
                    .onChange((value) => {
                        this.materials[index].color = value;
                    });

            }
        }
    }

    setMaterial() {
        this.materials = [];
        this.materials.push(
            new THREE.MeshStandardMaterial(),
            new THREE.MeshStandardMaterial({
                color: new THREE.Color(),
                side: THREE.DoubleSide,
                // transparent: false,
                // alphaTest: 0.5,
            }),
            new THREE.MeshStandardMaterial(),
            new THREE.MeshStandardMaterial(0x1f3861),
        );

        this.uniforms = [];
        for (let i = 0; i < this.materials.length; i++) {
            this.uniforms.push({
                uSize: { value: -0.2, target: 1 },
                uCenter: { value: new THREE.Vector3() }
            });

            let material = this.materials[i];
            material.onBeforeCompile = (shader) => {
                shader.uniforms.uCenter = this.uniforms[i].uCenter;
                shader.uniforms.uSize = this.uniforms[i].uSize;

                shader.vertexShader = shader.vertexShader.replace(
                    "void main()",
                    `
                  varying vec3 vPosition;
                  uniform vec3 uCenter;
                  uniform float uSize;
                  void main()
                  `
                );
                shader.fragmentShader = shader.fragmentShader.replace(
                    "void main()",
                    `
                  varying vec3 vPosition;
                  uniform vec3 uCenter;
                  uniform float uSize;
                  void main()
                  `
                );
                shader.vertexShader = shader.vertexShader.replace(
                    "#include <beginnormal_vertex>",
                    `
                      #include <beginnormal_vertex>
                      vPosition = position;
                  `
                );


                shader.fragmentShader = shader.fragmentShader.replace(
                    "#include <output_fragment>",
                    `
                  #ifdef OPAQUE
                  diffuseColor.a = 1.0;
                  #endif
  
                  // https://github.com/mrdoob/three.js/pull/22425
                  #ifdef USE_TRANSMISSION
                  diffuseColor.a *= transmissionAlpha + 0.1;                  
                  #endif

                  float dst = length(vPosition-uCenter);
                  dst = dst/(400.0 * 1000.0);
                  dst = max(0.0,min(1.0,dst));

                   if(dst > uSize){
                       discard;
                   }

                  dst = max(0.0,min(1.0,dst-.5));
                  dst = 1.0-dst;
                  dst = pow(dst,7.2);
                  vec4 finColor = vec4( outgoingLight * dst , dst );
                  gl_FragColor = vec4(finColor.x,finColor.y,finColor.z,dst);
                  `
                );
            };
        }
    }

    update() {
        this.uniforms[0].uCenter.value.set(this.underCore.position.x, this.underCore.position.y, this.underCore.position.z);
        this.uniforms[1].uCenter.value.set(this.ringRoad.position.x, this.ringRoad.position.y, this.ringRoad.position.z);
        this.uniforms[2].uCenter.value.set(this.underRoad.position.x, this.underRoad.position.y, this.underRoad.position.z);
        this.uniforms[3].uCenter.value.set(this.underSpace.position.x, this.underSpace.position.y, this.underSpace.position.z);

        for (let i = 0; i < this.materials.length; i++) {
            let value = this.uniforms[i].uSize.value;
            let target = this.uniforms[i].uSize.target;
            this.uniforms[i].uSize.value += (target - value) * .006;
        }
    }
}