import * as THREE from "three";
import Experience from "../../Experience.js";
import { Timeline } from "gsap/gsap-core";
import StateMachine from "../../StateMachine.js";

export default class HUB20_0 {
    constructor() {
        this.CLASS_STATE = StateMachine.States.HUB20_0;
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;
        this.pointer = this.experience.pointer;

        this.cityGroup = this.experience.world.city.group;
        this.natureGroup = this.experience.world.nature.group;
        this.undergroundGroup = this.experience.world.underground.group;

        this.renderer = this.experience.renderer;
        this.resources = this.experience.resources;
        this.stateMachine = this.experience.stateMachine;
        this.time = this.experience.time;
        this.debug = this.experience.debug;

        this.group = new THREE.Group();
        this.group.scale.set(
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale
        );

        this.animations = [];
        this.setMaterial();
        this.setModel();
        this.hide();
        this.setStateMachine();
        this.pointer.on("mousemove", () => {});
        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder("HUB20_0");
            this.debugFolder.close();
            this.debugFolder
                .addColor(this.experience.world.city.hub20.material, "color")
                .onChange((value) => {
                    //this.materials[0].color = value;
                    this.experience.world.city.hub20.material.color = value;
                });

            for (let index = 0; index < this.materials.length; index++) {
                //const material = this.materials[index];
                this.debugFolder
                    .addColor(this.materials[index], "color")
                    .onChange((value) => {
                        this.materials[index].color = value;
                        console.log(index, value);
                    });

            }

        }
    }

    setStateMachine() {
        this.stateMachine.on("stateChange", (value) => {
            let currentState = this.stateMachine.State;
            switch (currentState) {
                case this.CLASS_STATE:
                    this.show();
                    break;
                default:
                    this.hide();
                    break;
            }
        });
    }

    show() {
        this.group.visible = true;
    }

    hide() {
        this.group.visible = false;
    }

    setModel() {
        this.model = this.resources.items.HUB20_0;
        this.group.add(this.model.scene);
        this.scene.add(this.group);

        this.model.scene.traverse((child) => {
            if (child.isMesh) {
                // console.log(child.name);
                // child.castShadow = true;
                // child.receiveShadow = true;
                child.material = this.materials[0];
            }
        });
        //9_1_PBV_Ani // PBV_01~PBV_04
        //9_1_UAM_Ani // UAM_1~UAM_3
        let trackIndex = 3 * 3;
        let clip0 = this.model.animations[0].clone();
        clip0.tracks = [clip0.tracks[trackIndex++], clip0.tracks[trackIndex++], clip0.tracks[trackIndex++]];
        let root = this.model.scene.children[1].children[0];
        let animation = {};
        animation.mixer = new THREE.AnimationMixer(root);
        animation.actions = {};
        animation.actions.idle = animation.mixer.clipAction(clip0);
        animation.actions.current = animation.actions.idle;
        animation.actions.current.play();
        this.animations.push(animation);

        trackIndex = 3 * 4;
        clip0 = this.model.animations[0].clone();
        clip0.tracks = [clip0.tracks[trackIndex++], clip0.tracks[trackIndex++], clip0.tracks[trackIndex++]];
        root = this.model.scene.children[1].children[1];
        animation = {};
        animation.mixer = new THREE.AnimationMixer(root);
        animation.actions = {};
        animation.actions.idle = animation.mixer.clipAction(clip0);
        animation.actions.current = animation.actions.idle;
        animation.actions.current.play();
        this.animations.push(animation);

        trackIndex = 3 * 5;
        clip0 = this.model.animations[0].clone();
        clip0.tracks = [clip0.tracks[trackIndex++], clip0.tracks[trackIndex++], clip0.tracks[trackIndex++]];
        root = this.model.scene.children[1].children[2];
        animation = {};
        animation.mixer = new THREE.AnimationMixer(root);
        animation.actions = {};
        animation.actions.idle = animation.mixer.clipAction(clip0);
        animation.actions.current = animation.actions.idle;
        animation.actions.current.play();
        this.animations.push(animation);

        trackIndex = 3 * 6;
        clip0 = this.model.animations[0].clone();
        clip0.tracks = [clip0.tracks[trackIndex++], clip0.tracks[trackIndex++], clip0.tracks[trackIndex++]];
        root = this.model.scene.children[1].children[3];
        animation = {};
        animation.mixer = new THREE.AnimationMixer(root);
        animation.actions = {};
        animation.actions.idle = animation.mixer.clipAction(clip0);
        animation.actions.current = animation.actions.idle;
        animation.actions.current.play();
        this.animations.push(animation);

        trackIndex = 3 * 0;
        clip0 = this.model.animations[0].clone();
        clip0.tracks = [clip0.tracks[trackIndex++], clip0.tracks[trackIndex++], clip0.tracks[trackIndex++]];
        root = this.model.scene.children[0].children[0];
        animation = {};
        animation.mixer = new THREE.AnimationMixer(root);
        animation.actions = {};
        animation.actions.idle = animation.mixer.clipAction(clip0);
        animation.actions.current = animation.actions.idle;
        animation.actions.current.play();
        this.animations.push(animation);

        trackIndex = 3 * 1;
        clip0 = this.model.animations[0].clone();
        clip0.tracks = [clip0.tracks[trackIndex++], clip0.tracks[trackIndex++], clip0.tracks[trackIndex++]];
        root = this.model.scene.children[0].children[2];
        animation = {};
        animation.mixer = new THREE.AnimationMixer(root);
        animation.actions = {};
        animation.actions.idle = animation.mixer.clipAction(clip0);
        animation.actions.current = animation.actions.idle;
        animation.actions.current.play();
        this.animations.push(animation);

        trackIndex = 3 * 2;
        clip0 = this.model.animations[0].clone();
        clip0.tracks = [clip0.tracks[trackIndex++], clip0.tracks[trackIndex++], clip0.tracks[trackIndex++]];
        root = this.model.scene.children[0].children[1];
        animation = {};
        animation.mixer = new THREE.AnimationMixer(root);
        animation.actions = {};
        animation.actions.idle = animation.mixer.clipAction(clip0);
        animation.actions.current = animation.actions.idle;
        animation.actions.current.play();
        this.animations.push(animation);

    }

    setAnimation() {

    }

    setMaterial() {
        this.materials = [];
        this.materials.push(
            new THREE.MeshStandardMaterial({
                color: new THREE.Color(0xca522a),
            })
        );
        // console.log(this.experience.world.city);
        // console.log(this.experience.world.city);
        // console.log(this.experience.world.city.hub20);
        this.materials.push(this.experience.world.city.hub20.material);
    }

    update() {
        if (this.group.visible) {
            for (let index = 0; index < this.animations.length; index++) {
                const animation = this.animations[index];
                animation.mixer.update(this.time.delta * 0.001);
            }
        }
    }
}