import * as THREE from "three";
import Experience from "../Experience.js";
import gsap from "gsap";
import { Timeline } from "gsap/gsap-core";
import StateMachine from "../StateMachine.js";
import Camera from "../Camera.js";
export default class Pin {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;
        this.pointer = this.experience.pointer;
        this.stateMachine = this.experience.stateMachine;
        this.time = this.experience.time;
        this.debug = this.experience.debug;

        this.resources = this.experience.resources;
        this.resource = this.resources.items.pinModel;
        this.cityGroup = this.experience.world.city.group;
        this.natureGroup = this.experience.world.nature.group;
        this.undergroundGroup = this.experience.world.underground.group;
        this.isVisible = false;
        this.pins = [];
        this.boundBoxs = [];
        this.setMaterial();
        this.setModel();
        this.setGui();
        this.setClickEvent();
        this.setStateMachine();
        this.hide();
    }

    setClickEvent() {
        this.raycaster = new THREE.Raycaster();

        this.offsets = [0, 0, 0];
        this.pointer.on("click", () => {
            this.raycaster.setFromCamera(this.pointer.pointer, this.camera.instance);
            const intersects = this.raycaster.intersectObjects(this.boundBoxs, false);
            for (let index = 0; index < this.boundBoxs.length; index++) {
                const element = this.boundBoxs[index];
                if (
                    intersects.length > 0 &&
                    element == intersects[0].object &&
                    element.visible
                ) {
                    this.stateMachine.SetState(
                        [
                            StateMachine.States.WALKABLE_CITY_0,
                            StateMachine.States.HYPON_DENSITY_0,
                            StateMachine.States.CONVENIENCE_CITY_0,
                            StateMachine.States.EMBRACES_NATURE_0,
                            StateMachine.States.NATURE_ECOSYSTEM_0,
                            StateMachine.States.SUSTAINABLE_CITY_0,
                            StateMachine.States.GIRD_SYSTEM_0,
                            StateMachine.States.CONNECTED_CITY_0,
                            StateMachine.States.HUB20_0,
                        ][index]
                    );
                }
            }
        });

        this.pointer.on("mousemove", () => {
            this.raycaster.setFromCamera(this.pointer.pointer, this.camera.instance);
            const intersects = this.raycaster.intersectObjects(this.boundBoxs, false);
            let checkCount = 0;
            for (let index = 0; index < this.boundBoxs.length; index++) {
                const element = this.boundBoxs[index];
                if (
                    intersects.length > 0 &&
                    element == intersects[0].object &&
                    element.visible
                ) {
                    document.body.style.cursor = 'pointer';

                    return;
                }
            }
            if (this.isVisible) {
                document.body.style.cursor = 'default';
            }

        });
    }
    setStateMachine() {
        this.stateMachine.on("stateChange", (value) => {
            let currentState = this.stateMachine.State;
            switch (currentState) {
                case StateMachine.States.INITAL_STATE:
                case StateMachine.States.SEPERATE:
                    this.hide();
                    break;

                case StateMachine.States.HUMAN_CENTERED:
                case StateMachine.States.EMBRACING_FUTURE:
                case StateMachine.States.COEXIST_WITH_NATURE:
                    let offset = (currentState / 100) - 1;
                    offset *= 3;
                    this.show(offset);
                    break;
                default:
                    let offset1 = (currentState / 100) - 1;
                    offset1 = Math.floor(offset1);
                    offset1 *= 3;
                    this.show(offset1);

                    let stateArray = [
                        StateMachine.States.WALKABLE_CITY_0,
                        StateMachine.States.HYPON_DENSITY_0,
                        StateMachine.States.CONVENIENCE_CITY_0,
                        StateMachine.States.EMBRACES_NATURE_0,
                        StateMachine.States.NATURE_ECOSYSTEM_0,
                        StateMachine.States.SUSTAINABLE_CITY_0,
                        StateMachine.States.GIRD_SYSTEM_0,
                        StateMachine.States.CONNECTED_CITY_0,
                        StateMachine.States.HUB20_0,
                    ]
                    for (let index = 0; index < stateArray.length; index++) {
                        let element = stateArray[index];
                        let element2 = Math.floor(element / 10);
                        let currentState2 = Math.floor(currentState / 10);
                        var tl = new Timeline();
                        if (element2 == currentState2) {
                            tl.to(
                                this.pins[index].material.color, {
                                    r: 1,
                                    g: 0.172,
                                    b: 0,
                                    duration: 0.5,
                                    ease: "power2.inOut",
                                    onComplete: () => {},
                                },
                                0
                            );
                        } else {
                            tl.to(
                                this.pins[index].material.color, {
                                    r: .7,
                                    g: .7,
                                    b: .7,
                                    duration: 0.5,
                                    ease: "power2.inOut",
                                    onComplete: () => {},
                                },
                                0
                            );
                        }
                    }
                    break;

            }
        });
    }

    hide() {
        this.isVisible = false;
        var tl = new Timeline();
        for (let index = 0; index < this.pins.length; index++) {
            let element = this.pins[index];
            tl.to(
                element.material, {
                    duration: 0.5,
                    opacity: 0,
                    ease: "power2.inOut",
                    onComplete: () => {
                        element.visible = false;
                    },
                },
                0
            );

            tl.to(
                element.scale, {
                    x: 0,
                    y: 0,
                    z: 0,
                    ease: "power2.inOut"
                },
                0
            );
        }
    }

    show(offset) {
        this.isVisible = true;
        // this.material.envMapIntensity = 0;
        var tl = new Timeline();
        for (let index = 0; index < 3; index++) {
            let i = index + offset;
            let element = this.pins[i];
            if (element != undefined) {
                element.visible = true;
                tl.to(
                    element.material, {
                        duration: 0.5,
                        opacity: 1,
                        ease: "power2.inOut",
                        onComplete: () => {
                            // element.visible = false;
                        },
                    },
                    0
                );

                tl.to(
                    element.scale, {
                        x: 1,
                        y: 1,
                        z: 1,
                        delay: index * .3 + 1.1,
                        duration: 0.3,
                        ease: "power2.inOut"
                    },
                    0
                );

                tl.to(
                    element.material.color, {
                        r: 1,
                        g: 0.172,
                        b: 0,
                        duration: 0.5,
                        ease: "power2.inOut",
                        onComplete: () => {
                            //element.visible = false;
                        },
                    },
                    0
                );
            }
        }
    }

    setModel() {
        let pinModel = this.resources.items.pinModel.scene.children[0];
        let DegToRad = 0.0174533;
        let groups = [this.cityGroup, this.cityGroup, this.cityGroup, this.natureGroup, this.natureGroup, this.natureGroup, this.undergroundGroup, this.undergroundGroup, this.cityGroup]; //this.undergroundGroup
        for (let i = 0; i < 9; i++) {
            let index = Math.floor(i / 3);
            let pin = new THREE.Mesh(
                pinModel.geometry, new THREE.MeshMatcapMaterial({
                    color: 0x9c9c9c, //0xff2a00
                    matcap: this.resources.items.matcapWhite
                })
            );

            // pin.scale.set(0.001, 0.001, 0.001);
            pin.rotation.set(90 * DegToRad, 0, 0);
            let ii = [5, 7, 9, 11, 13, 15, 17, 20, 21][i];
            pin.position.set(
                Camera.CameraPoses[ii].target.x,
                Camera.CameraPoses[ii].target.y + 30,
                Camera.CameraPoses[ii].target.z
            );
            pin.visible = false;
            this.pins.push(pin);
            groups[i].add(pin);

            let boundingBox0 = pin.geometry.boundingBox;
            let max0 = boundingBox0.max;
            let min0 = boundingBox0.min;
            let width = max0.x - min0.x,
                height = max0.y - min0.y,
                depth = max0.z - min0.z
            let scale = 1.8;
            let box0 = new THREE.Mesh(new THREE.BoxGeometry(width * scale, height * scale, depth * scale), this.boxMaterial);
            pin.add(box0);
            this.boundBoxs.push(box0);
        }
    }

    setMaterial() {
        // for (let index = 0; index < this.pins.length; index++) {
        //     const element = this.pins[index];
        this.material = new THREE.MeshMatcapMaterial({
            color: 0xffffff, //0xff2a00
            matcap: this.resources.items.matcapWhite,
        });

        this.boxMaterial = new THREE.MeshMatcapMaterial({
            color: 0xffffff, //0xff2a00
            matcap: this.resources.items.matcapWhite,
            opacity: 0,
            transparent: true,
        });

        this.material.onBeforeCompile = (shader) => {
            //this.experience.world.environment.setCSMMaterial(material, shader);
            // shader.uniforms.lerp = this.natureUniform.lerp;
            // shader.uniforms.map2 = this.natureUniform.map2;
            // shader.vertexShader = shader.vertexShader.replace(
            //     "void main()",
            //     `
            //         varying vec3 vPosition;
            //         void main()
            //         `
            // );

            // shader.fragmentShader = shader.fragmentShader.replace(
            //     "void main()",
            //     `
            //         varying vec3 vPosition;
            //         void main()
            //         `
            // );

            // shader.fragmentShader = shader.fragmentShader.replace(
            //     "#include <premultiplied_alpha_fragment>",
            //     ``
            // );
            // shader.fragmentShader = shader.fragmentShader.replace(
            //     "vec3 totalSpecular = reflectedLight.directSpecular + reflectedLight.indirectSpecular;",
            //     `vec3 totalSpecular = reflectedLight.indirectSpecular * 0.0;`
            // );
            // shader.fragmentShader = shader.fragmentShader.replace(
            //     "#include <output_fragment>",
            //     `
            //             #ifdef OPAQUE
            //             diffuseColor.a = 1.0;
            //             #endif

            //             // https://github.com/mrdoob/three.js/pull/22425
            //             #ifdef USE_TRANSMISSION
            //             diffuseColor.a *= transmissionAlpha + 0.1;
            //             #endif
            //             diffuseColor.a = 1.0;

            //             gl_FragColor = vec4( outgoingLight, diffuseColor.a );
            //             `
            // );

            // console.log(shader.vertexShader);
            // console.log(shader.fragmentShader);
        };
    }

    setGui() {}

    update() {}
}