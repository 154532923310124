import * as THREE from "three";
import Experience from "../Experience.js";
import gsap from "gsap";
import { Timeline } from "gsap/gsap-core";
import StateMachine from "../StateMachine.js";
export default class City {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.stateMachine = this.experience.stateMachine;
        this.time = this.experience.time;
        this.debug = this.experience.debug;
        this.group = new THREE.Group();
        this.Cameras = [];
        this.pins = [];
        this.customUniforms = { uAlpha: { value: 1 } };
        this.buildingUniformArray = [];
        this.buildingUniforms = {
            uWeight: { value: 1.249975 },
            uvOffsetX: { value: 0 },
            uvOffsetY: { value: 0 },
            uvScaleX: { value: 1 },
            uvScaleY: { value: 1 },
        };
        this.BuildingLines = [];
        this.buildings = [];
        this.colorSettings = {
            building: "#ffffff",
            cityGround: "#0d121a",
            street00: "#6e7e97",
            street01: "#6e7e97",
            park: "#0d121a",
            block: "#0d121a",
            colorOffset: {
                hue: 1,
                saturation: 1,
                value: 1,
            },
        };

        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder("City");
            this.debugFolder.close();
        }

        // Resource
        this.resource = this.resources.items.cityModel;

        this.setMaterial();
        this.setModel();
        this.stateMachine.on("stateChange", (value) => {
            let currentState = this.stateMachine.State;
            var tl = new Timeline();
            switch (currentState) {
                case StateMachine.States.SEPERATE:
                    tl.to(this.customUniforms.uAlpha, {
                        value: 0,
                        ease: "power1.inOut",
                        onComplete: () => {},
                    });
                    tl.to(
                        this.group.scale, {
                            x: 1,
                            y: 1,
                            z: 1,
                            duration: 1.5,
                            // delay: 0.1,
                            ease: "power1.inOut",
                        },
                        0
                    );
                    for (let index = 0; index < this.buildingMaterials.length; index++) {
                        const element = this.buildingMaterials[index];
                        tl.to(
                            element.color, {
                                r: 1,
                                g: 1,
                                b: 1,
                                delay: index * 0.01,
                                duration: 0.4,
                                ease: "power1.inOut",
                            },
                            0
                        );
                    }
                    break;
                case StateMachine.States.EMBRACING_FUTURE:
                    tl.to(
                        this.group.position, {
                            y: 0,
                            duration: 1.5,
                            ease: "power1.inOut",
                        },
                        0
                    );
                    this.hub20.material.color = new THREE.Color(0xffffff);
                    break;
                case StateMachine.States.GIRD_SYSTEM_2:

                    break;

                    //C_01_BLD_HIGH
                case StateMachine.States.WALKABLE_CITY_0:
                case StateMachine.States.HYPON_DENSITY_0:
                    this.setRainbowColor();
                    break;
                case StateMachine.States.SUSTAINABLE_CITY_1:
                    this.setYellowColor();
                    break;
                case StateMachine.States.HUB20_0:
                    this.hub20.material.color = new THREE.Color(0xff3c00);
                    break;
                default:
                    tl.to(this.customUniforms.uAlpha, {
                        value: 1,
                        delay: 2,
                        ease: "power2.inOut",
                    });
                    for (let index = 0; index < this.buildingMaterials.length; index++) {
                        const element = this.buildingMaterials[index];
                        tl.to(
                            element.color, {
                                r: 1,
                                g: 1,
                                b: 1,
                                delay: index * 0.01,
                                duration: 0.4,
                                ease: "power2.inOut",
                            },
                            0
                        );
                    }
            }
        });
    }

    setModel() {
        this.scene.add(this.group);

        let cityModel = this.resources.items.cityModel.scene;
        cityModel.scale.set(
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale
        );

        this.group.add(cityModel);

        cityModel.traverse((child) => {
            if (child.isMesh) {
                child.castShadow = true;
                child.receiveShadow = true;

                if (child.name.includes("GROUND_City")) {
                    child.material = this.cityGroundMaterial;
                    this.groundCity = child;
                }
                if (child.name.includes("STREET_00")) {
                    child.material = this.street00Material;
                    this.street00 = child;
                }
                if (child.name.includes("STREET_01")) {
                    child.material = this.street01Material;
                    this.street01 = child;
                }
                if (child.name.includes("BLOCK")) {
                    child.material = this.blockMaterial;
                    this.block = child;
                }
                if (child.name.includes("PARK")) {
                    child.material = this.parkMaterial;
                    this.park = child;
                }
            }
        });

        let cityBuildingModel = this.resources.items.cityBuildingModel.scene;
        cityBuildingModel.scale.set(
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale
        );

        this.group.add(cityBuildingModel);

        cityBuildingModel.traverse((child) => {
            //   console.log(child.name);
            if (child.isMesh) {
                child.castShadow = true;
                child.receiveShadow = true;
                for (var i = 0; i < City.aoTexturesNames.length; i++) {
                    const meshName = City.aoTexturesNames[i];
                    if (child.name.includes(meshName)) {
                        child.material = this.buildingMaterials[i];
                        this.buildings.push(child);
                        // child.visible = false;
                    }
                }
                if (child.name == "UAM_Building_Retop") {
                    this.hub20 = child;
                    // console.log(this.hub20);
                }
            }
        });

        if (this.debugFolder) {
            this.debugFolder
                .add(this.buildingUniforms.uWeight, "value")
                .name("AO")
                .min(0.0001)
                .max(5)
                .onChange((value) => {
                    this.buildingUniforms.uWeight = value;

                    for (
                        let index = 0; index < this.buildingUniformArray.length; index++
                    ) {
                        const element = this.buildingUniformArray[index];
                        element.uWeight.value = value;
                    }
                });

            this.debugFolder
                .add(this.buildings[0], "visible")
                .name("City visible")
                .onChange((value) => {
                    for (let index = 0; index < this.buildings.length; index++) {
                        this.buildings[index].visible = value;
                    }
                });

            this.debugFolder
                .add(this.groundCity, "visible")
                .name("GroundCity Visible");

            this.debugFolder.add(this.street00, "visible").name("Street00 Visible");

            this.debugFolder.add(this.street01, "visible").name("Street01 Visible");

            this.debugFolder.add(this.block, "visible").name("Block Visible");

            this.debugFolder.add(this.park, "visible").name("Park Visible");

            // this.debugFolder
            //     .addColor(this.buildingMaterials[0], "color")
            //     .name("Building")
            //     .onChange((value) => {
            //         for (let index = 0; index < this.buildingMaterials.length; index++) {
            //             const element = this.buildingMaterials[index];
            //             element.color = value;
            //         }
            //         this.colorSettings.building = value.getHex().toString(16);
            //     });




            this.debugFolder
                .addColor(this.buildingMaterials[0], "color")
                .name("Building")
                .onChange((value) => {
                    //this.colorSettings.cityGround = value.getHex().toString(16);
                    for (var i = 0; i < (this.buildingMaterials.length - 8); i++) {
                        this.buildingMaterials[i].color = value;
                    }
                });

            this.debugFolder
                .addColor(this.cityGroundMaterial, "color")
                .name("CityGround")
                .onChange((value) => {
                    this.colorSettings.cityGround = value.getHex().toString(16);
                });
            this.debugFolder
                .addColor(this.street00Material, "color")
                .name("Street00")
                .onChange((value) => {
                    this.colorSettings.street00 = value.getHex().toString(16);
                });
            this.debugFolder
                .addColor(this.street01Material, "color")
                .name("Street01")
                .onChange((value) => {
                    this.colorSettings.street01 = value.getHex().toString(16);
                });
            this.debugFolder
                .addColor(this.blockMaterial, "color")
                .name("Block")
                .onChange((value) => {
                    this.colorSettings.block = value.getHex().toString(16);
                });
            this.debugFolder
                .addColor(this.parkMaterial, "color")
                .name("Park")
                .onChange((value) => {
                    this.colorSettings.park = value.getHex().toString(16);
                });
        }
    }
    updateColor() {
        for (let index = 0; index < this.buildingMaterials.length; index++) {
            const element = this.buildingMaterials[index];
            //element.color = this.getOffsetedColor(this.colorSettings.building);
            element.color = new THREE.Color(1, 1, 1);
            element.color.setHSL(1, 1, 1);
        }
        this.cityGroundMaterial.color = this.colorSettings.cityGround;
        this.street00Material.color = this.colorSettings.street00;
        this.street01Material.color = this.colorSettings.street01;
        this.blockMaterial.color = this.colorSettings.block;
        this.parkMaterial.color = this.colorSettings.park;
    }

    changeMaterialColor(hue, saturation, value) {}

    getOffsetedColor(color) {
        let hue = this.colorSettings.colorOffset.hue,
            saturation = this.colorSettings.colorOffset.saturation,
            value = this.colorSettings.colorOffset.value;
        let hsl = { h: 1, s: 1, l: 1 };
        let inputColor = new THREE.Color(color);
        inputColor.getHSL(hsl);
        let result = new THREE.Color(1, 1, 1);
        result.setHSL(hsl.h * hue, hsl.s * saturation, hsl.l * value);
        return result;
    }
    setRainbowColor() {
        var tl = new Timeline();

        // for (let index = 0; index < this.buildingMaterials.length; index++) {
        //     const element = this.buildingMaterials[index];
        //     tl.to(
        //         element.color, {
        //             r: 1,
        //             g: 1,
        //             b: 1,
        //             delay: index * 0.01,
        //             duration: 0.4,
        //             ease: "power1.inOut",
        //         },
        //         0
        //     );
        // }

        //totalMaterial-8
        let buildingLength = (this.buildingMaterials.length - 8);
        let colorSetA = [0x00608a, 0x5aaab0, 0x6ac09b]; //6ed5dd
        let colorSetB = [0xefe372, 0xf3a738, 0xb4c366];
        let colorSetC = [0x8094ac, 0xff804c, 0xf1e9d5];


        for (var i = 0; i < buildingLength; i += 3) {
            let pct = (i / 3) / (buildingLength / 3);
            pct = (pct + .3) % 1.0;
            pct = Math.floor(pct * 3);
            let colorA = new THREE.Color(colorSetA[pct]);
            let colorB = new THREE.Color(colorSetB[pct]);
            let colorC = new THREE.Color(colorSetC[pct]);
            // this.buildingMaterials[i].color = colorA;
            // this.buildingMaterials[i + 2].color = colorB;
            // this.buildingMaterials[i + 1].color = colorC;

            tl.to(
                this.buildingMaterials[i].color, {
                    r: colorA.r,
                    g: colorA.g,
                    b: colorA.b,
                    delay: i * 0.01,
                    duration: 0.4,
                    ease: "power1.inOut",
                },
                0
            );

            tl.to(
                this.buildingMaterials[i + 2].color, {
                    r: colorB.r,
                    g: colorB.g,
                    b: colorB.b,
                    delay: i * 0.01,
                    duration: 0.4,
                    ease: "power1.inOut",
                },
                0
            );

            tl.to(
                this.buildingMaterials[i + 1].color, {
                    r: colorC.r,
                    g: colorC.g,
                    b: colorC.b,
                    delay: i * 0.01,
                    duration: 0.4,
                    ease: "power1.inOut",
                },
                0
            );

        }
    }

    setYellowColor() {



        for (var i = 0; i < (this.buildingMaterials.length - 8); i++) {
            this.buildingMaterials[i].color = new THREE.Color(0xff4600); //0xfe400b
        }
        this.hub20.material.color = new THREE.Color(0xff4600);
    }

    defaultColor() {
        for (var i = 0; i < (this.buildingMaterials.length - 8); i++) {
            this.buildingMaterials[i].color = new THREE.Color(this.colorSettings.building);
        }
    }

    setMaterial() {
        this.buildingMaterials = [];
        // console.log(this.resources);
        // console.log(this.resources.items);
        for (var i = 0; i < City.aoTexturesNames.length; i++) {
            this.buildingMaterials.push(
                new THREE.MeshStandardMaterial({
                    color: new THREE.Color(this.colorSettings.building),
                    //map: this.resources.items[City.aoTexturesNames[i] + "_AOMap"],
                    map: this.resources.items.AOmap,
                    envMapIntensity: 0.2,
                })
            );
            this.buildingMaterials[i].needsUpdate = true;
        }

        this.cityGroundMaterial = new THREE.MeshStandardMaterial({
            color: new THREE.Color(this.colorSettings.cityGround),
            // map: this.experience.resources.items.groundColorTexture,
            roughnessMap: this.experience.resources.items.groundNatureSpeculerTexture,
            alphaMap: this.resources.items.groundCityAlphaTexture,
            alphaTest: 0.5,
        });
        this.street00Material = new THREE.MeshStandardMaterial({
            color: new THREE.Color(this.colorSettings.street00),
        });
        this.street01Material = new THREE.MeshStandardMaterial({
            color: new THREE.Color(this.colorSettings.street01),
        });

        this.parkMaterial = new THREE.MeshStandardMaterial({
            color: new THREE.Color(this.colorSettings.park),
        });

        this.blockMaterial = new THREE.MeshStandardMaterial({
            color: new THREE.Color(this.colorSettings.block),
        });

        let step = 9;
        for (let index = 0; index < this.buildingMaterials.length; index++) {
            let uniform = {
                uWeight: { value: 1.249975 },
                uvOffsetX: { value: ((index * 1) / step) % 1.0 },
                uvOffsetY: { value: (Math.floor(index / step) * 1) / step },
                uvScaleX: { value: 1 / step },
                uvScaleY: { value: 1 / step },

                // uvOffsetX: { value: 0.0 },
                // uvOffsetY: { value: 0.0 },
                // uvScaleX: { value: 1 / step },
                // uvScaleY: { value: 1 / step },
            };
            // console.log(
            //     City.aoTexturesNames[index],
            //     index,
            //     uniform.uvOffsetX.value * 9,
            //     uniform.uvOffsetY.value * 9,
            //     // uniform.uvOffsetX.value,
            //     // uniform.uvOffsetY.value,
            //     // (uniform.uvOffsetX.value * 2048),
            //     // (uniform.uvOffsetY.value * 2048),
            //     // uniform.uvScaleX.value,
            //     // uniform.uvScaleY.value
            // );
            this.buildingUniformArray.push(uniform);

            const material = this.buildingMaterials[index];
            material.onBeforeCompile = (shader) => {
                shader.uniforms.uWeight = uniform.uWeight;
                shader.uniforms.uvOffsetX = uniform.uvOffsetX;
                shader.uniforms.uvOffsetY = uniform.uvOffsetY;
                shader.uniforms.uvScaleX = uniform.uvScaleX;
                shader.uniforms.uvScaleY = uniform.uvScaleY;

                shader.vertexShader = shader.vertexShader.replace(
                    "void main()",
                    `
                  varying vec3 vPosition;
                  uniform float uWeight;
                  uniform float uvOffsetX;
                  uniform float uvOffsetY;
                  uniform float uvScaleX;
                  uniform float uvScaleY;
                  void main()
                  `
                );
                shader.fragmentShader = shader.fragmentShader.replace(
                    "void main()",
                    `
                  varying vec3 vPosition;
                  uniform float uWeight;
                  uniform float uvOffsetX;
                  uniform float uvOffsetY;
                  uniform float uvScaleX;
                  uniform float uvScaleY;
                  void main()
                  `
                );
                shader.vertexShader = shader.vertexShader.replace(
                    "#include <uv_vertex>",
                    `

                  //vUv = ( uvTransform * vec3( uv, 1 ) ).xy;
                  #include <uv_vertex>
                  vUv = vec2(vUv.x * uvScaleX + uvOffsetX,vUv.y  * uvScaleY+ uvOffsetY);
                          `
                );

                shader.vertexShader = shader.vertexShader.replace(
                    "#include <beginnormal_vertex>",
                    `
                      #include <beginnormal_vertex>
                      vPosition = position;
                  `
                );
                // console.log(shader.fragmentShader);

                shader.fragmentShader = shader.fragmentShader.replace(
                    "#include <map_fragment>",
                    `
                  #ifdef USE_MAP

                  vec4 texelColor = texture2D( map, vUv );
                
                  diffuseColor *= texelColor;
                  diffuseColor = vec4(pow(diffuseColor.x,uWeight),pow(diffuseColor.y,uWeight),pow(diffuseColor.z,uWeight),diffuseColor.w);
                
                #endif
                `
                );
                shader.fragmentShader = shader.fragmentShader.replace(
                    "#include <output_fragment>",
                    `
                  #ifdef OPAQUE
                  diffuseColor.a = 1.0;
                  #endif
  
                  // https://github.com/mrdoob/three.js/pull/22425
                  #ifdef USE_TRANSMISSION
                  diffuseColor.a *= transmissionAlpha + 0.1;                  
                  #endif
                  float avg = (diffuseColor.x+diffuseColor.y+diffuseColor.z)/3.0;
                  float _x = avg;
                  float _y = avg;
                  float _z = avg;
                  
                  vec4 finColor = vec4( outgoingLight, diffuseColor.a );
                  _x =pow(finColor.x,uWeight);
                  _y =pow(finColor.y,uWeight);
                  _z =pow(finColor.z,uWeight);
                  
                  //gl_FragColor = vec4(_x,_y,_z,finColor.w);
                  gl_FragColor = vec4(finColor.x,finColor.y,finColor.z,finColor.w);
                  `
                );
            };
        }
    }

    update() {}
}

City.aoTexturesNames = [
    "A_01_BLD_HIGH",
    "A_01_BLD_LOW",
    "A_01_BLD_MID",
    "A_02_BLD_HIGH",
    "A_02_BLD_LOW",
    "A_02_BLD_MID",
    "A_03_BLD_HIGH",
    "A_03_BLD_LOW",
    "A_03_BLD_MID",
    "A_04_BLD_HIGH",
    "A_04_BLD_LOW",
    "A_04_BLD_MID",
    "B_01_BLD_HIGH",
    "B_01_BLD_LOW",
    "B_01_BLD_MID",
    "B_02_BLD_HIGH",
    "B_02_BLD_LOW",
    "B_02_BLD_MID",
    "B_03_BLD_HIGH",
    "B_03_BLD_LOW",
    "B_03_BLD_MID",
    "B_04_BLD_HIGH",
    "B_04_BLD_LOW",
    "B_04_BLD_MID",
    "C_01_BLD_HIGH",
    "C_01_BLD_LOW",
    "C_01_BLD_MID",
    "C_02_BLD_HIGH",
    "C_02_BLD_LOW",
    "C_02_BLD_MID",
    "C_03_BLD_HIGH",
    "C_03_BLD_LOW",
    "C_03_BLD_MID",
    "C_04_BLD_HIGH",
    "C_04_BLD_LOW",
    "C_04_BLD_MID",
    "D_01_BLD_HIGH",
    "D_01_BLD_LOW",
    "D_01_BLD_MID",
    "D_02_BLD_HIGH",
    "D_02_BLD_LOW",
    "D_02_BLD_MID",
    "D_03_BLD_HIGH",
    "D_03_BLD_LOW",
    "D_03_BLD_MID",
    "D_04_BLD_HIGH",
    "D_04_BLD_LOW",
    "D_04_BLD_MID",
    "E_01_BLD_HIGH",
    "E_01_BLD_LOW",
    "E_01_BLD_MID",
    "E_02_BLD_HIGH",
    "E_02_BLD_LOW",
    "E_02_BLD_MID",
    "E_03_BLD_HIGH",
    "E_03_BLD_LOW",
    "E_03_BLD_MID",
    "E_04_BLD_HIGH",
    "E_04_BLD_LOW",
    "E_04_BLD_MID",
    "F_01_BLD_HIGH",
    "F_01_BLD_LOW",
    "F_01_BLD_MID",

    "F_02_BLD_HIGH",
    "F_02_BLD_LOW",
    "F_02_BLD_MID",

    "F_03_BLD_HIGH",
    "F_03_BLD_LOW",
    "F_03_BLD_MID",

    "F_04_BLD_HIGH",
    "F_04_BLD_LOW",
    "F_04_BLD_MID",

    "G_mesh_01",
    "G_mesh_02",
    "G_mesh_03",

    "mesh_01",
    "mesh_02",
    "mesh_03",
    "UAM_Building_Retop",
    "UAM_Color",
];