import * as dat from "lil-gui";
import Stats from "three/examples/jsm/libs/stats.module.js";
import RendererStats from "@xailabs/three-renderer-stats";
import Experience from "../Experience";

export default class Debug {
    constructor() {
        this.active = window.location.hash === "#debug";
        this.iOS = this.iOSversion();

        if (this.active) {
            this.ui = new dat.GUI();
            this.ui.close();
            this.experience = new Experience();

            // this.Stats = new Stats();

            // this.rendererStats = new RendererStats();
            // this.rendererStats.domElement.style.position = "absolute";
            // this.rendererStats.domElement.style.left = "0px";
            // this.rendererStats.domElement.style.bottom = "0px";
            // this.rendererStats.domElement.style.width = "200px";
            // document.body.appendChild(this.rendererStats.domElement);
            // document.body.appendChild(this.Stats.dom);
        }
    }
    iOSversion() {
        if (/iP(hone|od|ad)/.test(navigator.platform)) {
            // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
            var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
            let array = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
            return array[0] * 10000 + array[1] * 100 + array[2];
        } else {
            return -1;
        }
    }
    update() {
        if (this.rendererStats) {
            // this.experience.renderer.instance.info.update();
            // console.log(this.experience.renderer.instance.info.memory);

            this.rendererStats.update(this.experience.renderer.instance);
        }
    }
}