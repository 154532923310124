import * as THREE from "three";
import Experience from "../../Experience.js";
import { Timeline } from "gsap/gsap-core";
import StateMachine from "../../StateMachine.js";

export default class GIRD_SYSTEM_1 {
    constructor() {
        this.CLASS_STATE = StateMachine.States.GIRD_SYSTEM_1;
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;
        this.pointer = this.experience.pointer;

        this.cityGroup = this.experience.world.city.group;
        this.natureGroup = this.experience.world.nature.group;
        this.undergroundGroup = this.experience.world.underground.group;

        this.renderer = this.experience.renderer;
        this.resources = this.experience.resources;
        this.stateMachine = this.experience.stateMachine;
        this.time = this.experience.time;
        this.debug = this.experience.debug;

        this.group = new THREE.Group();
        this.group.scale.set(
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale
        );
        this.animations = [];
        this.setMaterial();
        this.setModel();
        this.setAnimation();
        this.hide();
        this.setStateMachine();
        this.pointer.on("mousemove", () => {});
        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder("GIRD_SYSTEM_1");
            this.debugFolder.close();

            for (let index = 0; index < this.materials.length; index++) {
                const material = this.materials[index];
                this.debugFolder
                    .addColor(this.materials[index], "color")
                    .onChange((value) => {
                        this.materials[index].color = value;
                    });

            }

        }
    }

    setStateMachine() {
        this.stateMachine.on("stateChange", (value) => {
            let currentState = this.stateMachine.State;
            switch (currentState) {
                case this.CLASS_STATE:
                    this.show();
                    break;
                default:
                    this.hide();
                    break;
            }
        });
    }

    show() {
        this.group.visible = true;
    }

    hide() {
        this.group.visible = false;
    }

    setModel() {
        this.model = this.resources.items.GIRD_SYSTEM_1;
        this.group.add(this.model.scene);
        this.scene.add(this.group);
        // console.log(this.model);
        this.model.scene.traverse((child) => {
            if (child.isMesh) {
                child.castShadow = false;
                child.receiveShadow = false;
                if (child.name == "7_2_01") {
                    child.material = this.materials[0];
                }
                if (child.name == "7_2_02") {
                    child.material = this.materials[1];
                }
            }
        });

        //7_2_01 BLUE
        //7_2_02 YELLOW
    }

    setMaterial() {
        this.materials = [];
        this.materials.push(
            new THREE.MeshMatcapMaterial({
                color: new THREE.Color(0x20e5f3),
                matcap: this.resources.items.matcapWhite
            }),
            new THREE.MeshMatcapMaterial({
                color: new THREE.Color(0xfe5016),
                matcap: this.resources.items.matcapWhite
            })
        );
    }
    setAnimation() {
        // console.log(this.model.animations)
        const clip0 = this.model.animations[0];
        let root = this.model.scene.children[1].children[0]; //7_2_LoopAni_01_2
        clip0.duration = 9;
        let duration = clip0.duration;
        let length = 10;
        for (let i = 0; i < length; i++) {
            let animation = {};
            let instance = new THREE.Mesh(root.geometry, this.materials[0]);
            instance.name = "7_2_LoopAni_01_2";
            root.parent.add(instance);
            instance.position.set(root.position.x, root.position.y, root.position.z);
            animation.mixer = new THREE.AnimationMixer(instance);
            animation.actions = {};
            animation.actions.idle = animation.mixer.clipAction(clip0);
            animation.actions.current = animation.actions.idle;
            animation.actions.current.play();
            animation.mixer.setTime(duration / length * i);
            this.animations.push(animation);
        }
        root.visible = false;

        const clip1 = this.model.animations[0].clone();
        root = this.model.scene.children[2].children[0];
        length = 1;
        clip1.duration = 1.3666666;
        duration = 1.3666666;
        for (let i = 0; i < length; i++) {
            let animation = {};
            let instance = new THREE.Mesh(root.geometry, this.materials[1]);
            instance.name = "7_2_LoopAni_02_2";
            root.parent.add(instance);
            instance.position.set(root.position.x, root.position.y, root.position.z);
            animation.mixer = new THREE.AnimationMixer(instance);
            animation.actions = {};
            animation.actions.idle = animation.mixer.clipAction(clip1);
            animation.actions.current = animation.actions.idle;
            animation.actions.current.play();
            animation.mixer.setTime(duration / length * i);
            this.animations.push(animation);
        }
        root.visible = false;
    }

    update() {
        if (this.group.visible) {
            for (let index = 0; index < this.animations.length; index++) {
                const animation = this.animations[index];
                animation.mixer.update(this.time.delta * 0.001);
                if (animation.mixer._root.name == "7_2_LoopAni_02_2") {
                    animation.mixer._root.rotation.set(0, 0, -Math.PI / 2);
                }
            }
        }
    }
}