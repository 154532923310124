import Experience from "../Experience.js";
import Environment from "./Environment.js";
import City from "./City.js";
import Underground from "./Underground.js";
import Nature from "./Nature.js";
import TitleButtons from "./TitleButtons.js";
import Pin from "./Pin.js";
import AroundCity from "./AroundCity.js";
import UndergroundAnimations from "./UndergroundAnimations.js";

import CONNECTED_CITY_0 from "./Sub/CONNECTED_CITY_0.js";
import CONVENIENCE_CITY_0 from "./Sub/CONVENIENCE_CITY_0.js";
import CONVENIENCE_CITY_1 from "./Sub/CONVENIENCE_CITY_1.js";
import GIRD_SYSTEM_0 from "./Sub/GIRD_SYSTEM_0.js";
import GIRD_SYSTEM_1 from "./Sub/GIRD_SYSTEM_1.js";
import GIRD_SYSTEM_2 from "./Sub/GIRD_SYSTEM_2.js";
import HUB20_0 from "./Sub/HUB20_0.js";
import NATURE_ECOSYSTEM_0 from "./Sub/NATURE_ECOSYSTEM_0.js";
import NATURE_ECOSYSTEM_1 from "./Sub/NATURE_ECOSYSTEM_1.js";
import SUSTAINABLE_CITY_0 from "./Sub/SUSTAINABLE_CITY_0.js";
import WALKABLE_CITY_1 from "./Sub/WALKABLE_CITY_1.js";

export default class World {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.stateMachine = this.experience.stateMachine;
        this.resourcesReady = false;
        // Wait for resources
        this.resources.on("ready", () => {
            this.resourcesReady = true;
            // Setup
            // this.nature = new Nature();
            // this.city = new City();
            // this.aroundCity = new AroundCity();
            // this.underground = new Underground();


            // this.WALKABLE_CITY_1 = new WALKABLE_CITY_1();
            // this.CONVENIENCE_CITY_0 = new CONVENIENCE_CITY_0();
            // this.CONVENIENCE_CITY_1 = new CONVENIENCE_CITY_1();
            // this.NATURE_ECOSYSTEM_0 = new NATURE_ECOSYSTEM_0();
            // this.NATURE_ECOSYSTEM_1 = new NATURE_ECOSYSTEM_1();
            // this.SUSTAINABLE_CITY_0 = new SUSTAINABLE_CITY_0();
            // this.GIRD_SYSTEM_0 = new GIRD_SYSTEM_0();
            // this.GIRD_SYSTEM_1 = new GIRD_SYSTEM_1();
            // this.GIRD_SYSTEM_2 = new GIRD_SYSTEM_2();
            // this.CONNECTED_CITY_0 = new CONNECTED_CITY_0();
            // this.HUB20_0 = new HUB20_0();

            // this.undergroundAni = new UndergroundAnimations();
            // this.titleButtons = new TitleButtons();
            // this.pins = new Pin();
            // this.environment = new Environment();
        });

        this.stateMachine.on("stateChane", () => {});
    }

    update() {
        if (!this.resourcesReady) {
            return false;
        }

        if (!this.nature) {
            this.nature = new Nature();
            return false;
        }
        if (!this.city) {
            this.city = new City();
            return false;
        }
        if (!this.aroundCity) {
            this.aroundCity = new AroundCity();
            return false;
        }
        if (!this.underground) {
            this.underground = new Underground();
            return false;
        }


        if (!this.WALKABLE_CITY_1) {
            this.WALKABLE_CITY_1 = new WALKABLE_CITY_1();
            return false;
        }
        if (!this.CONVENIENCE_CITY_0) {
            this.CONVENIENCE_CITY_0 = new CONVENIENCE_CITY_0();
            return false;
        }
        if (!this.CONVENIENCE_CITY_1) {
            this.CONVENIENCE_CITY_1 = new CONVENIENCE_CITY_1();
            return false;
        }
        if (!this.NATURE_ECOSYSTEM_0) {
            this.NATURE_ECOSYSTEM_0 = new NATURE_ECOSYSTEM_0();
            return false;
        }
        if (!this.NATURE_ECOSYSTEM_1) {
            this.NATURE_ECOSYSTEM_1 = new NATURE_ECOSYSTEM_1();
            return false;
        }
        if (!this.SUSTAINABLE_CITY_0) {
            this.SUSTAINABLE_CITY_0 = new SUSTAINABLE_CITY_0();
            return false;
        }
        if (!this.GIRD_SYSTEM_0) {
            this.GIRD_SYSTEM_0 = new GIRD_SYSTEM_0();
            return false;
        }
        if (!this.GIRD_SYSTEM_1) {
            this.GIRD_SYSTEM_1 = new GIRD_SYSTEM_1();
            return false;
        }
        if (!this.GIRD_SYSTEM_2) {
            this.GIRD_SYSTEM_2 = new GIRD_SYSTEM_2();
            return false;
        }
        if (!this.CONNECTED_CITY_0) {
            this.CONNECTED_CITY_0 = new CONNECTED_CITY_0();
            return false;
        }
        if (!this.HUB20_0) {
            this.HUB20_0 = new HUB20_0();
            return false;
        }

        if (!this.undergroundAni) {
            this.undergroundAni = new UndergroundAnimations();
            return false;
        }
        if (!this.titleButtons) {
            this.titleButtons = new TitleButtons();
            return false;
        }
        if (!this.pins) {
            this.pins = new Pin();
            return false;
        }
        if (!this.environment) {
            this.environment = new Environment();
            return false;
        }

        if (this.city) this.city.update();
        if (this.environment) this.environment.update();
        if (this.underground) this.underground.update();
        if (this.undergroundRoad) this.undergroundRoad.update();
        if (this.undergroundAni) this.undergroundAni.update();
        if (this.titleButtons) this.titleButtons.update();
        if (this.pins) this.pins.update();
        if (this.aroundCity) this.aroundCity.update();

        if (this.NATURE_ECOSYSTEM_0) this.NATURE_ECOSYSTEM_0.update();
        if (this.NATURE_ECOSYSTEM_1) this.NATURE_ECOSYSTEM_1.update();
        if (this.WALKABLE_CITY_1) this.WALKABLE_CITY_1.update();
        if (this.GIRD_SYSTEM_1) this.GIRD_SYSTEM_1.update();
        if (this.GIRD_SYSTEM_2) this.GIRD_SYSTEM_2.update();
        if (this.HUB20_0) this.HUB20_0.update();
        if (this.CONNECTED_CITY_0) this.CONNECTED_CITY_0.update();
        if (this.SUSTAINABLE_CITY_0) this.SUSTAINABLE_CITY_0.update();

        return true;
    }
}