import {SUBMENU, SUBMENU_CONTENTS} from "./Constants/Constants";
import Experience from "./Experience";
import LocalizationManager from "./LocalizationManager";
import {fadeIn, fadeOut} from "./Utils/TransitionHelper";
import StateMachine from "./StateMachine";
import SimpleBar from "simplebar";
import GuidePopup from "./Utils/GuidePopup";
import DetailImages from "./DetailImages";

export const POPUP_EVENT = {
  PREVIOUS: "PREVIOUS",
  NEXT: "NEXT",
};

export default class Popup {
  constructor() {
    this.experience = new Experience();
    this.stateMachine = new StateMachine();
    this.guidePopup = new GuidePopup();

    this.detailImages = new DetailImages();

    if (this.experience.debug.active) {
      this.debugFolder = this.experience.debug.ui.addFolder("Popup");

      this.param = {
        WALKABLE_CITY: () => {
          this.show(SUBMENU.WALKABLE_CITY);
        },
        HYPON_DENSITY: () => {
          this.show(SUBMENU.HYPON_DENSITY);
        },
        FIGURE_GROUND: () => {
          this.show(SUBMENU.CONVENIENCE_CITY);
        },
        EMBRACES_NATURE: () => {
          this.show(SUBMENU.EMBRACES_NATURE);
        },
        NATURE_ECOSYSTEM: () => {
          this.show(SUBMENU.NATURE_ECOSYSTEM);
        },
        SUSTAINABLE_CITY: () => {
          this.show(SUBMENU.SUSTAINABLE_CITY);
        },
        GIRD_SYSTEM: () => {
          this.show(SUBMENU.GIRD_SYSTEM);
        },
        CONNECTED_CITY: () => {
          this.show(SUBMENU.CONNECTED_CITY);
        },
        HUB20: () => {
          this.show(SUBMENU.HUB20);
        },
      };
      this.debugFolder.add(this.param, "WALKABLE_CITY");
      this.debugFolder.add(this.param, "HYPON_DENSITY");
      this.debugFolder.add(this.param, "FIGURE_GROUND");
      this.debugFolder.add(this.param, "EMBRACES_NATURE");
      this.debugFolder.add(this.param, "NATURE_ECOSYSTEM");
      this.debugFolder.add(this.param, "SUSTAINABLE_CITY");
      this.debugFolder.add(this.param, "GIRD_SYSTEM");
      this.debugFolder.add(this.param, "CONNECTED_CITY");
      this.debugFolder.add(this.param, "HUB20");
    }

    this.localizationManager = new LocalizationManager();

    this.popupContainer = document.getElementsByClassName("popup")[0];
    this.maxCount = 0;
    this.currentCount = 0;
    this.currentSubMenu = undefined;

    this.nextBtn = document.getElementsByClassName("popup-next-btn")[0];
    this.previousBtn = document.getElementsByClassName("popup-previous-btn")[0];
    this.closeBtn = document.getElementsByClassName("popup-close-btn")[0];
    this.titleLabel = document.getElementsByClassName("popup-title-label")[0];
    this.subtitleLabel = document.getElementsByClassName(
      "popup-subtitle-label"
    )[0];
    this.subtitleLabelClone = document.getElementsByClassName(
      "popup-subtitle-label-clone"
    )[0];
    this.image = document.getElementsByClassName("popup-image")[0];
    this.descLabel = document.getElementsByClassName("popup-desc-label")[0];

    this.simpleBar = new SimpleBar(document.getElementsByClassName("scroll-wrap")[0]);

    this.maxImages = 0;
    this.currentImages = 0;

    this.nextBtn.addEventListener("click", (event) => {
      if (this.currentCount === this.maxCount) {
        return;
      }

      this.currentCount++;
      this.stateMachine.SetState(SUBMENU_CONTENTS.get(this.currentSubMenu)[this.currentCount].state);

      this.hide();
    });

    this.previousBtn.addEventListener("click", (event) => {
      if (this.currentCount === 0) {
        return;
      }

      this.currentCount--;
      this.stateMachine.SetState(SUBMENU_CONTENTS.get(this.currentSubMenu)[this.currentCount].state);

      this.hide();
    });

    this.closeBtn.addEventListener("click", (event) => {
      this.stateMachine.SetState(this.currentSubMenu.parent);
      this.currentSubMenu = undefined;
      this.hide();
    });

    this.image.addEventListener("click", () => {
      this.detailImages.show(SUBMENU_CONTENTS.get(this.currentSubMenu)[this.currentCount]);
    });

    this.stateMachine.on("stateChange", (value) => {
      let state = this.stateMachine.State;
      switch (state) {
        case StateMachine.States.INITAL_STATE: // 메인
        case StateMachine.States.SEPERATE: // 솔루션
          console.log("current state : " + state);
          this.hide();
          break;

      }
    });
  }

  refresh() {
    const content = SUBMENU_CONTENTS.get(this.currentSubMenu)[
      this.currentCount
    ];
    this.titleLabel.setAttribute("data-i18n", this.currentSubMenu.name);
    this.subtitleLabel.setAttribute("data-i18n", content.name);
    this.subtitleLabelClone.setAttribute("data-i18n", content.name);

    this.image.setAttribute(
      "src",
      'images/popup/' + content.images[0]
    );

    this.currentCount === this.maxCount
      ? this.nextBtn.classList.add("inactive")
      : this.nextBtn.classList.remove("inactive");

    this.currentCount === 0
      ? this.previousBtn.classList.add("inactive")
      : this.previousBtn.classList.remove("inactive");

    this.maxCount === 0
      ? (this.nextBtn.style.display = 'none', this.previousBtn.style.display = 'none')
      : (this.nextBtn.style.display = 'block', this.previousBtn.style.display = 'block');

    this.descLabel.setAttribute("data-i18n", content.desc);

    this.localizationManager.updateContent();

    // popup scroll 위치 초기화
    this.simpleBar.getScrollElement().scroll({top: 0, left: 0});
  }

  show(submenu) {
    console.log('show submenu : ' + JSON.stringify(submenu));
    console.log('current submenu : ' + JSON.stringify(this.currentSubMenu));
    if (submenu !== this.currentSubMenu) {
      this.currentCount = 0;
      this.currentSubMenu = submenu;
      this.maxCount = SUBMENU_CONTENTS.get(this.currentSubMenu).length - 1;
    }

    this.refresh();

    fadeIn(this.popupContainer);
  }

  hide() {
    // popup scroll 위치 초기화
    this.simpleBar.getScrollElement().scroll({top: 0, left: 0});
    fadeOut(this.popupContainer);
  }
}

