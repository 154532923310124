export const fadeIn = (dom) => {
  dom.classList.add('fade_in');
}

export const fadeOut = (dom) => {
  dom.classList.remove('fade_in');
}

export const slideIn = (dom) => {
  dom.classList.add('slide_in');
}

export const animationIn = (dom) => {
  dom.classList.remove('animation_out');
  dom.classList.add('animation_in');
}

export const animationOut = (dom) => {
  dom.classList.remove('animation_in');
  dom.classList.add('animation_out');
}