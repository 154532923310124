import Experience from "./Experience";
import Header from "./Header";
import { fadeOut } from "./Utils/TransitionHelper";
import GuidePopup from "./Utils/GuidePopup";
import Solution from "./Utils/Solution";
import { GUIDE_POPUP_ICON, GUIDE_POPUP_LABEL } from "./Constants/Constants";
import LocalizationManager from "./LocalizationManager";
import { deviceType } from "./Utils/DeviceType";
import Loading from "./Loading";
import { calculateValues } from "./Utils/TextAnimation";

const QUALITY = 'fhd'; // uhd, fhd, hd
const FRAME = 24; // 30fps, 24fps

const MAX_FRAME_COUNT = 35 * FRAME; // 35초
let RUNNING_TIME = 20;
const INTERVAL = Math.floor(MAX_FRAME_COUNT / (MAX_FRAME_COUNT * 0.1));

let instance = null;
export default class Intro {
    constructor() {
        // Singleton
        if (instance) {
            return instance;
        }

        instance = this;

        this.loading = new Loading();

        this.introIframe = document.querySelector('.intro-iframe');;
        const introDocument = this.introIframe.contentWindow.document;
        this.introWindow = this.introIframe.contentWindow;
        this.container = introDocument.body;
        if (deviceType() === 'MO') {
            RUNNING_TIME = 35;
        }
        this.introHeight = this.introWindow.innerHeight * RUNNING_TIME

        this.gsap = introDocument.gsap;

        //intro text
        this.textContanier = introDocument.querySelector('.intro');
        this.introTextInfo = {
            element: {
                elem01: introDocument.querySelector('.intro-scene-01'),
                elem02: introDocument.querySelector('.intro-scene-02'),
                elem02_child: introDocument.querySelector('.intro-scene-02 span'),
                elem03: introDocument.querySelector('.intro-scene-03'),
                elem03_child: introDocument.querySelector('.intro-scene-03 span'),
                elem04: introDocument.querySelector('.intro-scene-04'),
                elem04_child: introDocument.querySelector('.intro-scene-04 span'),
                elem05: introDocument.querySelector('.intro-scene-05'),
                elem05_child: introDocument.querySelector('.intro-scene-05 span'),
                elem06: introDocument.querySelector('.intro-scene-06'),
                elem06_child: introDocument.querySelector('.intro-scene-06 span'),
                elem07_child01: introDocument.querySelector('.intro-scene-07 .intro-text-01'),
                elem07_child02: introDocument.querySelector('.intro-scene-07 .intro-text-02'),
                elem07_child03: introDocument.querySelector('.intro-scene-07 .intro-text-03'),
                elem07_child04: introDocument.querySelector('.intro-scene-07 .intro-text-04'),
                elem07_child05: introDocument.querySelector('.intro-scene-07 .intro-text-05'),
                elem08: introDocument.querySelector('.intro-scene-08'),
            },
            values: {
                ani01_p_in: [64, 0, { start: 4, end: 7, easing: "ease-in-out" }],
                ani01_s_in: [1, 2, { start: 6.67, end: 7.3, easing: "ease-in-out" }],
                ani01_p_out: [0, -13, { start: 9.5, end: 12.14, easing: "ease-in-out" }],
                ani01_s_out: [2, 1, { start: 9.5, end: 12.14, easing: "ease-in-out" }],
                ani01_p_out_2: [-13, -50, { start: 55.2, end: 62, easing: "ease-in-out" }],

                ani02_p_in: [64, 0, { start: 9.5, end: 12, easing: "ease-in-out" }],
                ani02_p_out: [0, -150, { start: 13.5, end: 17, easing: "ease-in-out" }],
                ani03_p_in: [64, 0, { start: 14.98, end: 17.48, easing: "ease-in-out" }],
                ani03_p_out: [0, -150, { start: 18.98, end: 22.48, easing: "ease-in-out" }],
                ani04_p_in: [64, 0, { start: 20, end: 22.56, easing: "ease-in-out" }],
                ani04_p_out: [0, -150, { start: 26.06, end: 29.56, easing: "ease-in-out" }],
                ani05_p_in: [64, 0, { start: 27.15, end: 29.65, easing: "ease-in-out" }],
                ani05_p_out: [0, -150, { start: 33.15, end: 36.65, easing: "ease-in-out" }],
                ani06_p_in: [64, 0, { start: 34.08, end: 36.58, easing: "ease-in-out" }],
                ani06_p_out: [0, -150, { start: 40.47, end: 44.5, easing: "ease-in-out" }],

                ani07_01_p_in: [64, 0, { start: 41.7, end: 44, easing: "ease-in-out" }],
                ani07_01_p_in_2: [0, 3, { start: 47.8, end: 51.88, easing: "ease-in-out" }],
                ani07_01_p_out: [3, -50, { start: 55, end: 59.64, easing: "ease-in-out" }],

                ani07_02_p_in: [64, 0, { start: 43.5, end: 45.28, easing: "ease-in-out" }],
                ani07_02_p_in_2: [0, 3, { start: 48.6, end: 53.5, easing: "ease-in-out" }],
                ani07_02_p_out: [3, -50, { start: 55.4, end: 59.5, easing: "ease-in-out" }],

                ani07_03_p_in: [54, 0, { start: 43.5, end: 48, easing: "ease-in-out" }],
                ani07_03_p_in_2: [0, 3, { start: 49.87, end: 53, easing: "ease-in-out" }],
                ani07_03_p_out: [3, -60, { start: 54, end: 58.5, easing: "ease-in-out" }],

                ani07_04_p_in: [49, 0, { start: 45.5, end: 48.5, easing: "ease-in-out" }],
                ani07_04_p_in_2: [0, 3, { start: 50.7, end: 53, easing: "ease-in-out" }],
                ani07_04_p_out: [3, -65, { start: 54, end: 58.08, easing: "ease-in-out" }],

                ani07_05_p_in: [44, 0, { start: 47, end: 50, easing: "ease-in-out" }],
                ani07_05_p_in_2: [0, 1.5, { start: 51.76, end: 53, easing: "ease-in-out" }],
                ani07_05_p_out: [1.5, -70, { start: 53.7, end: 57.77, easing: "ease-in-out" }],
                ani08_p_in: [64, 0, { start: 61.5, end: 64, easing: "ease-in-out" }],
            }
        }

        this.canvas = introDocument.querySelector(".intro-canvas");
        this.context = this.canvas.getContext('2d', {
            alpha: false
        });

        // this.canvasWebGl = document.querySelector('canvas.webgl');
        this.header = new Header();

        this.localizationManager = new LocalizationManager();

        // Guide Popup
        this.guidePopup = new GuidePopup();

        // Solution
        this.solution = new Solution();

        this.introWindow.addEventListener('resize', () => {
            const progress = this.calculateProgress();
            this.renderImage(progress);
            this.animateText(progress);
        }, true);

        const ourSolutionsBtn = introDocument.querySelector('.our-solutions-btn');
        ourSolutionsBtn.addEventListener("click", (event) => {
            this.finish();
            console.log("click!!!!");
        });

        this.init();
    }

    init() {
        this.revisions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        this.images = [];

        this.loadImages();
    }

    convertPath(index) {
        return `https://d3jbqc1s47j1ve.cloudfront.net/20220408/${FRAME}fps_${QUALITY}/intro_${QUALITY}_${Number(index).toString().padStart(5, '0')}.webp`; // fhd
    }

    removeRevision(revision) {
        const index = this.revisions.indexOf(revision);
        if (index >= 0) {
            this.revisions.splice(index, 1);
        }
    }

    loadImages(revision = 0, progress = true) {
        this.removeRevision(revision);

        let totalCount = 0;
        let loadedCount = 0;

        const loaded = () => {
            loadedCount++;
            if (progress) {
                this.loading.progress(loadedCount / totalCount);
            }

            if (loadedCount === totalCount) {
                requestAnimationFrame(() => this.nextImages());
            }
        }

        for (let i = revision; i < MAX_FRAME_COUNT; i = i + INTERVAL) {
            totalCount++;

            if (this.images[i]) {
                loaded();
                continue;
            }

            const img = new Image();
            img.src = this.convertPath(i);
            this.images[i] = img;
            img.onload = () => {
                loaded();
            }
            img.onerror = () => {
                loaded();
            }
        }
    }

    nextImages() {
        console.log('nextImages : ' + this.revisions);
        if (this.revisions.length > 0) {
            const index = Math.floor(Math.random() * (this.revisions.length - 1));
            const nextRevision = this.revisions[index];
            this.loadImages(nextRevision, false);
        }
    }

    start() {
        this.guidePopup.show(GUIDE_POPUP_ICON.intro, GUIDE_POPUP_LABEL.intro);

        // this.header.solutionOut();
        this.solution.solutionOut();

        this.container.style.overflow = 'hidden auto';
        this.container.style.height = `${this.introHeight}px`;

        // 인트로의 경우 스크롤이 iframe안에 있어서 따로 처리
        const introGuide = () => {
            if (this.introWindow.pageYOffset > 120) {
                this.guidePopup.hide();
                this.introWindow.removeEventListener('scroll', introGuide);
            }
        }
        this.introWindow.addEventListener('scroll', introGuide)

        this.isFinish = false;
        this.prevProgress = -1;
        requestAnimationFrame(() => this.animate());
    }


    imageFromFrame(frame) {
        if (this.images === null) {
            return null;
        }

        const img = this.images[frame];
        if (img && img.complete) {
            return img;
        }

        return this.searchImage(frame);
    }

    searchImage(frame) {
        if (frame === 0) {
            return undefined;
        }

        const img = this.images[frame - 1];
        if (img && img.complete) {
            return img;
        }

        return this.searchImage(frame - 1);
    }

    animate() {
        const progress = this.calculateProgress();

        if (progress !== this.prevProgress) {
            this.renderImage(progress);
            this.animateText(progress);
        }

        this.prevProgress = progress;

        if (!this.isFinish) {
            setTimeout(() => {
                requestAnimationFrame(() => this.animate());
            }, 1000 / 30);
        }
    }

    calculateProgress() {
        let progress = this.introWindow.pageYOffset / (this.container.offsetHeight - this.introWindow.innerHeight);
        if (progress < 0) progress = 0;
        if (progress > 1) progress = 1;

        return progress;
    }

    renderImage(progress) {
        const frame = Math.round((MAX_FRAME_COUNT - 1) * progress);

        const width = this.introWindow.innerWidth;
        const height = this.introWindow.innerHeight;
        this.context.canvas.width = width;
        this.context.canvas.height = height;

        const img = this.imageFromFrame(frame);

        if (!img) {
            return true;
        }

        const hScale = height / img.height;
        const wScale = width / img.width;
        const scale = (hScale > wScale) ? hScale : wScale;

        this.context.drawImage(
            img,
            (width - img.width * scale) / 2,
            (height - img.height * scale) / 2,
            img.width * scale,
            img.height * scale
        );
    }

    animateText(progress) {
            const progressPercent = progress * 100;
            console.log("animateText : " + progressPercent);
            const val = this.introTextInfo.values;
            const elem = this.introTextInfo.element;

            if (progressPercent > val.ani01_p_in[2].start && progressPercent < val.ani01_p_out[2].start) {
                elem.elem01.style.display = 'block';
                elem.elem01.style.transform = `
                  translate3d(0, ${calculateValues(progressPercent, val.ani01_p_in)}%, 0) 
                  scale(${calculateValues(progressPercent, val.ani01_s_in)})`;
            } else if (progressPercent > val.ani01_p_out[2].start && progressPercent < val.ani01_p_out[2].end) {
                elem.elem01.style.display = 'block';
                elem.elem01.style.transform = `
                  translate3d(0, ${calculateValues(progressPercent, val.ani01_p_out)}%, 0) 
                  scale(${calculateValues(progressPercent, val.ani01_s_out)})`;
            } else if (progressPercent > val.ani01_p_out_2[2].start && progressPercent < val.ani01_p_out_2[2].end) {
                elem.elem01.style.display = 'block';
                elem.elem01.style.transform = `
                translate3d(0, ${calculateValues(progressPercent, val.ani01_p_out_2)}%, 0)`;
            } else if (progressPercent > val.ani01_p_out_2[2].end + 5 || progressPercent < val.ani01_p_in[2].start) {
                elem.elem01.style.display = 'none';
            }

            for (let i = 2; i < 7; i++) {
                if (progressPercent > eval(`val.ani0${i}_p_in`)[2].start && progressPercent < eval(`val.ani0${i}_p_out`)[2].start) {
                    eval(`elem.elem0${i}`).style.display = 'block';
                    eval(`elem.elem0${i}`).style.transform = `
                      translate3d(0, ${calculateValues(progressPercent, eval(`val.ani0${i}_p_in`))}%, 0)`;
            } else if (progressPercent > eval(`val.ani0${i}_p_out`)[2].start && progressPercent < eval(`val.ani0${i}_p_out`)[2].end) {
                eval(`elem.elem0${i}_child`).style.transform = `
                    translate3d(0, ${calculateValues(progressPercent, eval(`val.ani0${i}_p_out`))}%, 0)`;
                eval(`elem.elem0${i}`).style.display = 'block';
            } else {
                eval(`elem.elem0${i}`).style.display = 'none';
            }
        }

        for(let i = 1; i < 6; i++) {
            if (progressPercent > eval(`val.ani07_0${i}_p_in`)[2].start && progressPercent < eval(`val.ani07_0${i}_p_in_2`)[2].start) {
                eval(`elem.elem07_child0${i}`).style.display = 'block';
                eval(`elem.elem07_child0${i}`).style.transform = `
                      translate3d(0, ${calculateValues(progressPercent, eval(`val.ani07_0${i}_p_in`))}vh, 0)`;
            } else if (progressPercent > eval(`val.ani07_0${i}_p_in_2`)[2].start && progressPercent < eval(`val.ani07_0${i}_p_out`)[2].start){
                eval(`elem.elem07_child0${i}`).style.display = 'block';
                eval(`elem.elem07_child0${i}`).style.transform = `
                      translate3d(0, ${calculateValues(progressPercent, eval(`val.ani07_0${i}_p_in_2`))}vh, 0)`;
            } else if (progressPercent > eval(`val.ani07_0${i}_p_out`)[2].start && progressPercent < eval(`val.ani07_0${i}_p_out`)[2].end) {
                eval(`elem.elem07_child0${i}`).style.transform = `
                    translate3d(0, ${calculateValues(progressPercent, eval(`val.ani07_0${i}_p_out`))}vh, 0)`;
                eval(`elem.elem07_child0${i}`).style.display = 'block';
            } else {
                eval(`elem.elem07_child0${i}`).style.display = 'none';
            }
        }

        if (progressPercent > val.ani08_p_in[2].start && progressPercent < val.ani08_p_in[2].end) {
            elem.elem08.style.display = 'block';
            elem.elem08.style.transform = `
                  translate3d(0, ${calculateValues(progressPercent, val.ani08_p_in)}%, 0)`;
        } else if (progressPercent < val.ani08_p_in[2].start) {
            elem.elem08.style.display = 'none';
        } else if (progressPercent > val.ani08_p_in[2].end) {
            elem.elem08.style.display = 'block';
            elem.elem08.style.transform = `
                translate3d(0, 0, 0)`;
        }
    }

    finish() {
        this.isFinish = true;

        fadeOut(this.canvas);

        this.header.show();

        setTimeout(() => {
            this.guidePopup.guideType = 'webgl';
            this.guidePopup.isAddEvent = true;
            this.guidePopup.show(GUIDE_POPUP_ICON.webgl, GUIDE_POPUP_LABEL.webgl);
            this.introIframe.remove();
        }, 1100);

        this.images = null;

        const exp = new Experience();
        exp.start();
    }
}