export default [{
        name: "environmentMapTexture",
        type: "cubeTexture",
        path: [
            "textures/environmentMap/cube_env_04/posx.png",
            "textures/environmentMap/cube_env_04/negx.png",
            "textures/environmentMap/cube_env_04/posy.png",
            "textures/environmentMap/cube_env_04/negy.png",
            "textures/environmentMap/cube_env_04/posz.png",
            "textures/environmentMap/cube_env_04/negz.png",
        ],
    },
    //LUT
    // { name: "Bourbon64", type: "LUTCube", path: "luts/Bourbon 64.CUBE" },
    //MODELING
    {
        name: "cityModel",
        type: "gltf-BinaryModel",
        path: "models/City/Smart_City_011_compress.glb", //Smart_City_011_compress
    },
    {
        name: "cityBuildingModel",
        type: "gltf-BinaryModel",
        path: "models/City/Smart_City_Building_002.glb",
    },
    {
        name: "cityUndergroundModel",
        type: "gltf-BinaryModel",
        path: "models/City/UNDER_02.glb",
    },
    {
        name: "natureModel",
        type: "gltf-BinaryModel",
        path: "models/City/Nature_06.glb",
    },
    {
        name: "pinModel",
        type: "gltf-BinaryModel",
        path: "models/City/PIN.glb",
    },
    {
        name: "embracingTheFuture",
        type: "gltf-BinaryModel",
        path: "models/City/TEXT_FUTURE.glb",
    },
    {
        name: "humanCentered",
        type: "gltf-BinaryModel",
        path: "models/City/TEXT_HUMAN.glb",
    },
    {
        name: "coexistingWithNature",
        type: "gltf-BinaryModel",
        path: "models/City/TEXT_NATURE.glb",
    },
    /*
WALKABLE_CITY_0: 101, // 1-1
WALKABLE_CITY_1: 102, // 1-2* %
HYPON_DENSITY_0: 111, // 2-1
HYPON_DENSITY_1: 112, // 2-2
CONVENIENCE_CITY_0: 121, // 3-1* %
CONVENIENCE_CITY_1: 122, // 3-2*
EMBRACES_NATURE_0: 201, // 4-1*
EMBRACES_NATURE_1: 202, // 4-2
NATURE_ECOSYSTEM_0: 211, // 5-1* %
NATURE_ECOSYSTEM_1: 212, // 5-2* %
SUSTAINABLE_CITY_0: 221, // 6-1* %
SUSTAINABLE_CITY_1: 222, // 6-2*
GIRD_SYSTEM_0: 301, // 7-1* %
GIRD_SYSTEM_1: 302, // 7-2* %
GIRD_SYSTEM_2: 303, // 7-3* %
CONNECTED_CITY_0: 311, // 8-1* %
HUB20_0: 321, // 9-1* %
*/
    {
        name: "WALKABLE_CITY_1",
        type: "gltf-BinaryModel",
        path: "models/City/1_2.glb",
    },
    {
        name: "CONVENIENCE_CITY_0",
        type: "gltf-BinaryModel",
        path: "models/City/3_1.glb",
    },
    {
        name: "CONVENIENCE_CITY_1",
        type: "gltf-BinaryModel",
        path: "models/City/3_2.glb",
    },
    {
        name: "NATURE_ECOSYSTEM_0",
        type: "gltf-BinaryModel",
        path: "models/City/5_1.glb",
    },
    {
        name: "NATURE_ECOSYSTEM_1",
        type: "gltf-BinaryModel",
        path: "models/City/5_2.glb",
    },
    {
        name: "SUSTAINABLE_CITY_0",
        type: "gltf-BinaryModel",
        path: "models/City/6_1.glb",
    },
    {
        name: "GIRD_SYSTEM_0",
        type: "gltf-BinaryModel",
        path: "models/City/7_1.glb",
    },
    {
        name: "GIRD_SYSTEM_1",
        type: "gltf-BinaryModel",
        path: "models/City/7_2.glb",
    },
    {
        name: "GIRD_SYSTEM_2",
        type: "gltf-BinaryModel",
        path: "models/City/7_3.glb",
    },
    {
        name: "CONNECTED_CITY_0",
        type: "gltf-BinaryModel",
        path: "models/City/8_1.glb",
    },
    {
        name: "HUB20_0",
        type: "gltf-BinaryModel",
        path: "models/City/9_1.glb",
    },

    //animation
    {
        name: "undergroundCar",
        type: "gltf-BinaryModel",
        path: "models/City/0121_UNDER_CAR_1F.glb",
    },

    //textures
    //City
    {
        name: "groundColorTexture",
        type: "texture",
        path: "textures/GROUND_Color_C.jpg",
    },
    // {
    //     name: "groundColorTexture2",
    //     type: "texture",
    //     path: "textures/GROUND_Color_A.jpg",
    // },
    {
        name: "groundCityAlphaTexture",
        type: "texture",
        path: "textures/GROUND_CITY_Alpha.jpg",
    },

    {
        name: "groundNatureSpeculerTexture",
        type: "texture",
        path: "textures/GROUND_SP.png",
    },

    //ETC
    // { name: "cloudTexture", type: "texture", path: "textures/cloud_A01.png" },
    // { name: "gradientBGTexture", type: "texture", path: "textures/GradientBG.png" },

    //nature
    {
        name: "natureAlphaTexture",
        type: "texture",
        path: "textures/NATURE_Alpha.jpg",
    },
    {
        name: "natureThickTexture",
        type: "texture",
        path: "textures/GROUND_Color_A_thickness.jpg",
    },
    {
        name: "natureSpeculerTexture",
        type: "texture",
        path: "textures/WATER_SP.jpg",
    },
    {
        name: "shipTexture",
        type: "texture",
        path: "textures/SHIP.png",
    },

    {
        name: "matcapWhite",
        type: "texture",
        path: "textures/matcap-porcelain-white.jpg",
    },
    {
        name: "titleMatcap",
        type: "texture",
        path: "textures/titleMatcap.jpg",
    },


    // AO TEXTURES
    {
        name: "AOmap",
        type: "texture",
        path: "textures/AOmap_2k.jpg",
    },
    // AO TEXTURES
    {
        name: "whiteDot",
        type: "texture",
        path: "textures/whiteDot.jpg",
    },
];