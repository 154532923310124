import * as THREE from "three";
import Experience from "../Experience.js";
import gsap from "gsap";
import { Timeline } from "gsap/gsap-core";
import StateMachine from "../StateMachine.js";
import { mergeUniforms } from "three";
export default class Nature {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.renderer = this.experience.renderer;
        this.resources = this.experience.resources;
        this.stateMachine = this.experience.stateMachine;
        this.time = this.experience.time;
        this.debug = this.experience.debug;
        this.group = new THREE.Group();
        this.colorSettings = {
            ship: "#ffffff",
        };
        this.materials = {
            natureMaterial: null,
            treeMaterial: null,
        };
        this.natureUniform = {
            lerp: { value: 0.0 },
            map2: { value: this.experience.resources.items.groundColorTexture2 },
        };
        this.resource = this.resources.items.natureModel;

        this.setMaterial();
        this.setModel();
        this.setStateMachine();

        this.setDebug();
    }

    setDebug() {
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder("Nature");
            this.debugFolder.close();
            this.debugFolder
                .addColor(this.materials.natureMaterial, "color")
                .name("nature");
            this.debugFolder
                .addColor(this.materials.shipMaterial, "color")
                .name("ship");
        }
    }

    setStateMachine() {
        this.stateMachine.on("stateChange", (value) => {
            let currentState = this.stateMachine.State;
            var tl = new Timeline();
            switch (currentState) {
                case StateMachine.States.INITAL_STATE:
                case StateMachine.States.HUMAN_CENTERED:
                    this.group.visible = true;
                    tl.to(this.group.position, {
                        duration: 1.5,
                        ease: "power2.inOut",
                        y: 0,
                    });
                    tl.to(
                        this.group.scale, {
                            duration: 1.5,
                            delay: 0,
                            ease: "power2.inOut",
                            x: 1,
                            y: 1,
                            z: 1,
                        },
                        0
                    );
                    tl.to(
                        this.natureUniform.lerp, {
                            value: 0,
                            delay: 0.0,
                            duration: 2,
                            ease: "power1.Out",
                            onUpdate: (value) => {
                                this.materials.natureMaterial.needsUpdate = true;
                            },
                        },
                        0
                    );
                    tl.to(
                        this.materials.natureMaterial, {
                            alphaTest: 0.02,
                            delay: 0.5,
                            duration: 1,
                            ease: "power2.inOut",
                            onUpdate: (value) => {
                                this.materials.natureMaterial.needsUpdate = true;
                            },
                        },
                        0
                    );
                    tl.to(
                        this.materials.natureMaterial.color, {
                            r: 68 / 256,
                            g: 129 / 256,
                            b: 228 / 256,
                            duration: .5,
                            ease: "power2.inOut",
                        },
                        0
                    );
                    if (this.walkingPath) {
                        this.walkingPath.visible = true;
                    }
                    break;
                case StateMachine.States.EMBRACING_FUTURE:
                    tl.to(this.group.position, {
                        duration: 1.5,
                        ease: "power2.inOut",
                        y: 0,
                    });
                    tl.to(
                        this.group.scale, {
                            x: 1,
                            y: 1,
                            z: 1,
                            duration: 1.7,
                            ease: "power1.inOut",
                        },
                        0
                    );


                    tl.to(
                        this.materials.natureMaterial.color, {
                            r: 68 / 256,
                            g: 129 / 256,
                            b: 228 / 256,
                            duration: .5,
                            ease: "power2.inOut",
                        },
                        0
                    );
                    tl.to(
                        this.natureUniform.lerp, {
                            value: 0,
                            delay: 0.0,
                            duration: 2,
                            ease: "power1.Out",
                            onUpdate: (value) => {
                                this.materials.natureMaterial.needsUpdate = true;
                            },
                        },
                        0
                    );
                    tl.to(
                        this.materials.natureMaterial, {
                            alphaTest: 0.02,
                            delay: 0.0,
                            duration: 1.5,
                            ease: "power1.Out",
                            onUpdate: (value) => {
                                this.materials.natureMaterial.needsUpdate = true;
                            },
                        },
                        0
                    );

                    break;
                case StateMachine.States.SEPERATE:
                    this.group.visible = true;
                    tl.to(this.group.position, {
                        duration: 1.5,
                        ease: "power1.inOut",
                        y: 96,
                    });
                    tl.to(
                        this.group.scale, {
                            duration: 1.5,
                            // delay: 0.5,
                            ease: "power1.inOut",
                            x: 1, //0.86,
                            y: 1, //0.86,
                            z: 1, //0.86,
                        },
                        0
                    );

                    // tl.to(
                    //     this.materials.natureMaterial, {
                    //         alphaTest: 0.2,
                    //         delay: 0.0,
                    //         duration: 3,
                    //         ease: "power1.Out",
                    //         onUpdate: (value) => {
                    //             this.materials.natureMaterial.needsUpdate = true;
                    //         },
                    //     },
                    //     0
                    // );
                    if (this.walkingPath) {
                        this.walkingPath.visible = false;
                    }
                    break;
                case StateMachine.States.COEXIST_WITH_NATURE:
                    this.group.visible = true;
                    tl.to(this.group.position, {
                        duration: 1.5,
                        ease: "power2.inOut",
                        y: 0,
                    });
                    tl.to(
                        this.group.scale, {
                            duration: 1.5,
                            delay: 0,
                            ease: "power2.inOut",
                            x: 1,
                            y: 1,
                            z: 1,
                        },
                        0
                    );
                case StateMachine.States.EMBRACES_NATURE_0:
                case StateMachine.States.EMBRACES_NATURE_1:
                    tl.to(
                        this.natureUniform.lerp, {
                            value: 1,
                            delay: 0.0,
                            duration: 2,
                            ease: "power1.Out",
                            onUpdate: (value) => {
                                this.materials.natureMaterial.needsUpdate = true;
                            },
                        },
                        0
                    );
                    tl.to(
                        this.materials.natureMaterial.color, {
                            r: 1,
                            g: 1,
                            b: 1,
                            duration: 1,
                            ease: "power2.inOut",
                        },
                        0
                    );
                    tl.to(
                        this.materials.natureMaterial, {
                            alphaTest: 0.02,
                            delay: 0.0,
                            duration: 3,
                            ease: "power1.Out",
                            onUpdate: (value) => {
                                // console.log(this.materials.natureMaterial.alphaTest);
                                this.materials.natureMaterial.needsUpdate = true;
                            },
                        },
                        0
                    );
                    break;

            }

            switch (currentState) {
                case StateMachine.States.CONVENIENCE_CITY_0:
                case StateMachine.States.CONVENIENCE_CITY_1:
                    this.sport.visible = true;
                    this.sport.material = new THREE.MeshStandardMaterial({
                        color: new THREE.Color(0x93ced4)
                    })
                    break;
                default:
                    this.sport.visible = false;
                    break;
            }
        });
    }

    setModel() {
        this.model = this.resource.scene;
        this.model.scale.set(
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale
        );
        this.scene.add(this.group);
        this.group.position.set(0, 0, 0);
        this.group.add(this.model);

        if (this.debugFolder) {}
        this.model.traverse((child) => {
            if (child.name.includes("NATURE_2")) {
                this.GROUND_Nature = child;
                child.material = this.materials.natureMaterial;
            }

            if (child.name.includes("SHIP")) {
                child.material = this.materials.shipMaterial;
            }
            if (child.name.includes("SPORTS")) {
                //child.material = this.materials.shipMaterial;
                this.sport = child;
                child.visible = false;
            }


            if (child.name.includes("WALKING_PATH")) {
                this.walkingPath = child;
            }
            if (child.name.includes("NATURE_THICKNESS")) {
                this.thicknessModel = child;
                this.thicknessModel.material = this.materials.natureThickMaterial;
                // child.visible = false;
            }

            if (child.isMesh) {
                child.castShadow = true;
                child.receiveShadow = true;
            }
        });

        if (this.debugFolder) {}
    }

    setMaterial() {
        this.materials = {
            natureMaterial: new THREE.MeshStandardMaterial({
                color: new THREE.Color(0x4481e4),
                map: this.experience.resources.items.groundColorTexture,
                roughnessMap: this.experience.resources.items.natureSpeculerTexture,
                alphaMap: this.experience.resources.items.natureAlphaTexture,
                alphaTest: 0.02,
                transparent: false,
            }),

            shipMaterial: new THREE.MeshStandardMaterial({
                color: new THREE.Color(1, 1, 1),
                map: this.experience.resources.items.shipTexture,
            }),
            natureThickMaterial: new THREE.MeshStandardMaterial({
                color: new THREE.Color(0x4481e4),
                map: this.experience.resources.items.groundColorTexture,
            })
        };
        this.materialArray = [
            this.materials.natureMaterial,
            this.materials.shipMaterial,
        ];

        const material = this.materials.natureMaterial;

        material.onBeforeCompile = (shader) => {
            //this.experience.world.environment.setCSMMaterial(material, shader);
            shader.uniforms.lerp = this.natureUniform.lerp;
            shader.vertexShader = shader.vertexShader.replace(
                "void main()",
                `
                uniform float lerp;
                varying vec3 vPosition;
                void main()
                `
            );

            shader.fragmentShader = shader.fragmentShader.replace(
                "void main()",
                `
                uniform float lerp;
                varying vec3 vPosition;
                void main()
                `
            );
            shader.fragmentShader = shader.fragmentShader.replace(
                "#include <map_fragment>",
                `
        #ifdef USE_MAP
        
        vec2 vUv_A = vec2(vUv.x / 2.0 ,vUv.y);
        vec2 vUv_B = vec2(vUv.x / 2.0 + 0.5 ,vUv.y);
        vec4 texelColor = texture2D( map, vUv_A );

    
        vec4 texelColor2 = texture2D( map, vUv_B );
        diffuseColor = mix(texelColor,texelColor2,lerp) * diffuseColor;

        #endif
        `
            );
            shader.fragmentShader = shader.fragmentShader.replace(
                "#include <color_fragment>",
                `#if defined( USE_COLOR_ALPHA )
              diffuseColor *= vColor;
          #elif defined( USE_COLOR )
              diffuseColor.rgb *= vColor;
          #endif
          `
            );

            shader.fragmentShader = shader.fragmentShader.replace(
                "#include <premultiplied_alpha_fragment>",
                ``
            );
            shader.fragmentShader = shader.fragmentShader.replace(
                "vec3 totalSpecular = reflectedLight.directSpecular + reflectedLight.indirectSpecular;",
                `vec3 totalSpecular = reflectedLight.indirectSpecular * 10.0;`
            );
            shader.fragmentShader = shader.fragmentShader.replace(
                "#include <output_fragment>",
                `
                    #ifdef OPAQUE
                    diffuseColor.a = 1.0;
                    #endif

                    // https://github.com/mrdoob/three.js/pull/22425
                    #ifdef USE_TRANSMISSION
                    diffuseColor.a *= transmissionAlpha + 0.1;
                    #endif
                    diffuseColor.a = 1.0;

                    gl_FragColor = vec4( outgoingLight, diffuseColor.a );
                    `
            );

            // console.log(shader.vertexShader);
            // console.log(shader.fragmentShader);
        };
    }

    update() {}
}