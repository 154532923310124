import * as THREE from "three";
import Experience from "../../Experience.js";
import { Timeline } from "gsap/gsap-core";
import StateMachine from "../../StateMachine.js";

export default class GIRD_SYSTEM_2 {
    constructor() {
        this.CLASS_STATE = StateMachine.States.GIRD_SYSTEM_2;
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;
        this.pointer = this.experience.pointer;

        this.cityGroup = this.experience.world.city.group;
        this.natureGroup = this.experience.world.nature.group;
        this.undergroundGroup = this.experience.world.underground.group;

        this.renderer = this.experience.renderer;
        this.resources = this.experience.resources;
        this.stateMachine = this.experience.stateMachine;
        this.time = this.experience.time;
        this.debug = this.experience.debug;

        this.group = new THREE.Group();
        this.group.scale.set(
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale
        );
        this.animations = [];
        this.setMaterial();
        this.setModel();
        this.hide();
        this.setStateMachine();
        this.pointer.on("mousemove", () => {});
        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder("GIRD_SYSTEM_2");
            this.debugFolder.close();

            for (let index = 0; index < this.materials.length; index++) {
                const material = this.materials[index];
                this.debugFolder
                    .addColor(this.materials[index], "color")
                    .onChange((value) => {
                        this.materials[index].color = value;
                    });

            }

        }
    }

    setStateMachine() {
        this.stateMachine.on("stateChange", (value) => {
            let currentState = this.stateMachine.State;
            // console.log(this.CLASS_STATE, this.stateMachine.State);
            switch (currentState) {
                case this.CLASS_STATE:
                    this.show();
                    break;
                case StateMachine.States.HYPON_DENSITY_0:
                case StateMachine.States.WALKABLE_CITY_0:
                    

                    break;
                default:
                    this.hide();
                    break;
            }
        });
    }

    show() {
        this.group.visible = true;
        this.materials[3].color = new THREE.Color(0x4699a4);
        this.materials[4].color = new THREE.Color(0xd68a1f);
        this.materials[5].color = new THREE.Color(0x728227);
    }

    hide() {
        this.group.visible = false;
        this.materials[3].color = new THREE.Color(0xffffff);
        this.materials[4].color = new THREE.Color(0xffffff);
        this.materials[5].color = new THREE.Color(0xffffff);
    }

    setModel() {

        // this.group.add(this.resources.items.GIRD_SYSTEM_0.scene);
        this.model = this.resources.items.GIRD_SYSTEM_2;
        this.group.add(this.model.scene);
        this.scene.add(this.group);

        let clip0 = this.model.animations[0].clone();
        clip0.tracks = [clip0.tracks[0], clip0.tracks[1], clip0.tracks[2]];
        let root = this.model.scene.children[1].children[0]; //7_3_LoopAni_01
        // clip0.duration = 11;
        let duration = clip0.duration;
        let length = 10;
        for (let i = 0; i < length; i++) {
            let animation = {};
            let instance = new THREE.Mesh(root.geometry, this.materials[0]);
            instance.name = root.name;
            root.parent.add(instance);
            instance.position.set(root.position.x, root.position.y, root.position.z);
            animation.mixer = new THREE.AnimationMixer(instance);
            animation.actions = {};
            animation.actions.idle = animation.mixer.clipAction(clip0);
            animation.actions.current = animation.actions.idle;
            animation.actions.current.play();
            animation.mixer.setTime(duration / length * i);
            this.animations.push(animation);
        }

        const clip1 = this.model.animations[0].clone();
        clip1.tracks = [clip1.tracks[3], clip1.tracks[4], clip1.tracks[5]];
        root = this.model.scene.children[2].children[0]; //7_3_LoopAni_01
        duration = clip1.duration;
        length = 10;
        for (let i = 0; i < length; i++) {
            let instance = new THREE.Mesh(root.geometry, this.materials[1]);
            instance.name = root.name;
            root.parent.add(instance);
            instance.position.set(root.position.x, root.position.y, root.position.z);

            let animation = {};
            animation.mixer = new THREE.AnimationMixer(instance);
            animation.actions = {};
            animation.actions.idle = animation.mixer.clipAction(clip1);
            animation.actions.current = animation.actions.idle;
            animation.actions.current.play();
            animation.mixer.setTime(duration / length * i);
            this.animations.push(animation);
        }

        for (let i = 0; i < 4; i++) {
            const clip00 = this.model.animations[0].clone();
            clip00.tracks = [clip00.tracks[6 + i * 3], clip00.tracks[7 + i * 3], clip00.tracks[8 + i * 3]];
            root = this.model.scene.children[3 + i].children[0]; //7_3_LoopAni_01
            root.material = this.materials[2];
            let animation = {};
            animation.mixer = new THREE.AnimationMixer(root);
            animation.actions = {};
            animation.actions.idle = animation.mixer.clipAction(clip00);
            animation.actions.current = animation.actions.idle;
            animation.actions.current.play();
            this.animations.push(animation);
        }


    }

    setMaterial() {
        this.materials = [];
        this.materials.push(
            new THREE.MeshMatcapMaterial({
                color: new THREE.Color(0x0b164b),
                matcap: this.resources.items.matcapWhite
            }),
            new THREE.MeshMatcapMaterial({
                color: new THREE.Color(0x0e3b3f),
                matcap: this.resources.items.matcapWhite
            }),
            new THREE.MeshMatcapMaterial({
                color: new THREE.Color(0xffac38),
                matcap: this.resources.items.matcapWhite
            })
        );



        for (let i = 0; i < this.materials.length; i++) {
            let material = this.materials[i];
            material.onBeforeCompile = (shader) => {
                // console.log(shader.fragmentShader);
                shader.fragmentShader = shader.fragmentShader.replace(
                    "vec3 totalDiffuse = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse;",
                    `
                    float diffuseLength = length(reflectedLight.directDiffuse);
                    vec3 diffuseNormalized = normalize(reflectedLight.directDiffuse);
                    diffuseLength =  max(.03,diffuseLength);
                    vec3 totalDiffuse = diffuseNormalized * diffuseLength + reflectedLight.indirectDiffuse * 0.0;`
                );
                shader.fragmentShader = shader.fragmentShader.replace(
                    "vec3 totalSpecular = reflectedLight.directSpecular + reflectedLight.indirectSpecular;",
                    `vec3 totalSpecular = reflectedLight.indirectSpecular * 0.0;`
                );

                shader.fragmentShader = shader.fragmentShader.replace(
                    "vec3 outgoingLight = totalDiffuse + totalSpecular + totalEmissiveRadiance;",
                    `vec3 outgoingLight = totalDiffuse + totalSpecular * 0.0; + totalEmissiveRadiance * 0.0;;`
                );
                //
                // console.log(shader.vertexShader);

            };
        }

        this.resources.items.cityBuildingModel.scene.traverse((child) => {
            if (child.name.includes("C_01_BLD_HIGH")) {
                this.materials.push(child.material);
                //child.material.color = new THREE.Color(0x7d5194);
            }
            if (child.name.includes("C_01_BLD_MID")) {
                this.materials.push(child.material);
                //child.material.color = new THREE.Color(0x629852);
            }
            if (child.name.includes("C_01_BLD_LOW")) {
                this.materials.push(child.material);
                //child.material.color = new THREE.Color(0xd1c7bb);
            }
        });
    }

    update() {
        if (this.group.visible) {
            for (let index = 0; index < this.animations.length; index++) {
                const animation = this.animations[index];
                animation.mixer.update(this.time.delta * 0.001);
                // if (animation.mixer._root.name == "7_2_LoopAni_02_2") {
                //     animation.mixer._root.rotation.set(0, 0, -Math.PI / 2);
                // }
            }
        }
    }
}