import {fadeIn, fadeOut} from "./Utils/TransitionHelper";
import Header from "./Header";
import Intro from "./Intro";
import lottie from "./lottie";
import LocalizationManager from "./LocalizationManager";
import {deviceType} from "./Utils/DeviceType";

export default class Loading {
    constructor() {

        const introIframe = document.querySelector('.intro-iframe');
        const introDocument = introIframe.contentWindow.document;
        this.container = introDocument.body;

        this.loadingBg = introDocument.querySelector(".loading-container");
        this.loadingContainer = introDocument.querySelector("div.loading");
        this.stateValText = introDocument.querySelector(".state-val-text");

        this.languageContainer = introDocument.querySelector('.language');
        this.languageBtnKo = introDocument.querySelector('.language-btn-ko');
        this.languageBtnEn = introDocument.querySelector('.language-btn-en');

        this.introContainer = introDocument.querySelector('.intro');

        this.header = new Header();
        this.intro = new Intro();

        this.localizationManager = new LocalizationManager();

        fadeIn(this.loadingBg);
        fadeIn(this.loadingContainer);

        this.toggleAudio = () => {
            this.audioPlaying = this.header.toggleAudio();
            if (this.audioPlaying) {
                introDocument.querySelector(".intro-audio-btn .stop").style.display = 'none';
                introDocument.querySelector(".intro-audio-btn .play").style.display = 'block';
            } else {
                introDocument.querySelector(".intro-audio-btn .play").style.display = 'none';
                introDocument.querySelector(".intro-audio-btn .stop").style.display = 'block';
            }
        }

        this.audioBtn = introDocument.querySelector("div.intro-audio-btn");
        this.audioBtn.addEventListener("click", (event) => {
            this.toggleAudio();
        });

        this.languageBtnClick = (lang) => {
            this.audioBtn.style.display= 'block';
            this.toggleAudio();

            this.intro.start();
            fadeOut(this.loadingBg);
            fadeOut(this.languageContainer);
            this.introContainer.style.pointerEvents = 'auto';

            this.header.setLanguage(lang);
            this.localizationManager.changeLanguage(lang);
            introDocument.localizationManager.changeLanguage(lang);


            const introTextRatio = () => {
                let val = 1;
                let val2 = 1;
                if (deviceType() === 'MO' && lang === 'ko') {
                    val = 0.8;
                } else if (deviceType() === 'MO' && lang === 'en') {
                    val = 0.5;
                    val2 = 1.15;
                }
                introDocument.documentElement.style.setProperty('--font-Ratio-1', `${val}`);
                introDocument.documentElement.style.setProperty('--font-Ratio-2', `${val2}`);

            }
            introTextRatio();

            introIframe.contentWindow.addEventListener('resize', introTextRatio);
            
        }

        // 언어 처리, 호버 효과 처리
        this.languageBtnKo.addEventListener("click", () => this.languageBtnClick("ko"));
        this.languageBtnEn.addEventListener("click", () => this.languageBtnClick("en"));

        this.languageBtnKo.addEventListener('mouseenter', () => {
            this.languageBtnKo.classList.add('active');
            this.languageBtnEn.classList.add('inactive');
        })

        this.languageBtnKo.addEventListener('mouseleave', () => {
            this.languageBtnKo.classList.remove('active');
            this.languageBtnEn.classList.remove('inactive');
        })

        this.languageBtnEn.addEventListener('mouseenter', () => {
            this.languageBtnEn.classList.add('active');
            this.languageBtnKo.classList.add('inactive');
        })

        this.languageBtnEn.addEventListener('mouseleave', () => {
            this.languageBtnEn.classList.remove('active');
            this.languageBtnKo.classList.remove('inactive');
        })

        this.animation = lottie.loadAnimation({
            container: introDocument.getElementById('loadingProgress'),
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: '../lottie/loading-lottie.json'
        });
    }

    progress(value) {
        console.log('Loading progress : ' + Number(value));
        const progress = Math.round(Number(value) * 100);
        this.stateValText.innerText = progress + '%'

        const maxFrame = 99;
        if (progress === 100) {
            this.animation.goToAndStop(maxFrame, true);
            fadeOut(this.loadingContainer);
            setTimeout(() => {
                fadeIn(this.languageContainer);
            }, 150)

        } else {
            this.animation.goToAndStop(progress * (maxFrame / 100), true);
            fadeIn(this.stateValText);
            fadeOut(this.languageContainer);
            fadeIn(this.loadingContainer);
        }
    }
}


