import * as THREE from "three";

import Debug from "./Utils/Debug.js";
import Sizes from "./Utils/Sizes.js";
import Time from "./Utils/Time.js";
import Pointer from "./Utils/Pointer.js";
import Camera from "./Camera.js";
import Renderer from "./Renderer.js";
import World from "./World/World.js";
import Resources from "./Utils/Resources.js";

import sources from "./sources.js";
import Composer from "./World/Composer.js";
import StateMachine from "./StateMachine.js";
import Popup, { POPUP_EVENT } from "./Popup";
import { SUBMENU } from "./Constants/Constants";

let instance = null;

export default class Experience {
    constructor(_canvas) {
        // Singleton
        if (instance) {
            return instance;
        }
        instance = this;

        // Global access
        window.experience = this;

        // Options
        this.canvas = _canvas;

        this.enabled = false;
        this.started = false;

        // Setup
        this.debug = new Debug();
        //return;
        this.sizes = new Sizes();
        this.pointer = new Pointer();
        this.time = new Time();
        this.stateMachine = new StateMachine();
        this.scene = new THREE.Scene();
        this.resources = new Resources(sources);
        this.camera = new Camera();
        this.renderer = new Renderer();
        this.world = new World();
        this.composer = new Composer();
        this.popup = new Popup();
        this.resources.on("ready", () => {
            this.enabled = true;
            setTimeout(() => {
                if (!this.started) {
                    this.enabled = false;
                }
            }, 1500);
        });

        if (this.debug.active) {
            this.enabled = true;
            this.started = true;
        }
        // Resize event
        this.sizes.on("resize", () => {
            this.resize();
        });

        // Time tick event
        this.time.on("tick", () => {
            this.update();
        });
        // this.render();
    }

    start() {
        this.enabled = true;
        this.started = true;
        this.camera.initalTween();
    }

    resize() {
        this.camera.resize();
        this.renderer.resize();
    }

    update() {
        if (!this.enabled) {
            return;
        }
        if (this.debug.active) {
            //this.debug.Stats.begin();
        }
        this.sizes.update();
        this.renderer.update();
        this.render();

        if (this.debug.active) {
            this.debug.update();
            //this.debug.Stats.end();
        }
    }
    render() {
        this.camera.update();
        if (this.world.update()) {

            this.composer.update();
        }
    }

    destroy() {
        this.sizes.off("resize");
        this.time.off("tick");

        // Traverse the whole scene
        this.scene.traverse((child) => {
            // Test if it's a mesh
            if (child instanceof THREE.Mesh) {
                child.geometry.dispose();

                // Loop through the material properties
                for (const key in child.material) {
                    const value = child.material[key];

                    // Test if there is a dispose function
                    if (value && typeof value.dispose === "function") {
                        value.dispose();
                    }
                }
            }
        });

        this.camera.controls.dispose();
        this.renderer.instance.dispose();

        if (this.debug.active) this.debug.ui.destroy();
    }
}