import * as THREE from "three";
import Experience from "../../Experience.js";
import { Timeline } from "gsap/gsap-core";
import StateMachine from "../../StateMachine.js";

export default class CONNECTED_CITY_0 {
    constructor() {
        this.CLASS_STATE = StateMachine.States.CONNECTED_CITY_0;
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;
        this.pointer = this.experience.pointer;

        this.cityGroup = this.experience.world.city.group;
        this.natureGroup = this.experience.world.nature.group;
        this.undergroundGroup = this.experience.world.underground.group;

        this.renderer = this.experience.renderer;
        this.resources = this.experience.resources;
        this.stateMachine = this.experience.stateMachine;
        this.time = this.experience.time;
        this.debug = this.experience.debug;

        this.group = new THREE.Group();
        this.group.scale.set(
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale
        );

        this.setMaterial();
        this.setModel();
        this.hide();
        this.setStateMachine();
        this.pointer.on("mousemove", () => {});
        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder("CONNECTED_CITY_0");
            this.debugFolder.close();

            for (let index = 0; index < this.materials.length; index++) {
                const material = this.materials[index];
                this.debugFolder
                    .addColor(this.materials[index], "color")
                    .onChange((value) => {
                        this.materials[index].color = value;
                    });

            }

        }
    }

    setStateMachine() {
        this.stateMachine.on("stateChange", (value) => {
            let currentState = this.stateMachine.State;
            switch (currentState) {
                case this.CLASS_STATE:
                    this.show();
                    break;
                default:
                    this.hide();
                    break;
            }
        });
    }

    show() {
        for (let index = 0; index < this.uniforms.length; index++) {
            const element = this.uniforms[index];
            element.uSize.target = 1;
        }
    }

    hide() {
        for (let index = 0; index < this.uniforms.length; index++) {
            const element = this.uniforms[index];
            element.uSize.target = 0;
        }
    }

    setModel() {
        this.model = this.resources.items.CONNECTED_CITY_0;
        this.group.add(this.model.scene);
        this.scene.add(this.group);

        this.model.scene.traverse((child) => {
            if (child.isMesh) {
                child.castShadow = false;
                child.receiveShadow = false;
                if (child.name == "8_1_Line_01") {
                    child.material = this.materials[0];
                    this.line01 = child;
                }

                if (child.name == "8_1_Line_02") {
                    child.material = this.materials[1];
                    this.line02 = child;
                }
            }
        });
    }

    setMaterial() {
        this.materials = [];
        this.materials.push(
            new THREE.MeshStandardMaterial({
                color: new THREE.Color(0xff4c38)
            }),
            new THREE.MeshStandardMaterial({
                color: new THREE.Color(0x40ccce)
            })
        );

        this.uniforms = [];
        for (let i = 0; i < this.materials.length; i++) {
            this.uniforms.push({
                uSize: { value: 0.0, target: 0 },
                uCenter: { value: new THREE.Vector3() }
            });

            let material = this.materials[i];
            material.onBeforeCompile = (shader) => {
                shader.uniforms.uCenter = this.uniforms[i].uCenter;
                shader.uniforms.uSize = this.uniforms[i].uSize;

                shader.vertexShader = shader.vertexShader.replace(
                    "void main()",
                    `
                  varying vec3 vPosition;
                  uniform vec3 uCenter;
                  uniform float uSize;
                  void main()
                  `
                );
                shader.fragmentShader = shader.fragmentShader.replace(
                    "void main()",
                    `
                  varying vec3 vPosition;
                  uniform vec3 uCenter;
                  uniform float uSize;
                  void main()
                  `
                );
                shader.vertexShader = shader.vertexShader.replace(
                    "#include <beginnormal_vertex>",
                    `
                      #include <beginnormal_vertex>
                      vPosition = position;
                  `
                );

                shader.fragmentShader = shader.fragmentShader.replace(
                    "#include <output_fragment>",
                    `
                  #ifdef OPAQUE
                  diffuseColor.a = 1.0;
                  #endif
  
                  // https://github.com/mrdoob/three.js/pull/22425
                  #ifdef USE_TRANSMISSION
                  diffuseColor.a *= transmissionAlpha + 0.1;                  
                  #endif
                  float dst = length(vPosition);
                  dst = dst/(800.0 * 1000.0);
                  dst = max(0.0,min(1.0,dst));

                  
                   if(dst > uSize){
                    discard;     
                   }

                  float alpha = (1.0-min(1.0,dst/uSize));
                  //alpha = pow(alpha,3.0);
                  vec4 finColor = vec4( outgoingLight  , diffuseColor.a );
                  //vec4 finColor = vec4(vec3(dst,dst,dst)  , diffuseColor.a );
                  //vec4 finColor = vec4(vec3(1,0,0)  , diffuseColor.a );
                  //gl_FragColor = vec4(finColor.x,finColor.y,finColor.z,alpha);
                  gl_FragColor = vec4(finColor.x*alpha,finColor.y*alpha,finColor.z*alpha,alpha);
                  `
                );
            };
        }
    }

    update() {

        this.uniforms[0].uCenter.value.set(this.line01.position.x, this.line01.position.y, this.line01.position.z);
        this.uniforms[1].uCenter.value.set(this.line02.position.x, this.line02.position.y, this.line02.position.z);

        for (let i = 0; i < this.materials.length; i++) {
            let value = this.uniforms[i].uSize.value;
            let target = this.uniforms[i].uSize.target;
            this.uniforms[i].uSize.value += (target - value) * .004;
        }
    }
}