import * as THREE from "three";
import Experience from "../../Experience.js";
import { Timeline } from "gsap/gsap-core";
import StateMachine from "../../StateMachine.js";

export default class CONVENIENCE_CITY_1 {
    constructor() {
        this.CLASS_STATE = StateMachine.States.CONVENIENCE_CITY_1;
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;
        this.pointer = this.experience.pointer;

        this.cityGroup = this.experience.world.city.group;
        this.natureGroup = this.experience.world.nature.group;
        this.undergroundGroup = this.experience.world.underground.group;

        this.renderer = this.experience.renderer;
        this.resources = this.experience.resources;
        this.stateMachine = this.experience.stateMachine;
        this.time = this.experience.time;
        this.debug = this.experience.debug;

        this.group = new THREE.Group();
        this.group.scale.set(
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale
        );

        this.setMaterial();
        this.setModel();
        this.hide();
        this.setStateMachine();
        this.pointer.on("mousemove", () => {});
        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder("CONVENIENCE_CITY_1");
            this.debugFolder.close();

            for (let index = 0; index < this.materials.length; index++) {
                const material = this.materials[index];
                this.debugFolder
                    .addColor(this.materials[index], "color")
                    .onChange((value) => {
                        this.materials[index].color = value;
                    });

            }

        }
    }

    setStateMachine() {
        this.stateMachine.on("stateChange", (value) => {
            let currentState = this.stateMachine.State;
            switch (currentState) {
                case this.CLASS_STATE:
                    this.show();
                    break;
                default:
                    this.hide();
                    break;
            }
        });
    }

    show() {
        let tl = new Timeline();
        // this.group.visible = true;
        for (let index = 0; index < this.uniforms.length; index++) {
            //this.uniforms[index].uSize.target = 1;
            tl.to(
                this.uniforms[index].uSize, {
                    value: 1,
                    duration:2,
                    delay: 1.1+0.1 * index,
                    ease: "power1.in"
                },
                0
            );
        }
    }

    hide() {
        let tl = new Timeline();
        // this.group.visible = false;
        for (let index = 0; index < this.uniforms.length; index++) {
            //this.uniforms[index].uSize.target = 0;
            tl.to(
                this.uniforms[index].uSize, {
                    value: 0,
                    duration:2,
                    delay: 0.0 * index,
                    ease: "power1.inOut"
                },
                0
            );
        }
    }

    setModel() {
        this.model = this.resources.items.CONVENIENCE_CITY_1;
        this.group.add(this.model.scene);
        this.scene.add(this.group);

        this.model.scene.traverse((child) => {
            if (child.isMesh) {
                child.castShadow = false;
                child.receiveShadow = false;
                if (child.name == "3_2_mesh_01") {
                    child.material = this.materials[0];
                    let vector = child.geometry.boundingBox.max.clone();
                    vector = vector.sub(child.geometry.boundingBox.min);
                    this.uniforms[0].uLength.value = vector.length()
                }

                if (child.name == "3_2_mesh_02") {
                    child.material = this.materials[1];
                    let vector = child.geometry.boundingBox.max.clone();
                    vector = vector.sub(child.geometry.boundingBox.min);
                    this.uniforms[1].uLength.value = vector.length()
                }

                if (child.name == "3_2_mesh_03") {
                    child.material = this.materials[2];
                    let vector = child.geometry.boundingBox.max.clone();
                    vector = vector.sub(child.geometry.boundingBox.min);
                    this.uniforms[2].uLength.value = vector.length()
                }
            }
        });
    }

    setMaterial() {
        this.materials = [];
        this.uniforms = [];
        this.materials.push(
            new THREE.MeshMatcapMaterial({
                color: new THREE.Color(0xdf3c2a),
                matcap: this.resources.items.matcapWhite
            }),
            new THREE.MeshMatcapMaterial({
                color: new THREE.Color(0x327176),
                matcap: this.resources.items.matcapWhite
            }),
            new THREE.MeshMatcapMaterial({
                color: new THREE.Color(0x717e30),
                matcap: this.resources.items.matcapWhite
            })
        );
        for (let i = 0; i < this.materials.length; i++) {
            this.uniforms.push({
                uSize: { value: 0.0, target: 1 },
                uLength: { value: 0.0},
            });

            let material = this.materials[i];
            material.onBeforeCompile = (shader) => {
                shader.uniforms.uSize = this.uniforms[i].uSize;
                shader.uniforms.uLength = this.uniforms[i].uLength;

                shader.vertexShader = shader.vertexShader.replace(
                    "void main()",
                    `
                  varying vec3 vPosition;
                  uniform float uSize;
                  uniform float uLength;
                  void main()
                  `
                );
                shader.fragmentShader = shader.fragmentShader.replace(
                    "void main()",
                    `
                  varying vec3 vPosition;
                  uniform float uSize;
                  uniform float uLength;
                  void main()
                  `
                );
                shader.vertexShader = shader.vertexShader.replace(
                    "#include <beginnormal_vertex>",
                    `
                      #include <beginnormal_vertex>
                      vPosition = position;
                  `
                );

                shader.fragmentShader = shader.fragmentShader.replace(
                    "#include <output_fragment>",
                    `
                  #ifdef OPAQUE
                  diffuseColor.a = 1.0;
                  #endif
  
                  // https://github.com/mrdoob/three.js/pull/22425
                  #ifdef USE_TRANSMISSION
                  diffuseColor.a *= transmissionAlpha + 0.1;                  
                  #endif
                  float dst = length(vec3(vPosition.x,vPosition.y*1.0,vPosition.z*0.0));
                  dst = dst/(uLength * 1.0);
                  dst = max(0.0,min(1.0,dst));

                   if(dst > uSize){
                       discard;
                   }

                  //vec4 finColor = vec4( vec3(dst,0,0)  , diffuseColor.a );
                  vec4 finColor = vec4( outgoingLight  , diffuseColor.a );
                  gl_FragColor = vec4(finColor.x,finColor.y,finColor.z,finColor.w);
                  `
                );
            };
        }
    }
    update() {}
}