import * as THREE from "three";
import Experience from "../../Experience.js";
import { Timeline } from "gsap/gsap-core";
import StateMachine from "../../StateMachine.js";

export default class NATURE_ECOSYSTEM_1 {
    constructor() {
        this.CLASS_STATE = StateMachine.States.NATURE_ECOSYSTEM_1;
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;
        this.pointer = this.experience.pointer;

        this.cityGroup = this.experience.world.city.group;
        this.natureGroup = this.experience.world.nature.group;
        this.undergroundGroup = this.experience.world.underground.group;

        this.renderer = this.experience.renderer;
        this.resources = this.experience.resources;
        this.stateMachine = this.experience.stateMachine;
        this.time = this.experience.time;
        this.debug = this.experience.debug;

        this.group = new THREE.Group();
        this.group.scale.set(
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale
        );
        this.uniform = {
            uTime: { value: 0.0 }
        };
        this.setMaterial();
        this.setModel();
        this.hide();
        this.setStateMachine();
        this.pointer.on("mousemove", () => {});
    }

    setStateMachine() {
        this.stateMachine.on("stateChange", (value) => {
            let currentState = this.stateMachine.State;
            switch (currentState) {
                case this.CLASS_STATE:
                    this.show();
                    break;
                default:
                    this.hide();
                    break;
            }
        });
    }

    show() {
        this.group.visible = true;
    }

    hide() {
        this.group.visible = false;
    }

    setModel() {
        this.model = this.resources.items.NATURE_ECOSYSTEM_1;
        this.group.add(this.model.scene);
        this.scene.add(this.group);

        this.model.scene.traverse((child) => {
            if (child.isMesh) {
                // child.castShadow = true;
                // child.receiveShadow = true;
                child.material = this.materials[0];
            }
        });
    }

    setMaterial() {
        this.materials = [];
        this.materials.push(
            new THREE.MeshMatcapMaterial({
                color: new THREE.Color(0xff351f),
                transparent: false,
                side: THREE.DoubleSide,
                alphaTest: 0.2,
                map: this.resources.items.whiteDot,
                alphaMap: this.resources.items.whiteDot
            })
        );

        for (let i = 0; i < this.materials.length; i++) {
            let material = this.materials[i];
            material.onBeforeCompile = (shader) => {
                shader.uniforms.uTime = this.uniform.uTime;

                shader.vertexShader = shader.vertexShader.replace(
                    "void main()",
                    `
                  uniform float uTime;
                  void main()
                  `
                );
                shader.fragmentShader = shader.fragmentShader.replace(
                    "void main()",
                    `
                  uniform float uTime;
                  void main()
                  `
                );

                shader.fragmentShader = shader.fragmentShader.replace(
                    "#include <output_fragment>",
                    `
                  #ifdef OPAQUE
                  diffuseColor.a = 1.0;
                  #endif
  
                  // https://github.com/mrdoob/three.js/pull/22425
                  #ifdef USE_TRANSMISSION
                  diffuseColor.a *= transmissionAlpha + 0.1;                  
                  #endif

                  /////// 0 ~ 1
                  float u = mod(1.0+vUv.y+uTime/10.0,1.0);
                  if ( u < alphaTest ) discard;
                  vec4 finColor = vec4( outgoingLight, diffuseColor.a );
                  gl_FragColor = vec4(finColor.x,finColor.y,finColor.z, u);
                  `
                );
            };
        }
    }

    update() {
        const d = new Date();
        let sec = d.getSeconds();
        let milisec = d.getMilliseconds();
        this.uniform.uTime.value = sec + milisec / 1000.0;
    }
}