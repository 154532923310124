import * as THREE from "three";
import EventEmitter from "./EventEmitter.js";
import Experience from "../Experience.js";

export default class Pointer extends EventEmitter {
    constructor() {
        super();
        this.experience = new Experience();
        this.sizes = this.experience.sizes;
        // Setup
        this.pointer = new THREE.Vector2();

        // Resize event
        window.addEventListener("mousemove", (event) => {
            this.pointer.x = (event.clientX / this.sizes.width) * 2 - 1;
            this.pointer.y = -(event.clientY / this.sizes.height) * 2 + 1;
            // console.log(this.pointer)
            this.trigger("mousemove");
        });

        window.addEventListener("click", (event) => {
            this.pointer.x = (event.clientX / this.sizes.width) * 2 - 1;
            this.pointer.y = -(event.clientY / this.sizes.height) * 2 + 1;
            // console.log(this.pointer)
            if(event.target === this.experience.canvas){
                this.trigger("click");
            }
        });

        window.addEventListener("touchend", (event) => {
            this.pointer.x =
                (event.changedTouches[0].clientX / this.sizes.width) * 2 - 1;
            this.pointer.y = -(event.changedTouches[0].clientY / this.sizes.height) * 2 + 1;

            // console.log(event);
            // console.log(event.changedTouches[0].clientX, this.sizes.width);
            // console.log(this.pointer);
            this.trigger("click");
        });
    }
}