import * as THREE from "three";
import Experience from "../../Experience.js";
import { Timeline } from "gsap/gsap-core";
import StateMachine from "../../StateMachine.js";

export default class SUSTAINABLE_CITY_0 {
    constructor() {
        this.CLASS_STATE = StateMachine.States.SUSTAINABLE_CITY_0;
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;
        this.pointer = this.experience.pointer;

        this.cityGroup = this.experience.world.city.group;
        this.natureGroup = this.experience.world.nature.group;
        this.undergroundGroup = this.experience.world.underground.group;

        this.renderer = this.experience.renderer;
        this.resources = this.experience.resources;
        this.stateMachine = this.experience.stateMachine;
        this.time = this.experience.time;
        this.debug = this.experience.debug;

        this.group = new THREE.Group();
        this.group.scale.set(
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale
        );

        this.setMaterial();
        this.setModel();
        this.hide();
        this.setStateMachine();
        this.pointer.on("mousemove", () => {});

        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder("SUSTAINABLE_CITY_0");
            this.debugFolder.close();

            for (let index = 0; index < this.materials.length; index++) {
                const material = this.materials[index];
                this.debugFolder
                    .addColor(this.materials[index], "color")
                    .onChange((value) => {
                        this.materials[index].color = value;
                    });

            }
            this.debugFolder
            .add(this.uniforms[0].uSize, "value")
            .min(0)
            .max(1)
            .step(.01)
            .onChange((value) => {
                for (let i = 0; i < this.materials.length; i++) {
                    this.uniforms[i].uSize.value = value;
                }
            });

           

        }
    }

    setStateMachine() {
        this.stateMachine.on("stateChange", (value) => {
            let currentState = this.stateMachine.State;
            switch (currentState) {
                case this.CLASS_STATE:
                    this.show();
                    break;
                default:
                    this.hide();
                    break;
            }
        });
    }

    show() {
        let tl = new Timeline();
        // this.group.visible = true;
        for (let index = 0; index < this.uniforms.length; index++) {
            //this.uniforms[index].uSize.target = 1;
            tl.to(
                this.uniforms[index].uSize, {
                    value: 1,
                    duration:3,
                    delay: 1.1+0.3 * index,
                    ease: "power1.inOut"
                },
                0
            );
        }
    }

    hide() {
        let tl = new Timeline();
        // this.group.visible = false;
        for (let index = 0; index < this.uniforms.length; index++) {
            //this.uniforms[index].uSize.target = 0;
            tl.to(
                this.uniforms[index].uSize, {
                    value: 0,
                    duration:2,
                    delay: 0.3 * index,
                    ease: "power1.inOut"
                },
                0
            );
        }

      
    }

    setModel() {
        this.model = this.resources.items.SUSTAINABLE_CITY_0;
        this.group.add(this.model.scene);
        this.scene.add(this.group);

        let i = 0;
        this.model.scene.traverse((child) => {
            if (child.isMesh) {
                child.material = this.materials[i++];
                let vector = child.geometry.boundingBox.max.clone();
                vector = vector.sub(child.geometry.boundingBox.min);
                this.uniforms[i-1].uLength.value = vector.length()
            }
            //blue,Green,Power_Module,Yellow
        });
    }

    setMaterial() {
        this.materials = [];
        this.materials.push(
            new THREE.MeshMatcapMaterial({
                color: new THREE.Color(0xfe3620),
                matcap: this.resources.items.matcapWhite
            })
        );
        this.materials.push(
            new THREE.MeshMatcapMaterial({
                color: new THREE.Color(0xcbee1b),
                matcap: this.resources.items.matcapWhite
            })
        );
        this.materials.push(
            new THREE.MeshMatcapMaterial({
                color: new THREE.Color(0x24e5ff),
                matcap: this.resources.items.matcapWhite
            })
        );
        this.materials.push(
            new THREE.MeshMatcapMaterial({
                color: new THREE.Color(0xff881a),
                matcap: this.resources.items.matcapWhite
            })
        );

        this.uniforms = [];
        for (let i = 0; i < this.materials.length; i++) {
            this.uniforms.push({
                uSize: { value: 0.0, target: 1 },
                uLength: { value: 0.0},
            });

            let material = this.materials[i];
            material.onBeforeCompile = (shader) => {
                shader.uniforms.uSize = this.uniforms[i].uSize;
                shader.uniforms.uLength = this.uniforms[i].uLength;

                shader.vertexShader = shader.vertexShader.replace(
                    "void main()",
                    `
                  varying vec3 vPosition;
                  uniform float uSize;
                  uniform float uLength;
                  void main()
                  `
                );
                shader.fragmentShader = shader.fragmentShader.replace(
                    "void main()",
                    `
                  varying vec3 vPosition;
                  uniform float uSize;
                  uniform float uLength;
                  void main()
                  `
                );
                shader.vertexShader = shader.vertexShader.replace(
                    "#include <beginnormal_vertex>",
                    `
                      #include <beginnormal_vertex>
                      vPosition = position;
                  `
                );

                shader.fragmentShader = shader.fragmentShader.replace(
                    "#include <output_fragment>",
                    `
                  #ifdef OPAQUE
                  diffuseColor.a = 1.0;
                  #endif
  
                  // https://github.com/mrdoob/three.js/pull/22425
                  #ifdef USE_TRANSMISSION
                  diffuseColor.a *= transmissionAlpha + 0.1;                  
                  #endif
                  float dst = length(vPosition);
                  dst = dst/(uLength * 1.0);
                  dst = max(0.0,min(1.0,dst));

                   if(dst > uSize){
                       discard;
                   }

                  //vec4 finColor = vec4( vec3(dst,0,0)  , diffuseColor.a );
                  vec4 finColor = vec4( outgoingLight  , diffuseColor.a );
                  gl_FragColor = vec4(finColor.x,finColor.y,finColor.z,finColor.w);
                  `
                );
            };
        }
    }

    update() {
        
    }
}