import * as THREE from "three";
import Experience from "../Experience.js";
import gsap from "gsap";
import { Timeline } from "gsap/gsap-core";
import StateMachine from "../StateMachine.js";

// import { CSM } from "three/examples/jsm/csm/CSM.js";
// import { CSMHelper } from "three/examples/jsm/csm/CSMHelper.js";
export default class Environment {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.debug = this.experience.debug;
        this.time = this.experience.time;
        this.stateMachine = this.experience.stateMachine;
        this.param = { density: 0 };
        this.camera = this.experience.camera.instance;
        // Debug
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder("environment");
            this.debugFolder.close();
        }
        this.setHemiLight();
        this.setSunLight();
        this.setEnvironmentMap();
        //this.setCSM();
        this.setStateMachine();
        this.resources.on("ready", () => {});
        if (this.experience.debug.active) {
            this.initalTween();
        }

    }
    setStateMachine() {
        this.stateMachine.on("stateChange", (value) => {
            let currentState = this.stateMachine.State;
            var tl = new Timeline();
            switch (currentState) {
                case StateMachine.States.SEPERATE:

                    tl.to(
                        this.sunLight.position, {
                            duration: 2,
                            x: 292.534,
                            y: 178.219,
                            z: 63.904,
                            //ease: "power1.inOut",
                        },
                        0
                    );

                    break;
                case StateMachine.States.EMBRACING_FUTURE:

                    tl.to(
                        this.sunLight.position, {
                            duration: 2,
                            x: 406.849,
                            y: 330.639,
                            z: -431.461,
                            //ease: "power1.inOut",
                        },
                        0
                    );

                    break;
                case StateMachine.States.HUB20_0:

                    tl.to(
                        this.sunLight.position, {
                            duration: 2,
                            x: 256.883,
                            y: 179.499,
                            z: -126.621,
                            //ease: "power1.inOut",
                        },
                        0
                    );

                    break;
                case StateMachine.States.WALKABLE_CITY_0:

                    break;
                default:
                    tl.to(this.scene.fog, {
                        duration: 1.5,
                        density: 0.000066,
                        //ease: "power2.inOut",
                    });
                    tl.to(
                        this.sunLight.position, {
                            duration: 2,
                            x: -164.726,
                            y: 444.954,
                            z: 1016.528,
                            //ease: "power1.inOut",
                        },
                        0
                    );
                    break;
            }
        });
    }

    setHemiLight() {
        this.hemiLight = new THREE.HemisphereLight(0xffffff, 0x3284ff, 0.6);
        // this.hemiLight.color.setHSL(0.6, 1, 0.6);
        // this.hemiLight.groundColor.setHSL(0.095, 1, 0.75);
        this.hemiLight.position.set(0, 50, 0);
        this.scene.add(this.hemiLight);
    }

    setSunLight() {
        const ShadowCameraSize = 300;
        this.sunLight = new THREE.DirectionalLight("#ffffff", 2);

        this.sunLight.castShadow = true;
        this.sunLight.shadow.camera.near = 100;
        this.sunLight.shadow.camera.far = 3000;
        this.sunLight.shadow.normalBias = 0.05;
        //this.sunLight.position.set(80, 370, -450)
        this.sunLight.position.set(22.71, 117.352, -301.776);
        this.sunLight.shadow.mapSize.width = 512 * 4;
        this.sunLight.shadow.mapSize.height = 512 * 4;
        this.sunLight.shadow.blurSamples = 25;
        this.sunLight.shadow.bias = 0.0000167;

        this.sunLight.shadow.camera.right = ShadowCameraSize;
        this.sunLight.shadow.camera.left = -ShadowCameraSize;
        this.sunLight.shadow.camera.top = ShadowCameraSize;
        this.sunLight.shadow.camera.bottom = -ShadowCameraSize;
        this.scene.add(this.sunLight);
        // console.log(this.sunLight.shadow);

        // Debug
        if (this.debug.active) {
            // this.lightHelper = new THREE.DirectionalLightHelper(this.sunLight, 5);
            // this.scene.add(this.lightHelper);
            // this.directionalLightCameraHelper = new THREE.CameraHelper(
            //     this.sunLight.shadow.camera
            // );
            // this.directionalLightCameraHelper.visible = true;
            // this.scene.add(this.directionalLightCameraHelper);

            this.debugFolder
                .add(this.sunLight, "intensity")
                .name("sunLightIntensity")
                .min(0)
                .max(10)
                .step(0.001);
            this.debugFolder.addColor(this.sunLight, "color").name("sunLight Color");

            this.debugFolder
                .add(this.sunLight.position, "x")
                .name("sunLightX")
                .min(-1550)
                .max(1550)
                .step(0.001);

            this.debugFolder
                .add(this.sunLight.position, "y")
                .name("sunLightY")
                .min(-1550)
                .max(1550)
                .step(0.001);

            this.debugFolder
                .add(this.sunLight.position, "z")
                .name("sunLightZ")
                .min(-1550)
                .max(1550)
                .step(0.001);
            this.debugFolder
                .add(this.sunLight.shadow, "bias")
                .name("bias")
                .min(-0.001)
                .max(0.001)
                .step(0.0000001);

            this.debugFolder
                .add(this.sunLight.shadow.camera, "right")
                .name("ShadowCameraSize")
                .min(0)
                .max(1000)
                .step(0.1)
                .onChange((value) => {
                    this.sunLight.shadow.camera.right = value;
                    this.sunLight.shadow.camera.left = -value;
                    this.sunLight.shadow.camera.top = value;
                    this.sunLight.shadow.camera.bottom = -value;

                    this.sunLight.shadow.camera.needsUpdate = true;
                    this.sunLight.needsUpdate = true;
                    this.directionalLightCameraHelper.needsUpdate = true;
                    //   console.log(this.sunLight.shadow.camera.right);
                });
            //   console.log(this.hemiLight);
            this.debugFolder
                .add(this.hemiLight, "intensity")
                .name("Hemi intensity")
                .min(0)
                .max(5);
            this.debugFolder.addColor(this.hemiLight, "color").name("Hemi Color");
            this.debugFolder
                .addColor(this.hemiLight, "groundColor")
                .name("Hemi GroundColor");

            this.debugFolder
                .add(this.param, "density")
                .name("density")
                .min(0)
                .max(0.00066)
                .step(0.00001)
                .onChange((value) => {
                    this.scene.fog.density = value;
                });
            // console.log(this.scene.fog)
        }
    }
    setCSM() {
        // this.csm = new CSM({
        //   maxFar: 3000,
        //   cascades: 4,
        //   mode: "practical",
        //   parent: this.scene,
        //   shadowMapSize: 1024,
        //   lightDirection: this.sunLight.position.normalize(),
        //   camera: this.camera,
        // });
        // this.csmHelper = new CSMHelper(this.csm);
        // this.csmHelper.visible = false;
    }

    setCSMMaterial(material, shader) {
        // if (!this.csm) {
        //   this.setCSM();
        // }
        // material.defines = material.defines || {};
        // material.defines.USE_CSM = 1;
        // material.defines.CSM_CASCADES = this.csm.cascades;
        // if (this.csm.fade) {
        //   material.defines.CSM_FADE = "";
        // }
        // const breaksVec2 = [];
        // const far = Math.min(this.camera.far, 1000000);
        // this.csm.getExtendedBreaks(breaksVec2);
        // shader.uniforms.CSM_cascades = { value: breaksVec2 };
        // shader.uniforms.cameraNear = { value: this.camera.near };
        // shader.uniforms.shadowFar = { value: far };
        // this.csm.shaders.set(material, shader);
    }

    initalTween() {
        console.log("initalTween()");
        var tl = new Timeline();
        tl.from(this.scene.fog, {
            duration: 6,
            density: 0.0006,
            //ease: "power1.inOut",
            delay: 0,
        });
        let from = new THREE.Vector3(
            this.sunLight.position.x - 50,
            this.sunLight.position.y - 30,
            this.sunLight.position.z - 50
        );
        tl.from(
            this.sunLight.position, {
                duration: 7,
                x: from.x,
                y: from.y,
                z: from.z,
                delay: 0,
                //ease: "power1.inOut",
            },
            0
        );
    }

    setEnvironmentMap() {
        this.environmentMap = {};
        this.environmentMap.intensity = 0.64;
        this.environmentMap.texture = this.resources.items.environmentMapTexture;
        this.environmentMap.texture.encoding = THREE.sRGBEncoding;
        this.environmentMap.BGgradient = {
            angle: 130,
            color0: 0x060709,
            color1: 0x2f394c,
        };

        this.scene.environment = this.environmentMap.texture;
        this.scene.background = 0x0b1b2a; //0xffffff00; //this.environmentMap.texture
        this.scene.fog = new THREE.FogExp2(0x000000, 0.000066);

        this.environmentMap.updateMaterials = () => {
            this.scene.traverse((child) => {
                if (
                    child instanceof THREE.Mesh &&
                    child.material instanceof THREE.MeshStandardMaterial
                ) {
                    child.material.envMap = this.environmentMap.texture;
                    child.material.envMapIntensity = this.environmentMap.intensity;
                    child.material.needsUpdate = true;
                }
            });
        };
        this.environmentMap.updateMaterials();

        // Debug
        if (this.debug.active) {
            this.debugFolder
                .add(this.environmentMap, "intensity")
                .name("envMapIntensity")
                .min(0)
                .max(4)
                .step(0.001)
                .onChange(this.environmentMap.updateMaterials);
            this.debugFolder
                .add(this.environmentMap.BGgradient, "angle")
                .min(0)
                .max(360)
                .name("BG angle")
                .onChange((value) => {
                    this.environmentMap.BGgradient.angle = value;
                    this.gradientUpdate();
                });
            this.debugFolder
                .addColor(this.environmentMap.BGgradient, "color0")
                .name("BG color0")
                .onChange((value) => {
                    this.environmentMap.BGgradient.color0 = value.toString(16);
                    this.gradientUpdate();
                });

            this.debugFolder
                .addColor(this.environmentMap.BGgradient, "color1")
                .name("BG color1")
                .onChange((value) => {
                    this.environmentMap.BGgradient.color1 = value.toString(16);
                    this.gradientUpdate();
                });

            // this.debugFolder
            //   .addColor(this.environmentMap.BGgradient, "color2")
            //   .name("color2")
            //   .onChange(() => {
            //     let hex = this.environmentMap.BGgradient.color2.toString(16);
            //     console.log(hex);
            //     console.log(document.getElementsByClassName("webgl").style);
            //     document.getElementsByClassName("webgl").style.background =
            //       "linear-gradient( 0geg" + "," + hex + " 0%," + hex + " 100%)";
            //   });
            // linear-gradient( 0deg, rgba(2, 6, 9, 1) 0%, rgba(11, 27, 42, 1) 52%, rgba(2, 6, 9, 1) 100%);
        }
    }
    gradientUpdate() {
        let gradient =
            "linear-gradient(" +
            this.environmentMap.BGgradient.angle +
            "deg, #" +
            this.environmentMap.BGgradient.color0.toString(16) +
            " 0%, #" +
            this.environmentMap.BGgradient.color1.toString(16) +
            " 100%)";
        document.getElementsByClassName("webgl")[0].style.background = gradient;
    }

    update() {
        if (this.csm) this.csm.update();
    }
}