import * as THREE from "three";
import Experience from "../Experience.js";
import gsap from "gsap";
import { Timeline } from "gsap/gsap-core";
import StateMachine from "../StateMachine.js";
export default class AroundCity {
    constructor() {
        this.experience = new Experience();

        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this.stateMachine = this.experience.stateMachine;
        this.time = this.experience.time;
        this.debug = this.experience.debug;
        this.group = new THREE.Group();
        this.Cameras = [];
        this.CityMeshs = [];
        this.pointer = this.experience.pointer;
        this.isClicked = false;
        this.pointer.on("click", () => {
            this.isClicked = true;
        });
        this.CityMeshNames = [
            "Building_E_2",
            "Building_NE_2",
            "Building_NW_2",
            "Building_SE_2",
            "Building_SW_2",
            "Building_W_2",
            "City_W",
        ];
        let offsetScale = 0.001;
        this.offsetArray = [
            new THREE.Vector3(-269139.695 * offsetScale, 0, 464945.122 * offsetScale),
            new THREE.Vector3(268609.189 * offsetScale, 0, 465239.808 * offsetScale),
            new THREE.Vector3(537218.695 * offsetScale, 0, -1310 * offsetScale),
            new THREE.Vector3(-536665.165 * offsetScale, 0, -969 * offsetScale),
            new THREE.Vector3(-268073.089 * offsetScale,
                0, -466195.985 * offsetScale
            ),
            new THREE.Vector3(268048.548 * offsetScale, 0, -466199.52 * offsetScale),
        ];
        // Resource
        //this.buildingUniforms = { uWeight: { value: 1.723 } };
        this.buildingUniforms = {
            uSize: { value: 1.0 },
            uTargetPostionX: { value: 1.0, vs: 0.0, as: 0.0, f: 0.0 },
            uTargetPostionY: { value: 1.0, vs: 0.0, as: 0.0, f: 0.0 },
            uTargetPostionZ: { value: 1.0, vs: 0.0, as: 0.0, f: 0.0 },
        };

        this.SPRING = {
            M: 0.8,
            K: 0.2,
            D: 0.82,
            R: 150,
        };

        this.resource = this.resources.items.cityBuildingModel;
        this.aroundCityBuilding = [];
        this.materials = [];
        this.uniformArray = [];
        this.setModel();
        this.setStateMachineEvent();
    }

    setStateMachineEvent() {
        this.stateMachine.on("stateChange", (value) => {
            let currentState = this.stateMachine.State;

            switch (currentState) {
                case StateMachine.States.INITAL_STATE:
                    this.show();
                    break;
                default:
                    this.hide();
                    break;
            }
        });
    }
    show() {
        this.group.visible = true;
        var tl = new Timeline();
        for (let j = 0; j < this.uniformArray.length; j++) {
            const uniform = this.uniformArray[j];
            // let newSize = Math.random() * 50 + 350;
            tl.to(
                uniform.uSize, {
                    duration: 0.5,
                    value: Math.random() * 50 + 350,
                    ease: "power2.inOut",
                },
                0
            );
        }
        tl.to(
            this.group.scale, {
                x: 1,
                y: 1,
                z: 1,
                duration: 2,
                ease: "power2.inOut",
                onUpdate: () => {
                    // console.log(this.group.scale);
                },
            },
            0
        );
    }
    hide() {
        var tl = new Timeline();
        for (let j = 0; j < this.uniformArray.length; j++) {
            const uniform = this.uniformArray[j];
            tl.to(
                uniform.uSize, {
                    duration: 2,
                    value: 0,
                    ease: "power2.inOut",
                    onComplete: () => {},
                },
                0
            );

            //uSize: { value: Math.random() * 50 + 350 },
        }
        tl.to(
            this.group.scale, {
                x: 10,
                y: 10,
                z: 10,
                duration: 3,
                delay: 0.2,
                ease: "power2.inOut",
                onUpdate: () => {
                    this.group.visible = false;
                    // console.log(this.group.scale);
                },
            },
            0
        );
    }

    setModel() {
        this.model = this.resource.scene;
        this.model.scale.set(
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale
        );
        this.scene.add(this.group);

        this.group.rotation.setFromQuaternion(
            new THREE.Quaternion(0.7071067336835151, 0, 0, 0.7071067336835151)
        );
        this.group.updateMatrix();
        this.group.updateMatrixWorld();

        for (let index = 0; index < this.offsetArray.length; index++) {
            const element = this.offsetArray[index];
            let aroundCityOffset = this.group.worldToLocal(element);
            this.model.traverse((child) => {
                if (child.name.includes("_BLD_")) {
                    if (child.geometry) {
                        let building = new THREE.Mesh(
                            child.geometry,
                            this.createMaterial()
                        );

                        let worldScl = child.getWorldScale(new THREE.Vector3());
                        building.position.set(
                            aroundCityOffset.x + child.position.x / 1000,
                            aroundCityOffset.y + child.position.y / 1000,
                            aroundCityOffset.z + child.position.z / 1000
                        );
                        building.scale.set(worldScl.x, worldScl.y, worldScl.z);

                        this.aroundCityBuilding.push(building);
                        this.group.add(building);
                    }
                }
            });
        }
    }
    createMaterial() {
        let material = new THREE.MeshStandardMaterial({
            color: new THREE.Color(1, 1, 1),
        });
        let buildingUniforms = {
            uSize: { value: Math.random() * 50 + 350 },
            uTargetPostionX: { value: 1.0, vs: 0.0, as: 0.0, f: 0.0 },
            uTargetPostionY: { value: 3000.0, vs: 0.0, as: 0.0, f: 0.0 },
            uTargetPostionZ: { value: 1.0, vs: 0.0, as: 0.0, f: 0.0 },
            spd: { value: Math.random() * 0.07 + 0.02 },
            // uTargetPostion: new THREE.Uniform(new THREE.Vector3(1, 1, 1)),
        };
        buildingUniforms.uTargetPostionX.ps =
            this.experience.camera.controls.target.x;
        buildingUniforms.uTargetPostionY.ps =
            this.experience.camera.controls.target.y;
        buildingUniforms.uTargetPostionZ.ps =
            this.experience.camera.controls.target.z;
        this.uniformArray.push(buildingUniforms);
        material.onBeforeCompile = (shader) => {
            shader.uniforms.uSize = buildingUniforms.uSize;
            shader.uniforms.uTargetPostionX = buildingUniforms.uTargetPostionX;
            shader.uniforms.uTargetPostionY = buildingUniforms.uTargetPostionY;
            shader.uniforms.uTargetPostionZ = buildingUniforms.uTargetPostionZ;
            //shader.uniforms.uTargetPostion = buildingUniforms.uTargetPostion;

            // console.log(shader.vertexShader);
            // console.log(shader.fragmentShader);

            shader.vertexShader = shader.vertexShader.replace(
                "void main()",
                `
              varying vec3 vPosition;
              // uniform vec3 uTargetPostion;
              uniform float uTargetPostionX;
              uniform float uTargetPostionY;
              uniform float uTargetPostionZ;
              uniform float uSize;
              void main()
              `
            );
            shader.fragmentShader = shader.fragmentShader.replace(
                "void main()",
                `
            varying vec3 vPosition;
            // uniform vec3 uTargetPostion;
            uniform float uTargetPostionX;
            uniform float uTargetPostionY;
            uniform float uTargetPostionZ;
            uniform float uSize;
            void main()
            `
            );
            shader.vertexShader = shader.vertexShader.replace(
                "#include <displacementmap_vertex>",
                `
              #include <displacementmap_vertex>
              vec4 _worldPosition = vec4( transformed, 1.0 );
              #ifdef USE_INSTANCING
                _worldPosition = instanceMatrix * _worldPosition;
              #endif
              _worldPosition = modelMatrix * _worldPosition;
              vec3 diff = vec3(_worldPosition.x-uTargetPostionX,_worldPosition.y-uTargetPostionY,_worldPosition.z-uTargetPostionZ);
              float _dst = length(diff);
              _dst = 1.0 - max(0.0,min(1.0,(_dst/(uSize * 0.7))));
              //float p = max(0.0,min(1.0,transformed.z/4.0)) * 5.0;
              _dst = _dst < .05 ? 0.0 :_dst;
              _dst = pow(_dst, 0.5);
              _dst = min(_dst,length(_worldPosition));
              transformed = vec3(transformed.x,transformed.y, transformed.z * _dst);
          `
            );
            shader.vertexShader = shader.vertexShader.replace(
                "#include <worldpos_vertex>",
                `
                      #include <worldpos_vertex>
                      vPosition = vec3(worldPosition.xyz);//position;
                  `
            );

            shader.fragmentShader = shader.fragmentShader.replace(
                "#include <map_fragment>",
                `
                  #ifdef USE_MAP

                  vec4 texelColor = texture2D( map, vUv );
                
                  diffuseColor *= texelColor;
                
                #endif
                `
            );
            shader.fragmentShader = shader.fragmentShader.replace(
                "#include <output_fragment>",
                `
                  #ifdef OPAQUE
                  diffuseColor.a = 1.0;
                  #endif
  
                  // https://github.com/mrdoob/three.js/pull/22425
                  #ifdef USE_TRANSMISSION
                  diffuseColor.a *= transmissionAlpha + 0.1;
                  #endif

                  vec4 finColor = vec4( outgoingLight, diffuseColor.a );
                  vec3 diff = vec3(vPosition.x-uTargetPostionX,vPosition.y-uTargetPostionY,vPosition.z-uTargetPostionZ);
                  float height = min(1.0,max(0.06,abs(vPosition.y-0.0)/100.0));
                  height = pow(height,1.0) * 10.0;
                  height = max(.01,height);
                  float _dst = length(diff);
                  _dst = 1.0- max(0.0,min(1.0,(_dst/(uSize * 4.2))));
                  _dst = pow(_dst,10.0);
                  _dst = min(_dst,length(vPosition));
                  
                  if(_dst < .01){
                    return;
                  }
                  // _dst = max(.6,_dst);
                  gl_FragColor = vec4(finColor.x * (_dst*height) ,finColor.y * (_dst*height),finColor.z *( _dst*height),_dst);
                  //gl_FragColor = vec4(height,height,height,1.0);
                  `
            );
        };

        this.materials.push(material);
        return material;
    }

    update() {
        if (!this.group.visible) {
            return;
        }
        let controlPos = this.experience.camera.controls.target;
        for (let j = 0; j < this.uniformArray.length; j++) {
            const uniform = this.uniformArray[j];

            // uniform.uTargetPostionX.f = this.SPRING.K * (uniform.uTargetPostionX.ps - this.SPRING.R);
            // uniform.uTargetPostionX.as = uniform.uTargetPostionX.f / this.SPRING.M;
            // uniform.uTargetPostionX.vs = this.SPRING.D * (uniform.uTargetPostionX.vs + uniform.uTargetPostionX.as);
            // uniform.uTargetPostionX.ps = uniform.uTargetPostionX.ps + uniform.uTargetPostionX.vs;
            // if (Math.abs(uniform.uTargetPostionX.vs) < 0.1) {
            //     uniform.uTargetPostionX.vs = 0.0;
            // }

            // uniform.uTargetPostionY.f = this.SPRING.K * (uniform.uTargetPostionY.ps - this.SPRING.R);
            // uniform.uTargetPostionY.as = uniform.uTargetPostionY.f / this.SPRING.M;
            // uniform.uTargetPostionY.vs = this.SPRING.D * (uniform.uTargetPostionY.vs + uniform.uTargetPostionY.as);
            // uniform.uTargetPostionY.ps = uniform.uTargetPostionY.ps + uniform.uTargetPostionY.vs;
            // if (Math.abs(uniform.uTargetPostionY.vs) < 0.1) {
            //     uniform.uTargetPostionY.vs = 0.0;
            // }

            // uniform.uTargetPostionZ.f = this.SPRING.K * (uniform.uTargetPostionZ.ps - this.SPRING.R);
            // uniform.uTargetPostionZ.as = uniform.uTargetPostionZ.f / this.SPRING.M;
            // uniform.uTargetPostionZ.vs = this.SPRING.D * (uniform.uTargetPostionZ.vs + uniform.uTargetPostionZ.as);
            // uniform.uTargetPostionZ.ps = uniform.uTargetPostionZ.ps + uniform.uTargetPostionZ.vs;
            // if (Math.abs(uniform.uTargetPostionZ.vs) < 0.1) {
            //     uniform.uTargetPostionZ.vs = 0.0;
            // }

            // uniform.uTargetPostionX.value = uniform.uTargetPostionX.ps;
            // uniform.uTargetPostionY.value = uniform.uTargetPostionY.ps;
            // uniform.uTargetPostionZ.value = uniform.uTargetPostionZ.ps;

            // if (this.isClicked) {
            //     console.log(controlPos)
            //     uniform.uTargetPostionX.ps = controlPos.x;
            //     uniform.uTargetPostionY.ps = controlPos.y;
            //     uniform.uTargetPostionZ.ps = controlPos.z;
            // }
            // this.isClicked = false;

            let _spd = uniform.spd.value;
            uniform.uTargetPostionX.value = THREE.Math.lerp(
                uniform.uTargetPostionX.value,
                this.experience.camera.controls.target.x,
                _spd
            );
            uniform.uTargetPostionY.value = THREE.Math.lerp(
                uniform.uTargetPostionY.value,
                this.experience.camera.controls.target.y,
                _spd
            );
            uniform.uTargetPostionZ.value = THREE.Math.lerp(
                uniform.uTargetPostionZ.value,
                this.experience.camera.controls.target.z,
                _spd
            );
        }
    }
}