import * as THREE from "three";
import Experience from "../../Experience.js";
import { Timeline } from "gsap/gsap-core";
import StateMachine from "../../StateMachine.js";

export default class WALKABLE_CITY_1 {
    constructor() {
        this.CLASS_STATE = StateMachine.States.WALKABLE_CITY_1;
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;
        this.pointer = this.experience.pointer;

        this.cityGroup = this.experience.world.city.group;
        this.natureGroup = this.experience.world.nature.group;
        this.undergroundGroup = this.experience.world.underground.group;

        this.renderer = this.experience.renderer;
        this.resources = this.experience.resources;
        this.stateMachine = this.experience.stateMachine;
        this.time = this.experience.time;
        this.debug = this.experience.debug;

        this.group = new THREE.Group();
        this.group.scale.set(
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale
        );

        this.setMaterial();
        this.setModel();
        this.setAnimations();
        this.hide();
        this.setStateMachine();
        this.pointer.on("mousemove", () => {});
    }

    setStateMachine() {
        this.stateMachine.on("stateChange", (value) => {
            let currentState = this.stateMachine.State;
            switch (currentState) {
                case this.CLASS_STATE:
                    this.show();
                    break;
                default:
                    this.hide();
                    break;
            }
        });
    }

    show() {
        this.group.visible = true;
    }

    hide() {
        this.group.visible = false;
    }

    setModel() {
        this.model = this.resources.items.WALKABLE_CITY_1;
        this.group.add(this.model.scene);
        this.scene.add(this.group);

        let i = 0;
        this.model.scene.traverse((child) => {
            if (child.isMesh) {
                // child.castShadow = true;
                // child.receiveShadow = true;
            }
        });
    }

    setMaterial() {
        this.materials = [];
        this.materials.push(
            new THREE.MeshStandardMaterial({
                color: new THREE.Color(1, 1, 1),
                opacity: 0,
                transparent: true,
            })
        );
    }
    setAnimations() {
        for (let index = 0; index < this.model.animations.length; index++) {
            this.mixer = new THREE.AnimationMixer(this.model.scene);
            this.mixer.clipAction(this.model.animations[0]).play();
        }
    }

    update() {
        if (this.group.visible) {
            this.mixer.update(this.time.delta * 0.0001);
        }
    }
}