import './style.css'
import './fonts.css'
import './reset.css'
import './popupStyle.css'
import './headerStyle.css'
import './aniStyle.css'
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import Experience from './Experience/Experience.js'
import LocalizationManager from "./Experience/LocalizationManager";
import {fadeIn} from "./Experience/Utils/TransitionHelper";
import Header from "./Experience/Header";
import Intro from "./Experience/Intro";
import {redefinitionHeight, redefinitionWidth} from './Experience/Utils/redefinitionSize'; // ios chrome innerHeight 오류 보정
const expContainer = document.querySelector('canvas.webgl');
const experience = new Experience(expContainer);
new LocalizationManager();

if (experience.debug.active) {
    const header = new Header();
    header.show();

    const introIframe = document.querySelector('.intro-iframe');
    introIframe.style.display = 'none';

    fadeIn(expContainer);
} else {
    window.onload = () => {
        new Intro();
    }
}

// 모바일 height 100% 대응
let vh;
let visualCloseTop;
const setPropertyValue = () => {

    
    vh = redefinitionHeight() * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    visualCloseTop = (redefinitionWidth() * 0.25) + ((redefinitionHeight() - redefinitionWidth() * 0.25) / 2);
    document.documentElement.style.setProperty('--visualCloseTop', `${visualCloseTop}px`);
}

window.addEventListener('load', setPropertyValue);
window.addEventListener('resize', setPropertyValue);

// Zoom In / Out 방지
document.addEventListener('touchstart', (e) => {
    if(e.touches.length > 1) {
        e.preventDefault();
    }
    }, { passive: false })