export default class TweenControl {
    constructor() {

    }
}

TweenControl.tweens = [];
TweenControl.timelines = [];
TweenControl.AddTween = (tween) => {
    console.log(tween);
    TweenControl.tweens.push(tween);
}
TweenControl.AddTimeline = (tl) => {
    console.log(tl);
    TweenControl.timelines.push(tl);
}

TweenControl.IsTweenComplete = () => {

}