import EventEmitter from "./EventEmitter.js"; //../Experience/Utils/redefinitionSize
import { redefinitionHeight } from './redefinitionSize'; // ios chrome innerHeight 오류 보정

export default class Sizes extends EventEmitter {
    constructor() {
        super();

        // Setup
        this.maxPixelRatio = 2;
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.pixelRatio = Math.min(window.devicePixelRatio, this.maxPixelRatio);

        this.modelScale = 0.1;

        // Resize event
        window.addEventListener("resize", this.onResize);
    }
    onResize() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        const user = navigator.userAgent;
        if (user.indexOf("iPhone") > -1 || user.indexOf("iPad") > -1) {
            this.width = document.documentElement.clientWidth;
            this.height = document.documentElement.clientHeight;
        }
        this.pixelRatio = Math.min(window.devicePixelRatio, this.maxPixelRatio);
        this.trigger("resize");
    }
    update() {
        const user = navigator.userAgent;
        if (user.indexOf("iPhone") > -1 || user.indexOf("iPad") > -1) {
            if (this.width != document.documentElement.clientWidth || this.height != document.documentElement.clientHeight) {
                this.onResize();
            }
        }
    }
}