import EventEmitter from "./Utils/EventEmitter.js";
import Experience from "./Experience.js";

let instance = null;
export default class StateMachine extends EventEmitter {
  constructor() {
    super();
    // Singleton
    if (instance) {
      return instance;
    }

    instance = this;

    this.experience = new Experience();

    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.debug = this.experience.debug;

    this.State = StateMachine.States.INITAL_STATE; //"initalState";

    this.param = {
      currentState: this.State,
    };
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("State");
      let properties = Object.getOwnPropertyNames(StateMachine.States);
      this.debugFolder
        .add(this.param, "currentState", properties)
        .onChange((value) => {
          let StateName = StateMachine.States[value];

          this.SetState(StateName);
        });
    }
  }
  /*
  -Usage Example
    SetState(StateMachine.States.INITAL_STATE)
  */

  SetState(_state) {
    if (this.State != _state) {
      this.State = _state;

      let properties = Object.getOwnPropertyNames(StateMachine.States);
      let isMached = false;
      for (let index = 0; index < properties.length; index++) {
        const property = properties[index];
        const value = StateMachine.States[property];
        if (value === this.State) {
          isMached = true;
          console.log(property, this.State);
        }
      }
      if (!isMached) {
        console.error("NO MATCHED ", this.State);
      }

      this.trigger("stateChange", this.State);
    }
  }
}
StateMachine.States = {
  INITAL_STATE: 0,
  SEPERATE: 1,

  HUMAN_CENTERED: 100,
  COEXIST_WITH_NATURE: 200,
  EMBRACING_FUTURE: 300,

  WALKABLE_CITY_0: 101, // 1-1 *
  WALKABLE_CITY_1: 102, // 1-2 *
  WALKABLE_CITY_2: 103,
  WALKABLE_CITY_3: 104,
  WALKABLE_CITY_4: 105,

  HYPON_DENSITY_0: 111, // 2-1*
  HYPON_DENSITY_1: 112, // 2-2
  HYPON_DENSITY_2: 113,
  HYPON_DENSITY_3: 114,
  HYPON_DENSITY_4: 115,

  CONVENIENCE_CITY_0: 121, // 3-1*
  CONVENIENCE_CITY_1: 122, // 3-2*
  CONVENIENCE_CITY_2: 123,
  CONVENIENCE_CITY_3: 124,
  CONVENIENCE_CITY_4: 125,

  EMBRACES_NATURE_0: 201, // 4-1*
  EMBRACES_NATURE_1: 202, // 4-2
  EMBRACES_NATURE_2: 203,
  EMBRACES_NATURE_3: 204,
  EMBRACES_NATURE_4: 205,

  NATURE_ECOSYSTEM_0: 211, // 5-1*
  NATURE_ECOSYSTEM_1: 212, // 5-2*
  NATURE_ECOSYSTEM_2: 213,
  NATURE_ECOSYSTEM_3: 214,
  NATURE_ECOSYSTEM_4: 215,

  SUSTAINABLE_CITY_0: 221, // 6-1*
  SUSTAINABLE_CITY_1: 222, // 6-2*
  SUSTAINABLE_CITY_2: 223,
  SUSTAINABLE_CITY_3: 224,
  SUSTAINABLE_CITY_4: 225,

  GIRD_SYSTEM_0: 301, // 7-1*
  GIRD_SYSTEM_1: 302, // 7-2*
  GIRD_SYSTEM_2: 303, // 7-3*
  GIRD_SYSTEM_3: 304,
  GIRD_SYSTEM_4: 305,

  CONNECTED_CITY_0: 311, // 8-1*
  CONNECTED_CITY_1: 312,
  CONNECTED_CITY_2: 313,
  CONNECTED_CITY_3: 314,
  CONNECTED_CITY_4: 315,

  HUB20_0: 321, // 9-1*
  HUB20_1: 322,
  HUB20_2: 323,
  HUB20_3: 324,
  HUB20_4: 325,
  HUB20_5: 326,
  HUB20_6: 327,
  HUB20_7: 328,
};
