import * as THREE from "three";
import Experience from "../Experience.js";
import { Timeline } from "gsap/gsap-core";
import StateMachine from "../StateMachine.js";
export default class TitleButtons {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;
        this.pointer = this.experience.pointer;

        this.cityGroup = this.experience.world.city.group;
        this.natureGroup = this.experience.world.nature.group;
        this.undergroundGroup = this.experience.world.underground.group;

        this.renderer = this.experience.renderer;
        this.resources = this.experience.resources;
        this.stateMachine = this.experience.stateMachine;
        this.time = this.experience.time;
        this.debug = this.experience.debug;

        this.groups = [this.natureGroup, this.cityGroup, this.undergroundGroup];
        this.group = new THREE.Group();
        this.group.scale.set(
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale,
            this.experience.sizes.modelScale
        );

        this.buttons = [];
        this.boundBoxs = [];
        this.targetColors = [{ r: 1, g: 1, b: 1 }, { r: 1, g: 1, b: 1 }, { r: 1, g: 1, b: 1 }];
        this.raycaster = new THREE.Raycaster();
        this.setMaterial();
        this.setModel();
        this.setGui();
        this.hide();
        this.setClickEvent();
        this.setStateMachine();
        this.pointer.on("mousemove", () => {});
    }

    setClickEvent() {
        this.pointer.on("click", () => {
            if (this.stateMachine.State == StateMachine.States.SEPERATE) {
                this.raycaster.setFromCamera(
                    this.pointer.pointer,
                    this.camera.instance
                );
                const intersects = this.raycaster.intersectObjects(this.boundBoxs, false);
                if (intersects.length > 0) {
                    for (let index = 0; index < this.boundBoxs.length; index++) {
                        const element = this.boundBoxs[index];
                        if (
                            element == intersects[0].object &&
                            intersects[0].object.visible
                        ) {
                            this.stateMachine.SetState(
                                [
                                    StateMachine.States.COEXIST_WITH_NATURE,
                                    StateMachine.States.HUMAN_CENTERED,
                                    StateMachine.States.EMBRACING_FUTURE,
                                ][index]
                            );
                        }
                    }
                }
            }
        });

        this.pointer.on("mousemove", () => {
            if (this.stateMachine.State == StateMachine.States.SEPERATE) {
                this.raycaster.setFromCamera(
                    this.pointer.pointer,
                    this.camera.instance
                );
                const intersects = this.raycaster.intersectObjects(this.boundBoxs, false);
                if (intersects.length > 0) {
                    for (let index = 0; index < this.boundBoxs.length; index++) {
                        const element = this.boundBoxs[index];
                        if (
                            element == intersects[0].object &&
                            intersects[0].object.visible
                        ) {
                            document.body.style.cursor = 'pointer';
                            this.targetColors[index].r = 1;
                            this.targetColors[index].g = 0.1640625;
                            this.targetColors[index].b = 0;
                        } else {
                            this.targetColors[index].r = 1;
                            this.targetColors[index].g = 1;
                            this.targetColors[index].b = 1;
                        }
                    }
                } else {
                    document.body.style.cursor = 'default';
                    for (let index = 0; index < 3; index++) {
                        this.targetColors[index].r = 1;
                        this.targetColors[index].g = 1;
                        this.targetColors[index].b = 1;
                    }
                }
            }
        });

        //document.body.style.cursor = 'wait';
    }

    setStateMachine() {
        this.stateMachine.on("stateChange", (value) => {
            let currentState = this.stateMachine.State;
            switch (currentState) {
                case StateMachine.States.SEPERATE:
                    this.show();
                    break;
                default:
                    this.hide();
                    break;
            }
        });
    }

    show() {
        var tl = new Timeline();
        for (let index = 0; index < 3; index++) {
            this.buttons[index].visible = true;
            tl.to(
                this.buttons[index].material, {
                    duration: 0.5,
                    delay: 2,
                    opacity: 1,
                    ease: "power2.inOut",
                    onUpdate: () => {
                        let camPosition = new THREE.Vector3(
                            this.camera.instance.position.x,
                            this.camera.instance.position.y,
                            this.camera.instance.position.z
                        );
                        for (let index = 0; index < this.buttons.length; index++) {
                            const element = this.buttons[index];
                            // element.lookAt(camPosition);
                            // element.rotateX((90 * Math.PI) / 180);

                            // //element.rotation

                            // let quaternion = new THREE.Quaternion();
                            // element.getWorldQuaternion(quaternion);
                            // var rotation = new THREE.Euler().setFromQuaternion( quaternion );
                            // console.log(" ");
                            // console.log(element.rotation);
                            // console.log(rotation);

                            element.rotation.set(1, .32, -.5);
                        }
                    },
                },
                index * 0.1
            );
        }
    }

    hide() {
        var tl = new Timeline();
        for (let index = 0; index < 3; index++) {
            tl.to(
                this.buttons[index].material, {
                    duration: 0.5,
                    opacity: 0,
                    ease: "power2.inOut",
                    onComplete: () => {
                        this.buttons[index].visible = false;
                    },
                },
                0
            );
        }
        document.body.style.cursor = 'default';
    }

    setModel() {
        let textModels = [
            this.resources.items.coexistingWithNature,
            this.resources.items.humanCentered,
            this.resources.items.embracingTheFuture,
        ];

        for (let index = 0; index < 3; index++) {
            let model = textModels[index].scene;
            //model.scale.set(0.1, 0.1, 0.1);
            //model.rotation.set(0, (Math.PI / 180) * 30, 0);

            // model.children[0].rotation.set(90 * 0.0174533, 0, 0);
            model.children[0].material = this.materials[index];

            this.buttons.push(model.children[0]);
        }

        textModels[0].scene.children[0].position.set(210 / 2 + 15, 40, 210);
        textModels[1].scene.children[0].position.set(250 / 2 + 15, 90, 250);
        textModels[2].scene.children[0].position.set(240 / 2 + 15, 70, 240);

        let boundingBox0 = textModels[0].scene.children[0].geometry.boundingBox;
        let max0 = boundingBox0.max;
        let min0 = boundingBox0.min;
        let width = max0.x - min0.x,
            height = max0.y - min0.y,
            depth = max0.z - min0.z
        let scale = 1.3;
        let box0 = new THREE.Mesh(new THREE.BoxGeometry(width * scale, height * scale, depth * scale), this.materials[3]);
        textModels[0].scene.children[0].add(box0);

        let boundingBox1 = textModels[1].scene.children[0].geometry.boundingBox;
        let max1 = boundingBox1.max;
        let min1 = boundingBox1.min;
        width = max1.x - min1.x, height = max1.y - min1.y, depth = max1.z - min1.z
        let box1 = new THREE.Mesh(new THREE.BoxGeometry(width * scale, height * scale, depth * scale), this.materials[3]);
        textModels[1].scene.children[0].add(box1);

        let boundingBox2 = textModels[2].scene.children[0].geometry.boundingBox;
        let max2 = boundingBox2.max;
        let min2 = boundingBox2.min;
        width = max2.x - min2.x, height = max2.y - min2.y, depth = max2.z - min2.z
        let box2 = new THREE.Mesh(new THREE.BoxGeometry(width * scale, height * scale, depth * scale), this.materials[3]);
        textModels[2].scene.children[0].add(box2);

        this.boundBoxs.push(box0);
        this.boundBoxs.push(box1);
        this.boundBoxs.push(box2);

        // textModels[0].scene.children[0].set(0.001, 0.001, 0.001);
        // textModels[1].scene.children[0].set(0.001, 0.001, 0.001);
        // textModels[2].scene.children[0].set(0.001, 0.001, 0.001);

        this.natureGroup.add(textModels[0].scene);
        this.cityGroup.add(textModels[1].scene);
        this.undergroundGroup.add(textModels[2].scene);

        this.scene.add(this.group);
    }

    setGui() {}

    setMaterial() {
        this.materials = [];
        this.materials.push(
            new THREE.MeshMatcapMaterial({
                color: new THREE.Color(1, 1, 1),
                matcap: this.resources.items.titleMatcap,
                opacity: 0,
                transparent: true,
            })
        );
        this.materials.push(
            new THREE.MeshMatcapMaterial({
                color: new THREE.Color(1, 1, 1),
                matcap: this.resources.items.titleMatcap,
                opacity: 0,
                transparent: true,
            })
        );
        this.materials.push(
            new THREE.MeshMatcapMaterial({
                color: new THREE.Color(1, 1, 1),
                matcap: this.resources.items.titleMatcap,
                opacity: 0,
                transparent: true,
            })
        );
        this.materials.push(
            new THREE.MeshMatcapMaterial({
                color: new THREE.Color(1, 1, 1),
                matcap: this.resources.items.titleMatcap,
                opacity: 0,
                transparent: true,
            })
        );
    }

    update() {
        for (let index = 0; index < 3; index++) {
            const element = this.materials[index];
            let r = element.color.r + (this.targetColors[index].r - element.color.r) * .1;
            let g = element.color.g + (this.targetColors[index].g - element.color.g) * .1;
            let b = element.color.b + (this.targetColors[index].b - element.color.b) * .1;
            element.color.setRGB(r, g, b);

            const button = this.buttons[index];
            let targetScale = .001 + ((this.targetColors[index].b == 0) ? .00003 : 0);

            let scale = button.scale.x + (targetScale - button.scale.x) * .1;
            button.scale.set(scale, scale, scale);
        }
    }
}