import { fadeIn, fadeOut } from "./Utils/TransitionHelper";
import StateMachine from "./StateMachine";
import Experience from "./Experience";
import LocalizationManager from "./LocalizationManager";
import { TUTORIAL } from "./Constants/Constants";
import {deviceType} from "./Utils/DeviceType";
import GuidePopup from "./Utils/GuidePopup";
import {GUIDE_POPUP_ICON, GUIDE_POPUP_LABEL} from "./Constants/Constants";

let instance = null;
export default class Header {
  constructor() {
    // Singleton
    if (instance) {
      return instance;
    }

    instance = this;

    this.header = document.querySelector("header");
    this.solutionBtn = document.querySelector("div.header-solution-btn");
    this.homeBtn = document.querySelector("div.header-home-btn");
    this.audioBtn = document.querySelector("div.header-audio-btn");
    this.videoBtn = document.querySelector("div.header-video-btn");
    this.languageBtn = document.querySelector("div.header-language-btn");
    this.audioPlayer = document.querySelector("audio.header-audio-player");
    this.tutorialBtn = document.querySelector("div.header-info-btn");
    this.tutorialPopup = document.querySelector("div.tutorial-popup");
    this.tutorialClose = document.querySelector("div.tutorial-close");
    this.solutionGuide = document.querySelector("div.solution-guide");
    this.solutionLabel = document.querySelector(".solution-guide-lable");
    this.solutionClose = document.querySelector(".solution-guide-close");
    // this.solutionWrap = document.querySelector(".header-solution-btn-wrap");

    this.mainVideoContainer = document.querySelector(".main-video-container");
    this.mainVideo = document.querySelector(".main-video");

    this.isMobile = false;

    this.experience = new Experience();
    this.stateMachine = new StateMachine();

    this.localizationManager = new LocalizationManager();

    // Guide Popup
    this.guidePopup = new GuidePopup();


    this.solutionBtn.addEventListener("click", (event) => {
      this.stateMachine.SetState(StateMachine.States.SEPERATE);
    });

    this.homeBtn.addEventListener("click", (event) => {
      this.stateMachine.SetState(StateMachine.States.INITAL_STATE);
    });

    this.isAudioPlaying = false;
    this.audioBtn.addEventListener("click", (event) => {
      this.toggleAudio();
    });

    // 언어 설정 관련
    this.language = 'en';
    this.setLanguage = (lang) => {
      this.language = lang
      if (lang === 'ko') {
        document.querySelector(".header-language-btn .en").style.display = 'none';
        document.querySelector(".header-language-btn .ko").style.display = 'block';
      } else if (lang === 'en') {
        document.querySelector(".header-language-btn .ko").style.display = 'none';
        document.querySelector(".header-language-btn .en").style.display = 'block';
      }
    }

    this.languageBtn.addEventListener("click", (event) => {
      if(this.language === 'ko') {
        this.setLanguage('en')
      } else {
        this.setLanguage('ko')
      }
      this.localizationManager.changeLanguage(this.language);
    });

    const mainVideoContainer = document.querySelector(".main-video-container");
    const mainVideoClose = document.querySelector(".main-video-close");
    let isAudioVal = false;

    const videoEndRespones = () => {
      this.experience.enabled = true;
      if (isAudioVal) {
        isAudioVal = false;
        this.toggleAudio();
      }
      fadeOut(mainVideoClose);
      mainVideoClose.style.display = 'none';
      fadeOut(mainVideoContainer);
    }

    this.videoBtn.addEventListener("click", (event) => {

      this.experience.enabled = false;
      if (this.isAudioPlaying) {
        isAudioVal = true;
        this.toggleAudio();
      }
      

      let videoId = '';
      let controller = false;

      if (deviceType() === 'MO') {
        controller = true;
        const videoGuide = () => {
          if (window.matchMedia('(orientation: portrait)').matches) {
            this.guidePopup.guideType = 'popup-video';
            this.guidePopup.isAddEvent = true;
            this.guidePopup.show(GUIDE_POPUP_ICON.media, GUIDE_POPUP_LABEL.media);
          } else {
            this.guidePopup.hide();
          }
        }
        videoGuide ();
      }

      if (this.language === 'ko') {
        videoId = '691368860?h=3f12c75969';
      } else {
        videoId = '691368943?h=df898f8cf1';
      }

      const options01 = {
        url: `https://player.vimeo.com/video/${videoId}`,
        quality: '1080p',
        transparent: true,
        responsive: true,
        controls: controller,
      };

      const videoPlayer = new Vimeo.Player('main-video', options01);

      mainVideoClose.style.display = 'flex';
      fadeIn(mainVideoContainer);
      setTimeout( () => {
        videoPlayer.play();
        fadeIn(mainVideoClose);
      }, 400)

      videoPlayer.on('ended', function() {
        
        videoPlayer.destroy().then(function() {
          videoEndRespones();
        });

      });

      mainVideoClose.addEventListener('click', () => {
        videoPlayer.destroy().then(function() {
          videoEndRespones();
        });
      })

    });

    this.solutionGuideOn = () => {
      this.solutionLabel.setAttribute("data-i18n", "SOLUTIONLABEL_00");
      this.localizationManager.updateContent();
      fadeIn(this.solutionGuide);
      this.solutionBtn.classList.add('active');
    }

    this.solutionGuideOff = () => {
      fadeOut(this.solutionGuide);
      this.solutionBtn.classList.remove('active');
    }

    this.solutionClose.addEventListener('click', this.solutionGuideOff);

    // 튜토리얼
    this.setTutorial = () => {
      TUTORIAL.map((contnet, index) => {
        eval("this.tutorial" + index.toString().padStart(2, '0') + "= document.createElement('div')");
        this.tutorialPopup.appendChild(eval("this.tutorial" + index.toString().padStart(2, '0')));
        eval("this.tutorial" + index.toString().padStart(2, '0')).classList.add(`tutorial-${index.toString().padStart(2, '0')}`);
        eval("this.img" + index.toString().padStart(2, '0') + "= document.createElement('div')");
        eval("this.label" + index.toString().padStart(2, '0') + "= document.createElement('span')");
        eval("this.tutorial" + index.toString().padStart(2, '0')).appendChild(eval("this.img" + index.toString().padStart(2, '0')));
        eval("this.tutorial" + index.toString().padStart(2, '0')).appendChild(eval("this.label" + index.toString().padStart(2, '0')));
        eval("this.label" + index.toString().padStart(2, '0')).setAttribute("data-i18n", contnet);
      })
      this.localizationManager.updateContent();
    }


    this.tutorialBtn.addEventListener('click', () => {
      fadeIn(this.tutorialPopup);
    })
    this.tutorialClose.addEventListener('click', () => {
      fadeOut(this.tutorialPopup);
    })

    this.stateMachine.on("stateChange", (value) => {
      let currentState = this.stateMachine.State;
      switch (currentState) {
        case StateMachine.States.INITAL_STATE: // 메인
        case StateMachine.States.HUMAN_CENTERED:
        case StateMachine.States.COEXIST_WITH_NATURE:
        case StateMachine.States.EMBRACING_FUTURE:
          this.solutionGuideOn();
          break;
        default:
          this.solutionGuideOff();
          break;

      }
      console.log("current state : " + currentState);
    });

  }

  show() {
    fadeIn(this.header);
    this.solutionGuideOn();
    this.setTutorial();
  }

  toggleAudio() {
    this.isAudioPlaying = !this.isAudioPlaying;

    if (this.isAudioPlaying) {
      this.audioPlayer.play();
      this.isAudioPlaying = true;

      document.querySelector(".header-audio-btn .stop").style.display = 'none';
      document.querySelector(".header-audio-btn .play").style.display = 'block';
    } else {
      this.audioPlayer.pause();
      this.isAudioPlaying = false;

      document.querySelector(".header-audio-btn .play").style.display = 'none';
      document.querySelector(".header-audio-btn .stop").style.display = 'block';
    }

    return this.isAudioPlaying;
  }

  // solutionIn() {
  //   if (deviceType() === "PC") {
  //     this.solutionWrap.style.pointerEvents = 'auto';
  //   } else {
  //     this.solutionWrap.style.pointerEvents = 'auto';
  //     this.solutionWrap.style.opacity = 1;
  //   }
  // }

  // solutionOut() {
  //   if (deviceType() === "PC") {
  //     this.solutionWrap.style.pointerEvents = 'none';
  //   } else {
  //     this.solutionWrap.style.pointerEvents = 'none';
  //     this.solutionWrap.style.opacity = 0;
  //   }
  // }
}
