import * as THREE from "three";
import Experience from "./Experience.js";
import {
    OrbitControls,
    MapControls,
} from "three/examples/jsm/controls/OrbitControls.js";
import gsap from "gsap";
import { Timeline } from "gsap/gsap-core";
import StateMachine from "./StateMachine.js";
import { SUBMENU } from "./Constants/Constants";
import TweenControl from "./Utils/TweenControl";

export default class Camera {
    constructor() {
        this.experience = new Experience();
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;
        this.canvas = this.experience.canvas;
        this.debug = this.experience.debug;
        this.stateMachine = this.experience.stateMachine;
        this.popup = this.experience.popup;
        this.resources = this.experience.resources;
        this.setInstance();
        this.setControls();
        this.setStateMachineEvent();
        this.guiSetup();
        this.tl = new Timeline();
        // const startPos = 10000;
        // this.instance.position.set(startPos, startPos, startPos);
        this.resources.on("ready", () => {
            if (this.experience.debug.active) {
                this.initalTween();
            }
        });
        this.initalPosition();
    }

    guiSetup() {
        if (this.debug.active) {
            this.debugFolder = this.debug.ui.addFolder("Camera");
            this.debugFolder.close();

            this.debugFolder
                .add(this.instance.position, "x")
                .name("positionX")
                .min(-3000)
                .max(3000)
                .step(1);
            this.debugFolder
                .add(this.instance.position, "y")
                .name("positionY")
                .min(-3000)
                .max(3000)
                .step(1);
            this.debugFolder
                .add(this.instance.position, "z")
                .name("positionZ")
                .min(-20000)
                .max(20000)
                .step(1);

            this.debugFolder
                .add(this.controls.target, "x")
                .name("targetX")
                .min(-5000)
                .max(5000)
                .step(1);
            this.debugFolder
                .add(this.controls.target, "y")
                .name("targetY")
                .min(-5000)
                .max(5000)
                .step(1);
            this.debugFolder
                .add(this.controls.target, "z")
                .name("targetZ")
                .min(-5000)
                .max(5000)
                .step(1);
            this.debugFolder
                .add(this.instance, "fov")
                .name("fov")
                .min(1)
                .max(180)
                .step(0.1)
                .onChange(() => {
                    this.instance.updateProjectionMatrix();
                });
        }
    }
    initalPosition() {
        this.distWeight = 1;
        this.distWeight = this.sizes.height / this.sizes.width;
        this.distWeight = Math.max(this.distWeight, 1);

        const startPos = 500 * this.distWeight + 600;
        this.instance.position.set(startPos, startPos, startPos);
        this.controls.dampingFactor = 0.3;
    }

    initalTween() {
        const endPos = 500 * this.distWeight;
        let tween = gsap.to(this.instance.position, {
            duration: 5,
            ease: "power2.inOut",
            x: endPos,
            y: endPos,
            z: endPos,
            onComplete: () => {
                this.controls.maxDistance = 20000;
                this.popup = this.experience.popup;
                //this.stateMachine.SetState("Seperate");
            },
        });
        TweenControl.AddTween(tween);
    }
    getCameraPoseByState(currentState) {
        for (let index = 0; index < Camera.CameraPoses.length; index++) {
            const element = Camera.CameraPoses[index];
            if (element.state == currentState) {
                let newElement = {...element };
                let ratio = this.sizes.height / this.sizes.width;
                let maxRatio = 2;
                if (currentState == StateMachine.States.SEPERATE) {
                    maxRatio = 1.2;
                }
                ratio = Math.max(1, ratio);
                ratio = Math.min(maxRatio, ratio);
                // console.log("ratio : " , ratio);
                let position = new THREE.Vector3(newElement.position.x, newElement.position.y, newElement.position.z);
                let target = new THREE.Vector3(newElement.target.x, newElement.target.y, newElement.target.z);
                let diff = position.sub(target);
                let length = diff.length();
                let normalized = diff.normalize();
                let newPosition = normalized.multiplyScalar(length * ratio);
                newPosition = newPosition.add(target);

                newElement.position = {
                    x: newPosition.x,
                    y: newPosition.y,
                    z: newPosition.z
                }
                return newElement;
            }
        }
    }

    setStateMachineEvent() {
        this.stateMachine.on("stateChange", (value) => {
            let currentState = this.stateMachine.State;
            this.tl = new Timeline();
            TweenControl.AddTimeline(this.tl);
            let distWeight = 1;
            distWeight = this.sizes.height / this.sizes.width;
            distWeight = Math.max(distWeight, 1);
            let pose = this.getCameraPoseByState(currentState);
            //console.log(pose);
            switch (currentState) {
                case StateMachine.States.INITAL_STATE:
                case StateMachine.States.HUMAN_CENTERED:
                    this.tl.to(this.instance.position, {
                        duration: 1.5,
                        ease: "power1.inOut",
                        x: pose.position.x,
                        y: pose.position.y,
                        z: pose.position.z,
                    });
                    this.tl.to(
                        this.instance, {
                            duration: 3,
                            delay: 0.3,
                            ease: "power1.inOut",
                            fov: pose.fov,
                            onUpdate: () => {
                                this.instance.updateProjectionMatrix();
                            },
                        },
                        0
                    );
                    this.tl.to(
                        this.controls.target, {
                            duration: 1,
                            ease: "power1.inOut",
                            x: pose.target.x,
                            y: pose.target.y,
                            z: pose.target.z,
                        },
                        0
                    );
                    break;
                case StateMachine.States.SEPERATE:
                case StateMachine.States.EMBRACING_FUTURE:
                    this.tl.to(
                        this.instance.position, {
                            duration: 3,
                            delay: 0.3,
                            ease: "power1.inOut",
                            x: pose.position.x,
                        },
                        0
                    );
                    this.tl.to(
                        this.instance.position, {
                            duration: 1.6,
                            delay: 0.0,
                            ease: "power1.inOut",
                            y: pose.position.y,
                            z: pose.position.z,
                        },
                        0
                    );
                    this.tl.to(
                        this.instance, {
                            duration: 3,
                            delay: 0.3,
                            ease: "power1.inOut",
                            fov: pose.fov,
                            onUpdate: () => {
                                this.instance.updateProjectionMatrix();
                            },
                        },
                        0
                    );
                    this.tl.to(
                        this.controls.target, {
                            duration: 4,
                            ease: "power1.inOut",
                            x: pose.target.x,
                            y: pose.target.y,
                            z: pose.target.z,
                        },
                        0
                    );
                    break;
                default:
                    this.tl.to(
                        this.instance.position, {
                            duration: 2,
                            delay: 0.3,
                            ease: "power1.inOut",
                            x: pose.position.x,
                            y: pose.position.y,
                            z: pose.position.z,
                        },
                        0
                    );
                    this.tl.to(
                        this.instance, {
                            duration: 2,
                            delay: 0.3,
                            ease: "power1.inOut",
                            fov: pose.fov,
                            onUpdate: () => {
                                this.instance.updateProjectionMatrix();
                            },
                        },
                        0
                    );
                    this.tl.to(
                        this.controls.target, {
                            duration: 1,
                            ease: "power1.inOut",
                            x: pose.target.x,
                            y: pose.target.y,
                            z: pose.target.z,

                            onComplete: () => {
                                if (pose.popup != undefined) {
                                    let delay = pose.popupDelay ? pose.popupDelay : 2000;
                                    setTimeout(() => {
                                        if (this.popup) {
                                            this.popup.show(pose.popup);
                                        }
                                    }, delay);
                                }
                            },
                        },
                        0
                    );

                    break;
            }
        });
    }

    setInstance() {
        this.instance = new THREE.PerspectiveCamera(
            35,
            this.sizes.width / this.sizes.height,
            0.1,
            14000
        );

        this.instance.position.set(600, 600, 600);

        this.instance.lookAt(0, 0, 0);
        this.scene.add(this.instance);
    }

    setControls() {
        this.controls = new MapControls(this.instance, this.canvas);
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.05;
        this.controls.screenSpacePanning = false;
        this.controls.minDistance = 10;
        this.controls.maxDistance = 20000;
        this.controls.minPolarAngle = -(Math.PI / 2); // * 0.5;
        this.controls.maxPolarAngle = Math.PI / 2; // * 0.95;

        this.controls.mouseButtons.LEFT = 2;
        this.controls.mouseButtons.MIDDLE = 1;
        this.controls.mouseButtons.RIGHT = 0;
    }

    fitCameraToObject(object, offset) {
        offset = offset || 1.25;

        const boundingBox = new THREE.Box3();

        // get bounding box of object - this will be used to setup controls and camera
        boundingBox.setFromObject(object);

        const center = boundingBox.getCenter();
        const size = boundingBox.getSize();

        // get the max side of the bounding box (fits to width OR height as needed )
        const maxDim = Math.max(size.x, size.y, size.z);
        const fov = this.instance.fov * (Math.PI / 180);
        let cameraZ = Math.abs((maxDim / 4) * Math.tan(fov * 2));

        cameraZ *= offset; // zoom out a little so that objects don't fill the screen

        this.instance.position.z = cameraZ;

        const minZ = boundingBox.min.z;
        const cameraToFarEdge = minZ < 0 ? -minZ + cameraZ : cameraZ - minZ;

        this.instance.far = cameraToFarEdge * 3;
        this.instance.updateProjectionMatrix();

        if (this.controls) {
            // set camera to rotate around center of loaded object
            this.controls.target = center;

            // prevent camera from zooming out far enough to create far plane cutoff
            this.controls.maxDistance = cameraToFarEdge * 2;
            this.controls.saveState();
        } else {
            camera.lookAt(center);
        }
    }
    resize() {
        this.instance.aspect = this.sizes.width / this.sizes.height;
        this.instance.updateProjectionMatrix();
    }

    update() {
        if (this.debug.active) {
            this.debugFolder.children[0].value = this.instance.position.x;
            this.debugFolder.children[0].updateDisplay();
            this.debugFolder.children[1].value = this.instance.position.y;
            this.debugFolder.children[1].updateDisplay();
            this.debugFolder.children[2].value = this.instance.position.z;
            this.debugFolder.children[2].updateDisplay();

            this.debugFolder.children[3].value = this.controls.target.x;
            this.debugFolder.children[3].updateDisplay();
            this.debugFolder.children[4].value = this.controls.target.y;
            this.debugFolder.children[4].updateDisplay();
            this.debugFolder.children[5].value = this.controls.target.z;
            this.debugFolder.children[5].updateDisplay();

            this.debugFolder.children[6].value = this.instance.fov;
            this.debugFolder.children[6].updateDisplay();
        }

        let tweenCount = gsap.getTweensOf(this.instance.position).length + (this.tl.isActive() ? 1 : 0);

        this.controls.enabled = tweenCount == 0;
        this.controls.update();
    }
}

Camera.CameraPoses = [{
        state: StateMachine.States.INITAL_STATE,
        position: { x: 500, y: 500, z: 500 },
        target: { x: 0, y: 0, z: 0 },
        fov: 35,
    },

    {
        state: StateMachine.States.SEPERATE,
        position: { x: 370, y: 370, z: 650 },
        target: { x: 0, y: -80, z: 0 },
        fov: 50,
    },

    {
        state: StateMachine.States.HUMAN_CENTERED,
        position: { x: 500, y: 500, z: 500 },
        target: { x: 0, y: 0, z: 0 },
        fov: 35,
    },
    {
        state: StateMachine.States.COEXIST_WITH_NATURE,
        position: { x: 500, y: 500, z: 500 },
        target: { x: 0, y: 0, z: 0 },
        fov: 35,
    },
    {
        state: StateMachine.States.EMBRACING_FUTURE,
        position: { x: 663.7008605762411, y: 291.6484028672581, z: -473.7534979030094 },
        target: { x: 0, y: 0, z: 0 },
        fov: 35,
    },

    {
        state: StateMachine.States.WALKABLE_CITY_0, //FIX //5
        position: {
            x: 13.250171633235238,
            y: 927.512014324154,
            z: 13.250171633235245,
        },
        target: {
            x: 0,
            y: 0,
            z: 0,
        },
        fov: 35,
        popup: SUBMENU.WALKABLE_CITY,
        popupDelay: 3000,
    },
    {
        state: StateMachine.States.WALKABLE_CITY_1, //FIX 6
        position: {
            x: -155.72925619220032,
            y: 103.67624603620068,
            z: 198.29664827431216,
        },
        target: {
            x: -241.44845807585793,
            y: 1.0082796500308318e-15,
            z: 7.13476544811105,
        },
        fov: 35,
        popup: SUBMENU.WALKABLE_CITY,
        popupDelay: 3000,
    },

    {
        state: StateMachine.States.HYPON_DENSITY_0, //7
        position: {
            x: 81.34532855650917,
            y: 78.08780837799027,
            z: 184.03643766781587,
        },
        target: {
            x: 237.52860785323122,
            y: 3.691894767051653e-15,
            z: 120.30711403218496,
        },
        fov: 35,
        popup: SUBMENU.HYPON_DENSITY,
        popupDelay: 3000,
    },

    {
        state: StateMachine.States.HYPON_DENSITY_1, //FIX 8
        position: {
            x: 309.05812356548523,
            y: 37.27306823146783,
            z: 181.9605322663401,
        },
        target: {
            x: 61.224568963964394,
            y: 8.482724868630464e-17,
            z: 26.47447638609676,
        },
        fov: 35,
        popup: SUBMENU.HYPON_DENSITY,
        popupDelay: 3000,
    },

    {
        state: StateMachine.States.CONVENIENCE_CITY_0, //FIX 9
        position: {
            x: 141.39519855629902,
            y: 114.54852959683052,
            z: 36.96985703008099,
        },
        target: {
            x: 218.17005240145528,
            y: 2.1275847766225158e-15,
            z: -111.56668656715948,
        },
        fov: 35,
        popup: SUBMENU.CONVENIENCE_CITY,
        popupDelay: 3000,
    },
    {
        state: StateMachine.States.CONVENIENCE_CITY_1, //FIX 10
        position: {
            x: 116.56836183385342,
            y: 148.5981914428145,
            z: 81.43269969194638,
        },
        target: {
            x: 237.26179350655516,
            y: -9.092956965345983e-16,
            z: -63.689333468175974,
        },
        fov: 35,
        popup: SUBMENU.CONVENIENCE_CITY,
        popupDelay: 3000,
    },
    {
        state: StateMachine.States.EMBRACES_NATURE_0, //FIX 11
        position: {
            x: -15.592408428086411,
            y: 432.29681613436225,
            z: 689.5544145137305,
        },
        target: {
            x: 4.817251452544091,
            y: -3.0958108905112324e-17,
            z: 55.05571117141142,
        },
        fov: 35,
        popup: SUBMENU.EMBRACES_NATURE,
        popupDelay: 3000,
    },
    {
        state: StateMachine.States.EMBRACES_NATURE_1, //FIX 12
        position: {
            x: -29.84723047063747,
            y: 87.89993701384857,
            z: 374.41123196739824,
        },
        target: {
            x: -74.23952264041601,
            y: 3.35589249795903e-17,
            z: -7.360505858541666,
        },
        fov: 35,
        popup: SUBMENU.EMBRACES_NATURE,
        popupDelay: 3000,
    },
    {
        state: StateMachine.States.NATURE_ECOSYSTEM_0, //FIX 13
        position: {
            x: 257.28848756793326,
            y: 63.354101913769505,
            z: -136.34074230842415,
        },
        target: {
            x: 119.730207,
            y: -2.3025236853357866e-218,
            z: 29.056386,
        },
        fov: 35,
        popup: SUBMENU.NATURE_ECOSYSTEM,
        popupDelay: 3000,
    },
    {
        state: StateMachine.States.NATURE_ECOSYSTEM_1, //FIX 14
        position: {
            x: 37.111537762167885,
            y: 85.68438739745407,
            z: -19.793642614114816,
        },
        target: {
            x: 151.5917678219132,
            y: -5.173718842846732e-15,
            z: -192.54981673063673,
        },
        fov: 35,
        popup: SUBMENU.NATURE_ECOSYSTEM,
        popupDelay: 3000,
    },
    {
        state: StateMachine.States.SUSTAINABLE_CITY_0, //FIX 15
        position: {
            x: 382.14858404307324,
            y: 94.92411381052347,
            z: -156.99461021835674,
        },
        target: {
            x: 180.95413365121897,
            y: -6.147228236510194e-15,
            z: -128.66726014120033,
        },
        fov: 35,
        popup: SUBMENU.SUSTAINABLE_CITY,
        popupDelay: 3000,
    },
    {
        state: StateMachine.States.SUSTAINABLE_CITY_1, //FIX 16
        position: {
            x: 1.887624509814657,
            y: 65.3248237297672,
            z: -97.8793123059453,
        },
        target: {
            x: 209.27446405183474,
            y: -6.3641745088600465e-15,
            z: -152.79378401454798,
        },
        fov: 35,
        popup: SUBMENU.SUSTAINABLE_CITY,
        popupDelay: 3000,
    },
    {
        state: StateMachine.States.GIRD_SYSTEM_0, //FIX 17
        position: {
            x: 469.93934088490596,
            y: 141.11036046854002,
            z: 297.63486726127843,
        },
        target: {
            x: 255.7234686897435,
            y: -4.435410795596611e-15,
            z: 77.48251069657832,
        },
        fov: 35,
        popup: SUBMENU.GIRD_SYSTEM,
        popupDelay: 3000,
    },
    {
        state: StateMachine.States.GIRD_SYSTEM_1, //FIX 18
        position: {
            x: 558.7009718239804,
            y: 51.32831864528809,
            z: -24.902112911065416,
        },
        target: {
            x: 264.81334016720444,
            y: -4.457817155844891e-15,
            z: 93.9339220210893,
        },
        fov: 35,
        popup: SUBMENU.GIRD_SYSTEM,
        popupDelay: 3000,
    },
    {
        state: StateMachine.States.GIRD_SYSTEM_2, //FIX 19
        position: {
            x: 323.48387980951327,
            y: 161.5382919000576,
            z: 390.9190557478291,
        },
        target: {
            x: 240.8638679041211,
            y: -4.685060039173657e-15,
            z: 125.95518383895553,
        },
        fov: 35,
        popup: SUBMENU.GIRD_SYSTEM,
        popupDelay: 3000,
    },
    {
        state: StateMachine.States.CONNECTED_CITY_0, //FIX 20
        position: {
            x: 586.0254644595173,
            y: 198.78468393568525,
            z: -416.3120930977464,
        },
        target: {
            x: 218.45960060549658,
            y: 9.144989064408841e-17,
            z: -171.9946137932792,
        },
        fov: 35,
        popup: SUBMENU.CONNECTED_CITY,
        popupDelay: 3000,
    },
    {
        state: StateMachine.States.HUB20_0, //FIX 21
        position: {
            x: 406.96048962057915,
            y: 145.331635,
            z: -399.47655587133846,
        },
        target: {
            x: 238.0539706205792,
            y: -7.413255856297245e-17,
            z: -98.41181187133846,
        },
        fov: 35,
        popup: SUBMENU.HUB20,
        popupDelay: 3000,
    },


];