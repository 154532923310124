import * as THREE from "three";
import Experience from "../Experience.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { CopyShader } from "three/examples/jsm/shaders/CopyShader.js";
import { TAARenderPass } from "three/examples/jsm/postprocessing/TAARenderPass.js";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader.js";

import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader.js";
import { LUTPass } from "three/examples/jsm/postprocessing/LUTPass.js";
import { TexturePass } from "three/examples/jsm/postprocessing/TexturePass.js";
import { ClearPass } from "three/examples/jsm/postprocessing/ClearPass.js";

export default class Composer {
    constructor() {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;
        this.cameraPos = new THREE.Vector3();
        this.renderer = this.experience.renderer;
        this.resources = this.experience.resources;
        this.time = this.experience.time;
        this.sizes = this.experience.sizes;
        this.debug = this.experience.debug;
        this.param = { TAAEnabled: "1", TAASampleLevel: 0 };
        this.target = new THREE.WebGLRenderTarget({
            minFilter: THREE.LinearFilter,
            magFilter: THREE.LinearFilter,
            format: THREE.RGBAFormat,
            encoding: THREE.sRGBEncoding,
        });
        this.instance = new EffectComposer(this.renderer.instance, this.target);

        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2));
        this.instance.setSize(window.innerWidth, window.innerHeight);

        // console.log(Math.min(this.sizes.pixelRatio, 2))
        // this.lutPass = new LUTPass();

        this.clearPass = new ClearPass("white", 1.0);
        this.instance.addPass(this.clearPass);

        // this.texturePass = new TexturePass();
        // this.texturePass.map = this.resources.items.gradientBGTexture;
        // this.instance.addPass(this.texturePass);

        this.renderPass = new RenderPass(this.scene, this.camera.instance);
        this.renderPass.enabled = true;
        this.instance.addPass(this.renderPass);

        // this.copyPass = new ShaderPass(CopyShader);
        // this.instance.addPass(this.copyPass);

        // this.gammaCorrectionShader = new ShaderPass(GammaCorrectionShader);
        // this.instance.addPass(this.gammaCorrectionShader);

        // this.instance.addPass(this.lutPass);
        // Debug
        if (this.debug.active) {}

        // Resource
        // this.resources.on("ready", () => {
        // Setup
        // this.lutPass.lut = this.resources.items.Bourbon64.texture;
        // });
    }
    update() {
        this.instance.render();
    }
}