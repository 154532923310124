export const calculateValues = (process, values) => {
    let rv;

    const partScrollStart = values[2].start;
    const partScrollEnd = values[2].end;
    const partScrollHeight = partScrollEnd - partScrollStart;
    const partRatio = (process - partScrollStart) / partScrollHeight;

    if (process >= partScrollStart && process <= partScrollEnd) {
        if (values[2].easing === 'ease-in-out') {
            rv = (easeInOutSine(partRatio) * (values[1] - values[0]) + values[0]);
        } else {
            rv = (partRatio * (values[1] - values[0]) + values[0]);
        }
    } else if (process < partScrollStart) {
        rv = values[0];

    } else if (process > partScrollEnd) {
        rv = values[1];
    }

    function easeInOutSine(x) {
        return -(Math.cos(Math.PI * x) - 1) / 2;
    }

    return rv;
}
