import * as THREE from "three";
import Experience from "./Experience.js";

export default class Renderer {
    constructor() {
        this.experience = new Experience();
        this.canvas = this.experience.canvas;
        this.sizes = this.experience.sizes;
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;
        //this.currentSize = new THREE.Vector2();
        this.setInstance();
    }

    setInstance() {
        this.instance = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: true,
            logarithmicDepthBuffer: true,
            alpha: true,
            autoClear: true,
        });

        this.instance.physicallyCorrectLights = true;
        this.instance.outputEncoding = THREE.sRGBEncoding;
        this.instance.toneMapping = THREE.CineonToneMapping; //ACESFilmicToneMapping; //CineonToneMapping;
        this.instance.toneMappingExposure = 1.15;
        this.instance.shadowMap.enabled = true;
        this.instance.shadowMap.type = THREE.PCFSoftShadowMap;

        if (this.experience.debug.iOS >= 150400) {
            this.instance.shadowMap.enabled = false;
        }

        this.instance.setClearColor(0xffffff, 0.0);

        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2));
    }

    resize() {
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2));
    }

    update() {
        // let debugText = document.getElementsByClassName("debug-text")[0];
        // let text = this.currentSize.x + " " + this.currentSize.y + "   " + document.documentElement.clientWidth + " " + document.documentElement.clientHeight;
        // debugText.innerHTML = text;
    }
}