
export const redefinitionHeight = () => {
  const user = navigator.userAgent;
  let rn;

  if (user.indexOf("iPhone") > -1 || user.indexOf("iPad") > -1) {
    return rn = document.documentElement.clientHeight;
  } else {
    return rn = window.innerHeight;
  }

}

export const redefinitionWidth = () => {
  const user = navigator.userAgent;
  let rn;

  if (user.indexOf("iPhone") > -1 || user.indexOf("iPad") > -1) {
    return rn = document.documentElement.clientWidth;
  } else {
    return rn = window.innerWidth;
  }

}

