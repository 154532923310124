import * as THREE from "three";
import Experience from "../Experience.js";
import gsap from "gsap";
import { Timeline } from "gsap/gsap-core";
import StateMachine from "../StateMachine.js";

export default class UndergroundAnimations {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.stateMachine = this.experience.stateMachine;
    this.time = this.experience.time;
    this.debug = this.experience.debug;
    this.group = new THREE.Group();
    this.instances = [];
    this.animations = [];
    this.animationSpds = [];
    // Debug
    if (this.debug.active) {
      //this.debugFolder = this.debug.ui.addFolder("Underground");
      //this.debugFolder.close();
    }

    // Resource
    this.resource = this.resources.items.undergroundCar;

    this.setMaterial();
    this.setModel();
    this.setAnimation();

    this.stateMachine.on("stateChange", (value) => {
      let currentState = this.stateMachine.State;
      var tl = new Timeline();
      switch (currentState) {
          case StateMachine.States.SEPERATE:
          case StateMachine.States.COEXIST_WITH_NATURE:
          case StateMachine.States.HUMAN_CENTERED:
              this.group.visible = false;
              break;
          case StateMachine.States.EMBRACING_FUTURE:
          case StateMachine.States.GIRD_SYSTEM_0:
          case StateMachine.States.GIRD_SYSTEM_1:
          case StateMachine.States.GIRD_SYSTEM_2:
              this.group.visible = true;
              break;
          default:
            this.group.visible = false;
              break;
      }
  });
  }

  setModel() {
    this.model = this.resource.scene;
    this.model.scale.set(
      this.experience.sizes.modelScale,
      this.experience.sizes.modelScale,
      this.experience.sizes.modelScale
    );
    // this.scene.add(this.group);
    this.experience.world.underground.group.add(this.group);

    this.group.add(this.model);
    this.group.name = "scene";

    this.model.traverse((child) => {
      // Scene
      // A_CAR_01
      // A_CAR_02
      // B_CAR_01
      // B_CAR_02
      // C_CAR_01
      // C_CAR_02
      // D_CAR_01
      // D_CAR_02
      // E_CAR_01
      // E_CAR_02
      // RA_CAR_01
      // RA_CAR_02
      // RB_CAR_01
      // RB_CAR_02
      // RC_CAR_01
      // RC_CAR_02
      // RD_CAR_01
      // RD_CAR_02
      // RE_CAR_01
      // RE_CAR_02
      // RF_CAR_01
      // RF_CAR_02
      if (child.name.includes("CAR")) {
        this.instances.push(child);
      }

      if (child.isMesh) {
        // if (child.name.includes("CAR_01_5F")) {
        //   child.material = this.material;
        //   for (let index = 0; index < 50; index++) {
        //     let instance = new THREE.Mesh(child.geometry, this.material);
        //     instance.name = "CAR_01_5F";
        //     this.instances.push(instance);
        //     this.animationSpds.push(Math.random() * 0.0015);
        //     child.parent.add(instance);
        //   }
        // } else {
        //   child.visible = false;
        // }
      }
    });
    // for (let index = 0; index < this.resource.animations.length; index++) {
    //   const element = this.resource.animations[index];
    //   console.log(element);
    // }
  }

  setMaterial() {
    this.material = new THREE.MeshStandardMaterial({
      color: new THREE.Color(0.3, 0.3, 0.3),
      blending: THREE.AdditiveBlending,
      //   transparent: true,
    });
  }
  setAnimation() {
    for (let index = 0; index < this.resource.animations.length; index++) {
      const element = this.resource.animations[index];

      let animation = {};
      animation.mixer = new THREE.AnimationMixer(this.instances[index]);
      animation.actions = {};
      animation.actions.idle = animation.mixer.clipAction(element);
      let duration = element.duration;
      animation.actions.current = animation.actions.idle;
      animation.actions.current.play();
      animation.mixer.setTime(Math.random() * duration);

      this.animations.push(animation);
    }
  }

  update() {
    // console.log(" ");
    if(this.group.visible){
      for (let index = 0; index < this.animations.length; index++) {
        const animation = this.animations[index];
        animation.mixer.update(this.time.delta * 0.001);
      }
    }
  }
}
