import {GUIDE_POPUP_ICON, GUIDE_POPUP_LABEL, SUBMENU_CONTENTS} from "./Constants/Constants";
import Experience from "./Experience";
import {fadeIn, fadeOut} from "./Utils/TransitionHelper";
import {deviceType} from "./Utils/DeviceType";
import GuidePopup from "./Utils/GuidePopup";
import {Vector2} from "three";

export default class DetailImages {
  constructor() {

    this.experience = new Experience();

    this.guidePopup = new GuidePopup();

    //visual-img
    this.visualImageWrap = document.getElementsByClassName("visual-img-container")[0];
    this.visualcloseBtn = document.getElementsByClassName("visual-img-close")[0];
    this.visualNextBtn = document.getElementsByClassName("visual-next-btn")[0];
    this.visualPreviousBtn = document.getElementsByClassName("visual-previous-btn")[0];

    this.image = new Image();
    this.canvas = document.getElementsByClassName("visual-img")[0];
    this.ctx = this.canvas.getContext("2d");

    this.visualcloseBtn.addEventListener("click", () => {
      this.hide();
    });

    this.visualNextBtn.addEventListener("click", () => {
      if (this.currentIndex === this.maxImages) {
        return;
      }

      this.currentIndex++;
      this.refresh();
    });

    this.visualPreviousBtn.addEventListener("click", () => {
      if (this.currentIndex === 0) {
        return;
      }

      this.currentIndex--;
      this.refresh();
    });

    // Resize event
    window.addEventListener("resize", () => this.resizeCanvas());

    this.moveX = 0;
    this.moveY = 0;
    this.lastOffsets = {
      x: undefined,
      y: undefined
    }

    let scaling = false,
        scaleFactor = 1.05,
        distance,
        lastDistance = 0,
        startCoords = {
          x: 0,
          y: 0
        },
        last = {
          x: 0,
          y: 0
        };

    const pointers = [];
    const pointerPositions = {};

    const addPointer = (e) => {
      pointers.push(e);
    };

    const removePointer = (e) => {
      delete pointerPositions[e.pointerId];

      for ( let i = 0; i < pointers.length; i ++ ) {
        if ( pointers[ i ].pointerId === e.pointerId ) {
          pointers.splice( i, 1 );

          if (pointers.length === 0) {
            console.log("초기화!!");
            last = {
              x: 0,
              y: 0
            }
          }
          return;
        }
      }
    };

    const onTouchStart = (e) => {
      trackPointer( e );

      console.log("onTouchStart pointers.length : " + pointers.length);
      switch (pointers.length) {
        case 1: {
          const position = this.pointerEvents(e);
          startCoords = {
            x: position.x - last.x,
            y: position.y - last.y
          };
        }
          break;
        case 2: {
          scaling = true;
          lastDistance = calculateDistance();
        }
          break;
      }
    };

    this.canvas.addEventListener("pointercancel", (e) => {
      console.log("pointercancel");
      removePointer(e);
    });

    this.canvas.addEventListener("pointerdown", (e) => {
      console.log("onPointerDown!!");
      if ( pointers.length === 0 ) {
        this.lastOffsets.x = this.calculateOffsetX();
        this.lastOffsets.y = this.calculateOffsetY();

        this.canvas.addEventListener("pointermove", onPointerMove);
        this.canvas.addEventListener("pointerup", onPointerUp);
      }

      addPointer(e);

      if ( e.pointerType === 'touch' ) {
        onTouchStart(e);
      }
    });

    const onPointerMove = (e) => {
      if ( e.pointerType === 'touch' ) {
        onTouchMove(e);
      }
    }

    const onPointerUp = (e) => {
      console.log("onPointerUp!!");

      if ( e.pointerType === 'touch' ) {
        onTouchEnd(e);
      }

      removePointer(e);

      if (pointers.length === 0) {
        console.log("lastOffsets : " + JSON.stringify(this.lastOffsets));

        this.canvas.removeEventListener('pointermove', onPointerMove);
        this.canvas.removeEventListener('pointerup', onPointerUp);
      }
    }

    const onTouchMove = (e) => {
      trackPointer(e);

      // e.preventDefault();
      if (scaling) {
        distance = calculateDistance();

        requestAnimationFrame(() => {
          lastDistance = this.scaleCanvasTouch(lastDistance, distance, scaleFactor);
        });
      } else {
        const position = this.pointerEvents(e),
            offset = 1.3;

        this.moveX = (position.x - startCoords.x) * offset;
        this.moveY = (position.y - startCoords.y) * offset;

        // console.log("moveX : " + this.moveX);

        requestAnimationFrame(() => this.render());
      }
    }

    const onTouchEnd = (e) => {
      const position = this.pointerEvents(e);

      scaling = false;

      last = {
        x: position.x - startCoords.x,
        y: position.y - startCoords.y
      };
    };

    const trackPointer = ( event ) => {
      let position = pointerPositions[ event.pointerId ];
      if ( position === undefined ) {
        position = new Vector2();
        pointerPositions[ event.pointerId ] = position;
      }
      position.set( event.pageX, event.pageY );
    }

    const calculateDistance = () =>{
      return Math.sqrt(
          (pointerPositions[pointers[0].pointerId].x - pointerPositions[pointers[1].pointerId].x) *
          (pointerPositions[pointers[0].pointerId].x - pointerPositions[pointers[1].pointerId].x) +
          (pointerPositions[pointers[0].pointerId].y - pointerPositions[pointers[1].pointerId].y) *
          (pointerPositions[pointers[0].pointerId].y - pointerPositions[pointers[1].pointerId].y)
      );
    }
  }

  pointerEvents(e) {
    return {
      x: e.pageX,
      y: e.pageY
    };
  }

  scaleCanvasTouch(lastDistance, distance, scaleFactor) {
    if (lastDistance > distance) {
      this.scale = this.scale / scaleFactor;
      if (this.scale < this.orgScale) this.scale = this.orgScale;
    } else if (lastDistance < distance) {
      this.scale = this.scale * scaleFactor;
      if (this.scale > this.maxScale) this.scale = this.maxScale;
    }

    requestAnimationFrame(() => this.render());

    return distance;
  }

  show(content) {
    this.moveX = 0;
    this.moveY = 0;
    this.lastOffsets = {
      x: undefined,
      y: undefined
    }

    this.experience.enabled = false;

    this.currentIndex = 0;
    this.currentContent = content;
    this.maxImages = content.images.length - 1;

    if (deviceType() === 'MO') {
      this.guidePopup.guideType = 'popup-img';
      this.guidePopup.isAddEvent = true;
      this.guidePopup.show(GUIDE_POPUP_ICON.media, GUIDE_POPUP_LABEL.media);
    }

    // Show/Hide
    if (this.maxImages >= 1) {
      this.visualNextBtn.style.removeProperty('display');
      this.visualPreviousBtn.style.removeProperty('display');
    } else if (this.maxImages === 0) {
      this.visualNextBtn.style.display = 'none';
      this.visualPreviousBtn.style.display = 'none';
    }

    this.refresh();
    fadeIn(this.visualImageWrap);
  }

  hide() {
    this.experience.enabled = true;
    fadeOut(this.visualImageWrap);
  }

  loadImage(src) {
    this.scale = 1;
    this.image.src = src;
    this.image.onload = () => {
      this.resizeCanvas(window.innerWidth, window.innerHeight);
      // $("canvas").addClass("loaded");
    };
  }

  refresh() {
    // max
    if (this.currentIndex === this.maxImages) {
      this.visualNextBtn.classList.add('inactive');
    } else {
      this.visualNextBtn.classList.remove('inactive');
    }

    // min
    if (this.currentIndex === 0) {
      this.visualPreviousBtn.classList.add('inactive');
    } else {
      this.visualPreviousBtn.classList.remove('inactive');
    }

    this.loadImage('images/popup/' + this.currentContent.images[this.currentIndex]);
  }

  resizeCanvas() {
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;

    const hScale = this.canvas.height / this.image.height;
    const wScale = this.canvas.width / this.image.width;
    this.orgScale = (hScale < wScale) ? hScale : wScale;
    this.scale = this.orgScale;

    this.maxScale = 1;

    requestAnimationFrame(() => this.render());
  }

  render() {
    const imageWidth = this.image.width * this.scale;
    const imageHeight = this.image.height * this.scale;

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    console.log("render scale : " + this.scale)
    console.log("render calculateOffsetY() : " + this.calculateOffsetY())

    this.ctx.drawImage(
        this.image,
        this.calculateOffsetX(),
        this.calculateOffsetY(),
        imageWidth,
        imageHeight
    );
  }

  calculateOffsetX() {
    const imageWidth = this.image.width * this.scale;
    const offsetX = this.lastOffsets.x ? -this.lastOffsets.x : (imageWidth - this.canvas.width) / 2;

    if (this.moveX > offsetX) {
      this.moveX = offsetX;
    }

    if (this.moveX < -(imageWidth - offsetX - this.canvas.width)) {
      this.moveX = -(imageWidth - offsetX - this.canvas.width);
    }

    return -offsetX + this.moveX;
  }

  calculateOffsetY() {
    const imageHeight = this.image.height * this.scale;
    const offsetY = this.lastOffsets.y ? -this.lastOffsets.y : (imageHeight - this.canvas.height) / 2;

    if (offsetY < 0) {
      if (this.moveY < offsetY) {
        this.moveY = offsetY;
      }

      if (this.moveY > -(imageHeight - offsetY - this.canvas.height)) {
        this.moveY = -(imageHeight - offsetY - this.canvas.height);
      }
    } else {
      if (this.moveY > offsetY) {
        this.moveY = offsetY;
      }

      if (this.moveY < -(imageHeight - offsetY - this.canvas.height)) {
        this.moveY = -(imageHeight - offsetY - this.canvas.height);
      }
    }

    return -offsetY + this.moveY;
  }
}

