import StateMachine from "../StateMachine";

export const MENU = {
    HUMAN_CENTERED : {count: 3, name: "Human-centered"},
    COEXISTING_WITH_NATURE: {count: 3, name: "Coexisting with Nature"},
    EMBRACING_THE_FUTURE : {count: 3, name: "Embracing the Future"},
};

export const SUBMENU = {
    WALKABLE_CITY : {name: 'HC_0000', parent: StateMachine.States.HUMAN_CENTERED}, // 걷고 싶은 도시
    HYPON_DENSITY : {name: 'HC_1000', parent: StateMachine.States.HUMAN_CENTERED}, // 밀도로 구획된 도시
    CONVENIENCE_CITY : {name: 'HC_2000', parent: StateMachine.States.HUMAN_CENTERED}, // 생활 편의를 확보한 도시
    EMBRACES_NATURE : {name: 'CN_0000', parent: StateMachine.States.COEXIST_WITH_NATURE}, // 자연을 품은 도시
    NATURE_ECOSYSTEM : {name: 'CN_1000', parent: StateMachine.States.COEXIST_WITH_NATURE}, // 자연과 공존하는 도시
    SUSTAINABLE_CITY : {name: 'CN_2000', parent: StateMachine.States.COEXIST_WITH_NATURE}, // 지속가능한 도시
    GIRD_SYSTEM : {name: 'EF_0000', parent: StateMachine.States.EMBRACING_FUTURE}, // 효율적인 도시
    CONNECTED_CITY : {name: 'EF_0001', parent: StateMachine.States.EMBRACING_FUTURE}, // 연결성을 확보한 도시
    HUB20 : {name: 'EF_2000', parent: StateMachine.States.EMBRACING_FUTURE}, // Landmark Tower : Hub2.0
};

export const SUBMENU_CONTENTS = new Map();
SUBMENU_CONTENTS.set(SUBMENU.WALKABLE_CITY, [
    {state: StateMachine.States.WALKABLE_CITY_0, name: 'HC_0100', desc: 'HC_0101', images: ['1-1-1.webp']}, // 다양한 스타일의 도시 구성
    {state: StateMachine.States.WALKABLE_CITY_1, name: 'HC_0200', desc: 'HC_0201', images: ['1-2-1.webp']}, // 도보로 3분 안에 자연과 만날 수 있는 도시
])
SUBMENU_CONTENTS.set(SUBMENU.HYPON_DENSITY, [
    {state: StateMachine.States.HYPON_DENSITY_0, name: 'HC_1100', desc: 'HC_1101', images: ['2-1-1.webp']}, // 생활 속도에 따른 구획
    {state: StateMachine.States.HYPON_DENSITY_1, name: 'HC_1200', desc: 'HC_1201', images: ['2-2-1.webp', '2-2-2.webp']}, // 모든 위치에서 조망권 확보
])
SUBMENU_CONTENTS.set(SUBMENU.CONVENIENCE_CITY, [
    {state: StateMachine.States.CONVENIENCE_CITY_0, name: 'HC_2100', desc: 'HC_2101', images: ['3-1-1.webp']}, // 도시 기반시설의 기능 별 배치
    {state: StateMachine.States.CONVENIENCE_CITY_1, name: 'HC_2200', desc: 'HC_2201', images: ['3-2-1.webp']}, // 다양한 생활권 확보
])
SUBMENU_CONTENTS.set(SUBMENU.EMBRACES_NATURE, [
    {state: StateMachine.States.EMBRACES_NATURE_0, name: 'CN_0100', desc: 'CN_0101', images: ['4-1-1.webp']}, // 도시 영역 최소화 : Hexagon City
    {state: StateMachine.States.EMBRACES_NATURE_1, name: 'CN_0200', desc: 'CN_0201', images: ['4-2-1.webp']}, // 중앙 자연
])
SUBMENU_CONTENTS.set(SUBMENU.NATURE_ECOSYSTEM, [
    {state: StateMachine.States.NATURE_ECOSYSTEM_0, name: 'CN_1100', desc: 'CN_1101', images: ['5-1-1.webp']}, // 자연의 기능 활용
    {state: StateMachine.States.NATURE_ECOSYSTEM_1, name: 'CN_1200', desc: 'CN_1201', images: ['5-2-1.webp']}, // 자연 면적 확보 최대화
])
SUBMENU_CONTENTS.set(SUBMENU.SUSTAINABLE_CITY, [
    {state: StateMachine.States.SUSTAINABLE_CITY_0, name: 'CN_2100', desc: 'CN_2101', images: ['6-1-1.webp']}, // Self-Powering City
    {state: StateMachine.States.SUSTAINABLE_CITY_1, name: 'CN_2200', desc: 'CN_2201', images: ['6-2-1.webp']}, // 친환경 소재로 지어지는 건물
])
SUBMENU_CONTENTS.set(SUBMENU.GIRD_SYSTEM, [
    {state: StateMachine.States.GIRD_SYSTEM_0, name: 'EF_0100', desc: 'EF_0101', images: ['7-1-1.webp']}, // Smart GRID SYSTEM
    {state: StateMachine.States.GIRD_SYSTEM_1, name: 'EF_0200', desc: 'EF_0201', images: ['7-2-1.webp']}, // UNDERGROUND GFID SYSTEM – 에너지
    {state: StateMachine.States.GIRD_SYSTEM_2, name: 'EF_0300', desc: 'EF_0301', images: ['7-3-1.webp']}, // UNDERGROUND GFID SYSTEM – 물류
])
SUBMENU_CONTENTS.set(SUBMENU.CONNECTED_CITY, [
    {state: StateMachine.States.CONNECTED_CITY_0, name: 'EF_1100', desc: 'EF_1101', images: ['8-1-1.webp']}, // 도시와 도시의 이동
])
SUBMENU_CONTENTS.set(SUBMENU.HUB20, [
    {state: StateMachine.States.HUB20_0, name: 'EF_2100', desc: 'EF_2101', images: ['9-1-1.webp', '9-1-2.webp']}, // Hub2.0의 구조/기능
])


export const INTROTEXT = [
    'INTRO_0000',
    'INTRO_0001',
]

export const GUIDE_POPUP_ICON = {
    intro: 'intro-guide',
    webgl: 'webgl-guide',
    media: 'media-guide',
}

export const GUIDE_POPUP_LABEL = {
    intro: 'INTROPOPUP_',
    webgl: 'WEBGLPOPUP_',
    media: 'MEDIAPOPUP_',
}

export const TUTORIAL = [
    'TUTORIAL_00',
    'TUTORIAL_01',
    'TUTORIAL_02',
    'TUTORIAL_03',
    'TUTORIAL_04',
    'TUTORIAL_05',
    'TUTORIAL_06',
    'TUTORIAL_07',
]
